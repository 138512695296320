import formatValue from "../../utils/formatValue";

export const pieConfig = {
  chart: {
    dropShadow: {
      enabled: false,
      top: 0,
      left: 0,
      blur: 3,
      color: "#000",
      opacity: 0.35,
    },
  },
  tooltip: {
    fillSeriesColor: false,
  },
  legend: {
    position: "bottom",
  },
  theme: {
    monochrome: {
      enabled: true,
      color: "#EFB394",
      shadeTo: "light",
      shadeIntensity: 0.8,
    },
  },
  labels: [],
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    labels: {},
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

export const customTooltip = (serie, label, fieldLabel, formatType) => {
  return `<div class="apexcharts-tooltip-title" style="font-family: Inter; font-size: 12px;">${label}</div>
  <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
  <span class="apexcharts-tooltip-marker" style="background-color: rgb(45, 51, 122);"></span>
  <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
  <div class="apexcharts-tooltip-y-group">
  <span class="apexcharts-tooltip-text-y-label">${fieldLabel}: </span>
  <span class="apexcharts-tooltip-text-y-value">${formatValue(
    serie,
    formatType
  )}</span>
  </div>
  </div>
  </div>`;
};
