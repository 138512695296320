<template>
  <div class="dashboard-widgets__container">
    <div v-if="loadingDashboard" class="loading-bar__wrapper">
      <LoadingBar />
    </div>
    <template v-else>
      <DocumentationLink
        documentation-text="To create or modify a dashboard, follow the steps described in the"
        :documentation-link="`${getDocumentationLink()}/dashboards`"
        custom-class="create-dashboard-documentation"
      />
      <div
        class="dashboard-edit-mode-header"
        :class="showSideBar ? null : 'dashboard-edit-mode-header--full-width'"
      >
        <div class="dashboard-name__container">
          <input
            type="text"
            :title="selectedDashboard.name"
            v-model="selectedDashboard.name"
            @input="clearDashboardNameError()"
            placeholder="Dashboard name here..."
            class="dashboard-name-input"
          />
          <p
            v-if="!!dashboardNameErrorMessage?.length"
            class="aggregation-error__text"
          >
            {{ dashboardNameErrorMessage }}
          </p>
        </div>
        <div class="dashboard-btn-group">
          <button
            type="button"
            class="btn btn-secondary"
            @click="
              dashboardId
                ? $router.go(-1)
                : $router.push({
                    name: 'dashboard',
                  })
            "
          >
            Cancel
          </button>
          <button
            type="button"
            @click="saveDashboard()"
            :disabled="savingDashboard"
            class="btn btn-primary"
          >
            {{ selectedDashboard.id ? 'Save Changes' : 'Create Dashboard' }}
          </button>
        </div>
      </div>

      <div class="dashboard-create__container">
        <div style="display: flex">
          <div
            id="content"
            class="target-element"
            :class="showSideBar ? null : 'full-width'"
          >
            <span
              v-b-tooltip.hover
              :title="
                disabledPredefinedBtn
                  ? 'You need to add at least 1 widget and select a table'
                  : ''
              "
              id="predefined-filters-btn"
            >
              <b-button
                @click="addNewPredefinedFilter()"
                :disabled="disabledPredefinedBtn"
                class="mb-2 add-predefined-btn"
              >
                <GjIcon name="Plus_fill" size="14" /> Add Predefined Filter(s)
              </b-button>
            </span>
            <FilterTags
              v-if="predefinedFilters?.filters?.length > 0"
              :filters="predefinedFilters"
              :tableId="tableId"
              :isDisabled="false"
              @update-filters="savePredefinedFilters"
              :filterType="'predefined'"
              class="mt-2"
            />
            <modal-filter
              ref="predefinedFilter"
              key="predefinedFilter"
              :filters="predefinedFilters.filters"
              :logicalOperator="dashboard.predefinedGlobalFilterLogicalOperator"
              :filter-type="'predefined'"
              :global-disabled="false"
              @save-predefined="savePredefinedFilters($event, null)"
            />

            <GridLayout :mode="dashboardId !== null ? 'edit' : 'create'" />
            <div class="grid-builder extra-row"></div>
          </div>
          <div class="toolbar__container">
            <toolbar-wrapper />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { LoadingBar } from '@nodus/utilities-front'
import _ from 'lodash'
import DocumentationLink from '../components/DocumentationLink.vue'

import generateGuid from '@/utils/guid.js'
import { GjIcon, showToast } from '@nodus/utilities-front'
import GridLayout from '../components/GridLayout.vue'
import ModalFilter from '../components/ModalFilter.vue'
import FilterTags from '../components/global-filters/FilterTags.vue'
import ToolbarWrapper from '../components/toolbar/ToolbarWrapper.vue'
import Dashboard from '../models/Dashboard'
import PostDashboard from '../models/post/PostDashboard'
import store from '../store'
import { checkWidgetType } from '../utils/checkWidgetType'
import { getDocumentationLink } from '../utils/documentationLinks'
import validateValue from '../utils/regexValidations'

export default {
  name: 'Home',
  components: {
    GridLayout,
    ToolbarWrapper,
    DocumentationLink,
    ModalFilter,
    LoadingBar,
    FilterTags,
    GjIcon,
  },
  computed: {
    selectedItem: {
      get() {
        return store.getters['getSelectedItem']
      },
      set(value) {
        store.commit('SET_SELECTED_ITEM', value)
      },
    },
    showSideBar: {
      get() {
        return store.getters['getShowSideBar']
      },
      set(value) {
        store.commit('SET_SHOW_SIDEBAR', value)
      },
    },
    layout: {
      get() {
        return store.getters['getLayoutArray']
      },
      set(value) {
        store.commit('SET_LAYOUT_ARRAY', value)
      },
    },
    selectedDashboard: {
      get() {
        return store.getters['getSelectedDashboard']
      },
      set(value) {
        store.commit('SET_SELECTED_DASHBOARD', value)
      },
    },
    dashboard: {
      get() {
        return store.getters['getDashboard']
      },
      set(value) {
        store.commit('SET_DASHBOARD', value)
      },
    },
    widgetTypes: {
      get() {
        return store.getters['getWidgetTypes']
      },
      set(value) {
        store.commit('SET_WIDGET_TYPES', value)
      },
    },
    dashboardTableIds: {
      get() {
        return store.getters['getDashboardTableIds']
      },
      set(value) {
        store.commit('SET_DASHBOARD_TABLE_IDS', value)
      },
    },
    tableList: {
      get() {
        return store.getters['getTableList']
      },
    },
    parameters: {
      get() {
        return store.getters['getFilterParameters']
      },
      set(value) {
        store.commit('SET_FILTER_PARAMETERS', value)
      },
    },
    filteredParameters: {
      get() {
        return store.getters['getFilteredDashboardParameters']
      },
      set(value) {
        store.commit('SET_DASHBOARD_FILTERED_PARAMS', value)
      },
    },
    predefinedFilters: {
      get() {
        return {
          filters: this.dashboard.predefinedGlobalFilters,
        }
      },
      set(value) {
        this.dashboard.predefinedGlobalFilters = value
      },
    },
    layoutTableIds() {
      return this.layout.map((item) => item?.tableId)
    },
  },
  async beforeRouteEnter(to, __, next) {
    if (store.getters.getEnabledActions === null) {
      await store.dispatch('getDashboards', {
        organizationId: to.params.organizationId,
      })
    }
    if (store.getters.getEnabledActions?.canModify) next()
    else next('/error/401')
  },
  data() {
    return {
      organizationId: this.$route?.params?.organizationId,
      templateId: this.$route?.params?.templateId,
      dashboardId: null,
      elements: null,
      maxTitleLength: 60,
      savingDashboard: false,
      getDocumentationLink,
      checkWidgetType,
      dashboardNameErrorMessage: false,
      tableId: 'dashboard',
      areFiltersInvalid: false,
      loadingDashboard: true,
      disabledPredefinedBtn: true,
    }
  },
  watch: {
    layoutTableIds: {
      handler() {
        this.handleFilters()
      },
    },
  },
  methods: {
    isWidgetTitleValid(widget) {
      if (
        this.checkWidgetType(this.widgetTypes, widget, 'Card') &&
        !widget.title.trim().length
      ) {
        return false
      }

      const pattern = /^[a-zA-Z0-9\s()_\-,.]{0,30}$/
      return pattern.test(widget.title)
    },
    validateDashboardTitle(title) {
      if (!title?.trim().length) {
        this.dashboardNameErrorMessage = 'Dashboard name cannot be empty!'
        return
      }

      if (title.length > 100) {
        this.dashboardNameErrorMessage =
          'Dashboard name must be 100 characters or fewer!'
        return
      }

      const pattern = /^[a-zA-Z0-9\s()_\-,.]*$/
      const isValid = pattern.test(title)
      if (!isValid)
        this.dashboardNameErrorMessage =
          'Dashboard name should only contain letters, numbers, spaces, and ( ) _ - , .'
    },
    clearDashboardNameError() {
      this.dashboardNameErrorMessage = null
    },
    handleFilters() {
      this.getTableIds(true)
      if (this.dashboardTableIds.length > 0) {
        this.disabledPredefinedBtn = false
      } else {
        this.disabledPredefinedBtn = true
      }

      if (this.dashboard && Object.keys(this.parameters).length > 0) {
        this.dashboard.predefinedGlobalFilters =
          this.dashboard?.predefinedGlobalFilters?.filter((filter) => {
            for (const dashboardTableId of this.dashboardTableIds) {
              const tableParameters =
                this.parameters[dashboardTableId]?.parameters || []
              if (
                tableParameters.some(
                  (param) => param.columnId === filter.columnId
                )
              ) {
                return true
              }
            }
            return false
          })
      }
    },
    async saveDashboard() {
      let isDashboardValid = {
        valid: true,
        message: '',
      }
      let tempLayout = _.cloneDeep(this.layout)
      if (tempLayout.length < 1) {
        isDashboardValid.valid = false
        isDashboardValid.message = `Dashboard cannot be empty!`
      }
      this.validateDashboardTitle(this.selectedDashboard?.name)
      if (this.dashboardNameErrorMessage?.length > 0) {
        isDashboardValid.valid = false
        return
      }
      tempLayout.forEach((widget) => {
        if (!this.isWidgetTitleValid(widget)) {
          isDashboardValid.valid = false
          isDashboardValid.message = `Widget "${
            widget.title || this.checkWidgetType(this.widgetTypes, widget, null)
          }" has invalid title!`
          return
        }
        if (!widget.tableId || widget.fields.length < 1) {
          isDashboardValid.valid = false
          isDashboardValid.message = `Widget "${
            widget.title || this.checkWidgetType(this.widgetTypes, widget, null)
          }" has no table or field selected!`
          return
        } else if (widget.fields.length > widget.maxAggregation) {
          isDashboardValid.valid = false
          isDashboardValid.message = `Widget "${
            widget.title || this.checkWidgetType(this.widgetTypes, widget, null)
          }" has exceeded limit of aggregation functions.`
          return
        }
        widget.filters.forEach((filter) => {
          if (
            filter.columnId === null ||
            filter.operator === null ||
            filter.filterValues.length === 0 ||
            !filter.validValue
          ) {
            isDashboardValid.valid = false
            isDashboardValid.message = `Widget "${
              widget.title ||
              this.checkWidgetType(this.widgetTypes, widget, null)
            }" has an invalid filter!`
            filter.invalid = true
            return
          }
        })
      })
      if (!isDashboardValid.valid) {
        showToast('error', isDashboardValid.message)
        return
      }
      this.savingDashboard = true

      let name = this.selectedDashboard.name || 'Untitled-dashboard'

      tempLayout.map((item) => {
        if (item?.groupByField?.id) {
          item.groupByField = item?.groupByField?.id
        } else {
          item.groupByField = null
        }
      })

      const dashboardRes = await store.dispatch('saveDashboard', {
        organizationId: this.organizationId,
        data: new PostDashboard({
          id: this.selectedDashboard.id,
          name,
          widgets: tempLayout,
          userId: 'string',
          predefinedGlobalFilterLogicalOperator:
            this.dashboard.predefinedGlobalFilterLogicalOperator,
          predefinedGlobalFilters: this.dashboard.predefinedGlobalFilters,
        }),
      })

      if (dashboardRes) {
        const id = dashboardRes
        this.selectedDashboard = {
          id,
          name,
        }
        this.layout = []
        tempLayout = []
        this.savingDashboard = false
        setTimeout(() => {
          this.$router.push({
            name: 'dashboard-view',
            params: {
              organizationId: this.organizationId,
              id,
            },
          })
        }, 0)
      } else {
        this.savingDashboard = false
      }
    },
    changeFocus(e) {
      if (
        !e.target.classList.contains('vue-resizable-handle') &&
        !e.target.classList.contains('vue-grid-item') &&
        !e.target.classList.contains('overlay__container') &&
        !e.target.classList.contains('edit__btn')
      ) {
        this.selectedItem = null
        let toolbar = document.querySelector('.toolbar-wrapper')
        toolbar.classList.remove('filter-opened')
      }
    },
    savePredefinedFilters(e, predefinedGlobalFilters) {
      e?.preventDefault()
      const modalFilters = this.$refs?.predefinedFilter
      const updatedFilters =
        predefinedGlobalFilters?.filters || modalFilters?.getUpdatedFilters()
      const updatedOperator =
        predefinedGlobalFilters?.logicalOperator ||
        modalFilters?.getUpdatedOperator()
      if (!predefinedGlobalFilters) {
        this.validateFilters(updatedFilters)
        if (this.areFiltersInvalid) {
          showToast('error', `Some filters are invalid!`)
          return
        }
      }

      this.dashboard.predefinedGlobalFilters = updatedFilters
      this.dashboard.predefinedGlobalFilterLogicalOperator = updatedOperator

      this.$nextTick(() => {
        this.$bvModal.hide('predefinedFilter')
      })
    },
    async addNewPredefinedFilter() {
      await this.$refs?.predefinedFilter?.openModal()
    },
    getTableIds(filterForPredefined) {
      if (filterForPredefined) {
        const allTableIds = this.layout
          .map((item) => item.tableId)
          .filter((id) => id !== null && id !== undefined)
        this.dashboardTableIds = [...new Set(allTableIds)]
      } else {
        const allTableIds = this.tableList.map((item) => item.id)
        this.dashboardTableIds = [...new Set(allTableIds)]
      }
    },
    validateFilters(filters) {
      let filtersInvalid = false
      filters.forEach((filter) => {
        if (!this.isFilterValid(filter)) {
          filter.invalid = true
          filtersInvalid = true
        } else {
          filter.invalid = false
        }
      })

      if (filtersInvalid) {
        this.areFiltersInvalid = true
      } else {
        this.areFiltersInvalid = false
      }
    },
    isFilterValid(filter) {
      const operator = this.getOperators(filter.columnId)
      const validation = validateValue(filter, operator)
      filter.validValue = validation

      return (
        filter.columnId !== null &&
        filter.operator !== null &&
        filter.filterValues.length > 0 &&
        filter.validValue
      )
    },
    getOperators(id) {
      if (!id) return null
      return this.parameters[this.tableId]?.parameters.find(
        (x) => x.columnId === id
      )?.columnType
    },
    async fetchParametersForFilters() {
      for (const id of this.dashboardTableIds) {
        if (!this.parameters[id]) {
          await store.dispatch('getParameters', {
            organizationId: this.organizationId,
            id,
          })
        }
      }
    },
  },
  beforeDestroy() {
    this.elements?.forEach((el) => {
      el.removeEventListener('click', this.changeFocus)
    })
    delete this.parameters[this.tableId]
  },
  destroyed() {
    this.layout = []
    this.selectedDashboard = {}
  },
  async mounted() {
    this.dashboard = {}
    await store.dispatch('getWidgets', {
      organizationId: this.organizationId,
    })
    this.dashboardId = this.$route?.params?.id || null
    await store.dispatch('getTables', {
      organizationId: this.organizationId,
    })
    if (this.dashboardId) {
      store.commit('SET_SELECTED_DATES', {})
      await store.dispatch('getDashboardById', {
        organizationId: this.organizationId,
        id: this.dashboardId,
      })

      this.layout = this.dashboard?.widgets
      this.getTableIds(true)
      await this.fetchParametersForFilters()
      this.selectedDashboard = {
        id: this.dashboard.id,
        name: this.dashboard.name,
      }
      this.layout.map((item) => (item.show = true))
    } else if (this.templateId) {
      await store.dispatch('getDashboardTemplateById', {
        organizationId: this.organizationId,
        id: this.templateId,
      })
      this.selectedDashboard = {
        id: this.dashboard.id,
        name: this.dashboard.name,
      }
      this.layout = this.dashboard?.widgets
      this.layout.map((item) => (item.i = generateGuid()))

      if (this.dashboardTableIds.length < 1) {
        this.disabledPredefinedBtn = true
      }
    } else {
      this.dashboard = new Dashboard({})
    }
    this.loadingDashboard = false
    this.$nextTick(() => {
      this.elements = document.querySelectorAll('.target-element')
      this.elements.forEach((el) => {
        el.addEventListener('click', this.changeFocus)
      })
    })
    await store.dispatch('getAiConfigurations', {
      organizationId: this.organizationId,
    })
  },
}
</script>

<style lang="scss">
@import '~@/assets/style/views/create-dashboard.scss';
</style>
