var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "shared-dashboards__container" },
        [
          _c("Header", {
            staticClass: "mb-4",
            attrs: { "header-type": "view-on-share" },
            on: { "change-dashboard-list": _vm.changeDashboardId },
          }),
          _c(
            "section",
            { staticClass: "table__wrapper" },
            [
              _c("SearchInputField", {
                attrs: { id: "search-share", data: _vm.dashboardShareData },
              }),
              _c(
                "div",
                { staticClass: "table__container" },
                [
                  _c("CustomTable", {
                    attrs: {
                      data: _vm.dashboardShareData,
                      "type-of-table": "dashboard-share",
                      "is-pagination-enabled": true,
                      "is-loading": _vm.loadingTable,
                      "empty-state-action": {
                        canAdd: _vm.canModifyShare,
                        action: () => {
                          _vm.$router.push({
                            name: "create-share",
                            params: {
                              dashboardId: _vm.dashboardId,
                              organizationId: _vm.organizationId,
                            },
                          })
                        },
                      },
                    },
                    on: { "update-table": _vm.updateTable },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "row-data",
                          fn: function ({ data }) {
                            return [
                              _c("ShareRow", {
                                attrs: { data: data, type: "share" },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2086780877
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "embed-code",
              attrs: {
                id: "embed-code",
                size: "lg",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                centered: "",
                "modal-class": "embed-code__modal",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-header",
                    fn: function ({ close }) {
                      return [
                        _c("div", [
                          _c("h2", [_vm._v("Embed Code")]),
                          _c("span", [_vm._v(_vm._s(_vm.shareDetails.name))]),
                        ]),
                        _c(
                          "button",
                          { staticClass: "btn", on: { click: close } },
                          [
                            _c("GjIcon", {
                              attrs: { name: "Close", color: "#052D61" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3092371020
              ),
            },
            [
              _c("p", { staticClass: "config-title" }, [_vm._v("Embed Code")]),
              _c("b-form-textarea", {
                staticClass: "embed-code__container",
                attrs: { disabled: "", rows: "10", "max-rows": "10" },
                model: {
                  value: _vm.shareDetails.embedCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.shareDetails, "embedCode", $$v)
                  },
                  expression: "shareDetails.embedCode",
                },
              }),
              _c(
                "button",
                {
                  staticClass: "btn embed-copy__btn",
                  on: {
                    click: function ($event) {
                      return _vm.copyEmbed(_vm.shareDetails.embedCode)
                    },
                  },
                },
                [
                  _c("GjIcon", {
                    attrs: { name: "Copy", color: "#A3B0C2", size: "20" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("DeleteModal", {
            attrs: {
              id: "link-delete-modal",
              title: "Delete Link",
              type: "link",
            },
            on: { delete: _vm.removeItem },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }