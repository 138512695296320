export const barConfig = {
  chart: {
    width: "100%",
    height: "100%",
    fontFamily: "Inter",
    zoom: {
      enabled: false,
    },
    stacked: false,
  },
  tooltip: {
    intersect: false,
    shared: true,
  },
  legend: {
    position: "top",
  },
  xaxis: {
    labels: {
      trim: true,
      style: {
        colors: "#A3B0C2",
      },
      maxHeight: 60,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#A3B0C2",
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    colors: ["transparent"],
    width: 6,
  },
  colors: ["#EFB394"],
  fill: {
    colors: ["#EFB394"],
  },
};
