<template>
  <b-modal
    :id="typeOfModal"
    :ref="typeOfModal"
    modal-class="modal-filter"
    variant="primary"
    size="xl"
    centered
    @ok="saveFilters($event, null, false)"
  >
    <template #modal-header="{ close }">
      <div class="modal-title__container">
        <span>{{
          filterType !== "predefined" ? "Filter by" : "Predefined Filters"
        }}</span>
        <DocumentationLink
          v-if="filterType === 'predefined'"
          documentation-text="Predefined filters are applied to the entire dashboard. To learn more, follow the steps described in the"
          :documentation-link="`${getDocumentationLink()}/dashboards`"
        />
      </div>
      <b-button size="sm" @click="close()" class="bg-transparent border-0">
        <img :src="require(`@/assets/img/close-icon.svg`)" alt="Close icon" />
      </b-button>
    </template>
    <div id="modal-filters" class="modal-filter__container">
      <configurationFilter
        :filters="clonedFilters"
        :operator="operator"
        :table-id="tableId"
        :isModal="true"
        :loading="loading"
        @update:operator="(value) => (operator = value)"
        @validate-filters="$emit('validate-filters', data)"
        :filter-type="filterType"
        :global-disabled="globalDisabled"
      />
    </div>
    <template #modal-footer="{ ok, close }">
      <b-button
        size="sm"
        variant="outline-secondary"
        class="py-2 px-3"
        @click="close()"
      >
        Cancel
      </b-button>
      <b-button size="sm" class="py-2 px-4" variant="primary" @click="ok()">
        {{ filterType === "predefined" && globalDisabled ? "Update" : "Save" }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ConfigurationFilter from "@/components/toolbar/components/ConfigurationFilter";
import store from "@/store";
import _ from "lodash";
import Filter from "../models/Filter";
import { getDocumentationLink } from "../utils/documentationLinks";
import DocumentationLink from "./DocumentationLink.vue";

export default {
  components: {
    ConfigurationFilter,
    DocumentationLink,
  },
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => [
        new Filter({ id: null, columnId: null, filterValues: [] }),
      ],
    },
    logicalOperator: {
      type: Number,
      required: false,
      default: () => 1,
    },
    filterType: {
      type: String,
      required: false,
      default: "global",
    },
    globalDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    parameters: {
      get() {
        return store.getters["getFilterParameters"];
      },
      set(value) {
        store.commit("SET_FILTER_PARAMETERS", value);
      },
    },
    typeOfModal() {
      if (this.filterType === "global") return "globalFilter";
      else if (this.filterType === "predefined") return "predefinedFilter";
      else return "shareFilter";
    },
  },
  data() {
    return {
      clonedFilters: [],
      operator: 1,
      organizationId: this.$route?.params?.organizationId,
      tableId: "dashboard",
      loading: true,
      getDocumentationLink,
    };
  },
  methods: {
    getUpdatedFilters() {
      return this.clonedFilters;
    },
    getUpdatedOperator() {
      return this.operator;
    },
    saveFilters(e, globalFilters, removedTag) {
      if (this.filterType === "global") {
        this.$emit("save-filters", e, globalFilters, removedTag);
      } else if (this.filterType === "share") {
        const updatedFilters = this.getUpdatedFilters();
        const updatedOperator = this.getUpdatedOperator();
        this.$emit("add-new-filter", e, updatedFilters, updatedOperator);
      } else {
        this.$emit("save-predefined", e, globalFilters, true);
      }
    },
    async openModal() {
      this.$refs[
        this.filterType === "global"
          ? "globalFilter"
          : this.filterType === "predefined"
          ? "predefinedFilter"
          : "shareFilter"
      ].show();
      this.clonedFilters = _.cloneDeep(this.filters);
      this.operator = _.cloneDeep(this.logicalOperator);
      await this.$nextTick(async () => {
        if (this.filters.length === 0) {
          this.clonedFilters.push(
            new Filter({ id: null, columnId: null, filterValues: [] })
          );
        }
        if (
          !this.parameters[this.tableId] ||
          this.filterType === "predefined"
        ) {
          await store.dispatch("getParameters", {
            organizationId: this.organizationId,
          });
        }
        await store.dispatch("getOperators", {
          organizationId: this.organizationId,
        });
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.modal-filter .active-tag-filter {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  transition: all 0.6s ease-in;
}

.modal-filter .documentation-info {
  margin-bottom: 0;
  margin-top: 8px;
}
</style>
