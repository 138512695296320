const generateSimilarColor = (baseColor, variation) => {
  const rgbValues = baseColor
    .match(/[A-Fa-f0-9]{2}/g)
    .map((hex) => parseInt(hex, 16));
  const adjustedValues = rgbValues.map((value) => {
    const adjustment = Math.floor((Math.random() - 0.5) * variation);
    return Math.min(255, Math.max(0, value + adjustment));
  });
  const adjustedHex = adjustedValues
    .map((value) => value.toString(16).padStart(2, "0"))
    .join("");
  return `#${adjustedHex}`;
};

export const generateRandomHexColorsArray = (count, variation, chartColors) => {
  const colorsArray = [];

  for (let i = 0; i < count; i++) {
    const randomBaseColor =
      chartColors[Math.floor(Math.random() * chartColors.length)];
    colorsArray.push(generateSimilarColor(randomBaseColor, variation));
  }

  return colorsArray;
};
