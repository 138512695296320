var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "dashboard-overview" },
      [
        _c(
          "div",
          { staticClass: "table-list__header" },
          [
            _c("PrimaryCreateDropdown", {
              attrs: {
                id: "create-dashboard",
                name: "New Dashboard",
                "is-loading": _vm.loadingDashboards,
                "can-add": _vm.enabledActions?.canModify,
                "create-new-handler": _vm.enabledActions?.canModify
                  ? _vm.createNewDashboard
                  : () => {},
                "router-path": _vm.enabledActions?.canModify
                  ? "dashboard-create"
                  : "",
              },
            }),
            _c("SearchInputField", {
              attrs: { id: "search-dashboards", data: _vm.dashboardsData },
            }),
          ],
          1
        ),
        _c("DeleteModal", {
          attrs: { title: "Delete Dashboard", type: "dashboard" },
          on: { delete: _vm.deleteDashboard },
        }),
        _c("DashboardTemplateModal"),
        _c(
          "section",
          { staticClass: "table__wrapper" },
          [
            _c("CustomTable", {
              attrs: {
                data: _vm.dashboardsData,
                "type-of-table": "dashboards",
                "is-pagination-enabled": true,
                "is-loading": _vm.loadingDashboards,
                "empty-state-action": {
                  canAdd: _vm.enabledActions?.canModify,
                  action: _vm.createNewDashboard,
                },
              },
              on: { "update-table": _vm.updateTable },
              scopedSlots: _vm._u([
                {
                  key: "row-data",
                  fn: function ({ data }) {
                    return [
                      _c("DashboardsRow", {
                        attrs: { data: data },
                        on: { "select-dashboard": _vm.setSelectedDashboard },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }