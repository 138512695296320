<!-- eslint-disable vue/valid-v-bind -->
<template>
  <div v-if="!loading" class="filter-tags__container" :class="filterType">
    <GjIcon
      v-if="(isDisabled || isFilterTypeGlobal) && !isOnShare"
      class="info-icon"
      name="IconparkInfo"
      size="18"
      color="#A3B0C2"
      v-b-tooltip.hover
      :title="isFilterTypeGlobal ? 'Global Filters' : 'Predefined Filters'"
    />
    <div v-if="overflowing && !isOnShare">
      <div
        v-for="(filter, filterIndex) in filtersArr"
        :key="filterIndex"
        class="d-inline"
      >
        <span
          v-if="filterIndex < index || index === -1"
          class="filter-tag"
          :class="isFilterTypeGlobal ? null : 'predefined-filter'"
        >
          <TagText
            :filter="filter"
            :showTooltip="true"
            :tableId="tableId"
            @open-filter-modal="onFiltersModalOpen(filter.id)"
          />
          <button
            v-if="!areDisabled"
            type="button"
            class="btn remove-tag-btn"
            @click="handleFilter(filter.id)"
            :disabled="coolDownBtn"
          >
            <GjIcon
              v-if="isFilterTypeGlobal || !isDisabled"
              name="Close_fill"
              size="18"
              color="#7483a1"
            />
            <GjIcon
              v-else
              :color="!filter.hidden ? '#052D61' : '#667C99'"
              size="18"
              :name="
                !filter.hidden
                  ? 'EyeVisibilityPassword'
                  : 'IconparkPreviewClose'
              "
            />
          </button>
        </span>
      </div>
      <span
        class="filter-tag"
        :class="isFilterTypeGlobal ? null : 'predefined-filter'"
      >
        + {{ filtersArr.length - index }}
      </span>
      <button class="btn view-all__btn" @click="onFiltersModalOpen">
        View All
      </button>
      <button
        v-if="isFilterTypeGlobal || !isDisabled"
        class="btn"
        @click="clearGlobalFilters()"
      >
        Clear All
      </button>
    </div>
    <div v-else :class="isOnShare ? 'wrapped-tags' : ''">
      <div
        v-for="(filter, filterIndex) in filtersArr"
        :key="filterIndex"
        :class="isOnShare ? 'd-flex' : 'd-inline'"
      >
        <span
          class="filter-tag"
          :class="
            isOnShare ? null : isFilterTypeGlobal ? null : 'predefined-filter'
          "
        >
          <TagText
            :showTooltip="true"
            :filter="filter"
            :tableId="tableId"
            @open-filter-modal="
              canEditTags ? onFiltersModalOpen(filter.id) : {}
            "
          />
          <button
            v-if="!areDisabled"
            type="button"
            class="btn remove-tag-btn"
            @click="handleFilter(filter.id)"
            :disabled="coolDownBtn"
          >
            <GjIcon
              name="Close_fill"
              size="18"
              color="#7483a1"
              v-if="isFilterTypeGlobal || !isDisabled"
            />
            <GjIcon
              v-else
              :color="!filter.hidden ? '#052D61' : '#667C99'"
              size="18"
              :name="
                !filter.hidden
                  ? 'EyeVisibilityPassword'
                  : 'IconparkPreviewClose'
              "
            />
          </button>
        </span>
      </div>
      <button
        v-if="isOnShare && canEditTags"
        class="btn view-all__btn"
        @click="addFilter"
      >
        <GjIcon name="Plus" size="14" /> Add filter
      </button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { ref } from "vue";
import TagText from "./TagText.vue";
import { GjIcon } from "@nodus/utilities-front";

export default {
  props: {
    filters: {
      type: [Object, Array],
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    areDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOnShare: {
      type: Boolean,
      default: false,
    },
    filterType: {
      type: String,
      required: false,
      default: "global",
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    canEditTags: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TagText,
    GjIcon,
  },
  computed: {
    filtersArr() {
      return this.isOnShare ? [...this.filters] : [...this.filters.filters];
    },
    parameters: {
      get() {
        return store.getters["getFilterParameters"];
      },
      set(value) {
        store.commit("SET_FILTER_PARAMETERS", value);
      },
    },
    operators: {
      get() {
        return store.getters["getFilterOperators"];
      },
    },
    isFilterTypeGlobal() {
      return this.filterType === "global";
    },
  },
  data() {
    return {
      overflowing: ref(false),
      index: -1,
      organizationId: this.$route?.params?.organizationId,
      dashboardId: this.$route?.params?.id,
      removedTag: false,
      loading: true,
      coolDownBtn: false,
      coolDownTimeout: null,
    };
  },
  watch: {
    filtersArr: {
      handler() {
        if (this.filtersArr.length > 0) {
          this.overflowing = false;
          this.isOverflowing();
        }
      },
    },
  },
  async mounted() {
    if (!this.parameters[this.tableId]) {
      await store.dispatch("getParameters", {
        organizationId: this.organizationId,
      });
    }
    if (this.operators.length <= 0) {
      await store.dispatch("getOperators", {
        organizationId: this.organizationId,
      });
    }
    this.loading = false;
    this.isOverflowing();
  },
  methods: {
    onFiltersModalOpen(filterId) {
      let target;
      let modal = this.$parent.$refs[`${this.filterType}Filter`];
      modal.openModal();
      setTimeout(() => {
        const container = document.querySelector(
          `#${this.filterType}Filter .modal-filter__container`
        );
        target = document.getElementById(filterId);
        if (container && target) {
          target.classList.add("active-tag-filter");
          const targetRect = target.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          const offset =
            targetRect.top - containerRect.top + container.scrollTop - 20;
          container.scrollTo({ top: offset, behavior: "smooth" });
        }
      }, 550);
      setTimeout(() => {
        target?.classList?.remove("active-tag-filter");
      }, 1500);
    },
    isOverflowing() {
      this.$nextTick(() => {
        const parent = document.querySelector(
          `.filter-tags__container.${this.filterType}`
        );
        if (parent) {
          const childs = parent.querySelectorAll(".filter-tag");
          const seeMoreButton = document.querySelector(".view-all__btn");
          const parentWidth = parent.offsetWidth;
          if (!seeMoreButton) {
            this.index = -1;
          }
          let initialWidth = 0;
          for (let i = 0; i < childs.length; i++) {
            initialWidth += childs[i].clientWidth + 30;
            if (childs[i] === seeMoreButton) {
              break;
            }

            if (initialWidth > parentWidth - 200) {
              this.index = i;
              this.overflowing = true;
              break;
            }
          }
        }
      });
    },
    handleFilter(id) {
      const itemToHandle = this.filtersArr.findIndex((item) => item.id === id);
      if (this.filterType === "global" || !this.isDisabled) {
        if (this.isOnShare) {
          // eslint-disable-next-line vue/no-mutating-props
          this.filters.splice(itemToHandle, 1);
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.filters.filters.splice(itemToHandle, 1);
        }
        this.removedTag = true;
      } else if (this.filterType === "predefined") {
        // eslint-disable-next-line vue/no-mutating-props
        this.filters.filters[itemToHandle].hidden =
          !this.filters.filters[itemToHandle].hidden;
        this.coolDownBtn = true;
        this.coolDownTimeout = setTimeout(() => {
          this.coolDownBtn = false;
        }, 600);
      }
      this.$emit("update-filters", null, this.filters, this.removedTag);
    },
    destroyed() {
      clearTimeout(this.coolDownTimeout);
    },
    clearGlobalFilters() {
      this.overflowing = false;
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.filters.splice(0, this.filters.filters.length);
      this.removedTag = true;
      this.$emit("update-filters", null, this.filters, this.removedTag);
    },
    addFilter() {
      this.$emit("open-share-filter");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/filter-tags.scss";
</style>
