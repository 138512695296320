<!-- eslint-disable vue/no-mutating-props -->
<template>
  <span v-if="data.column.field === 'name'">
    <div class="dashboard-link">
      <ReadMore :text="data.row.name" :to="viewLocation" />
    </div>
  </span>
  <div v-else-if="data.column.field === 'insertDateTime'">
    <DateColumn :data="data" />
  </div>
  <div v-else-if="data.column.field === 'type'">
    <span class="share-type__txt">{{ data.row.dashboardSharingType }}</span>
  </div>
  <div v-else-if="data.column.field === 'options'" class="options-container">
    <b-form-checkbox
      v-model="data.row.isEnabled"
      switch
      size="md"
      :key="data.row.id"
      :disabled="!canModifyShare"
      @change="canModifyShare ? changeShareStatus(data.row.id) : {}"
    >
    </b-form-checkbox>
    <button
      class="btn"
      v-b-modal="isShareRow ? '' : 'embed-code'"
      v-b-tooltip.hover.bottomleft="isShareRow ? 'Copy Link' : 'Get Embed'"
      @click="isShareRow ? copyLink(data.row) : setRowDetails(data.row)"
    >
      <GjIcon :name="isShareRow ? 'Copy' : 'IconparkCode'" size="18" />
    </button>
    <DropdownActions :items="dropdownActions" />
  </div>
  <span v-else>{{ data.formattedRow[data.column.field] }}</span>
</template>

<script>
import store from "@/store";
import {
  DateColumn,
  DropdownActions,
  GjIcon,
  ReadMore,
  capitalizeFirstLetter,
  showToast,
} from "@nodus/utilities-front";
import { useClipboard } from "@vueuse/core";
import { BFormCheckbox } from "bootstrap-vue";
import { computed, getCurrentInstance, ref } from "vue";

export default {
  name: "DashboardsRow",
  components: {
    DateColumn,
    DropdownActions,
    GjIcon,
    ReadMore,
    BFormCheckbox,
  },
  props: {
    data: [Object],
    type: String,
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { organizationId, dashboardId } = vm.$route.params;
    const { copy } = useClipboard();
    const isShareRow = ref(props.data.row.dashboardSharingType === "Link");
    const canModifyShare = computed(() => store.getters.getCanModifyShare);

    const changeShareStatus = async (id) => {
      await store.dispatch("changeShareStatus", {
        organizationId,
        id,
      });
    };

    const copyLink = (row) => {
      const { shareUrl } = row;
      copy(shareUrl);
      showToast("success", "Link copied!");
    };

    const setRowDetails = (row) => {
      store.commit("SET_SHARE_DETAILS", row);
    };

    const setRowToDelete = (row) => {
      store.commit("SET_SHARE_TO_DELETE", row);
    };

    const viewLocation = {
      name: "share-details",
      params: {
        organizationId,
        dashboardId,
        id: props.data.row.id,
        isShare: isShareRow.value,
      },
    };

    const dropdownActions = [
      {
        id: "shared-dashboard-view",
        icon: "IconparkPreviewOpen",
        text: "View",
        to: viewLocation,
      },
      {
        id: "shared-dashboard-edit",
        icon: "Edit",
        text: "Edit",
        disabled: !canModifyShare.value,
        to: {
          name: "edit-share",
          params: {
            organizationId,
            dashboardId,
            id: props.data.row.id,
          },
        },
      },
      {
        id: "shared-dashboard-delete",
        icon: "Delete",
        text: "Delete",
        type: "danger",
        modal: "link-delete-modal",
        disabled: !canModifyShare.value,
        action: () => setRowToDelete(props.data.row),
      },
    ];

    return {
      organizationId,
      dashboardId,
      capitalizeFirstLetter,
      changeShareStatus,
      copyLink,
      isShareRow,
      setRowDetails,
      setRowToDelete,
      dropdownActions,
      canModifyShare,
      viewLocation,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/share-row.scss";
</style>
