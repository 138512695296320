var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "child-component" }, [
    _c("div", { staticClass: "map", attrs: { id: _vm.componentId } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }