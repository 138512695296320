var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-widgets__container" },
    [
      _vm.loadingDashboard
        ? _c(
            "div",
            { staticClass: "loading-bar__wrapper" },
            [_c("LoadingBar")],
            1
          )
        : [
            _c("DocumentationLink", {
              attrs: {
                "documentation-text":
                  "To create or modify a dashboard, follow the steps described in the",
                "documentation-link": `${_vm.getDocumentationLink()}/dashboards`,
                "custom-class": "create-dashboard-documentation",
              },
            }),
            _c(
              "div",
              {
                staticClass: "dashboard-edit-mode-header",
                class: _vm.showSideBar
                  ? null
                  : "dashboard-edit-mode-header--full-width",
              },
              [
                _c("div", { staticClass: "dashboard-name__container" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedDashboard.name,
                        expression: "selectedDashboard.name",
                      },
                    ],
                    staticClass: "dashboard-name-input",
                    attrs: {
                      type: "text",
                      title: _vm.selectedDashboard.name,
                      placeholder: "Dashboard name here...",
                    },
                    domProps: { value: _vm.selectedDashboard.name },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.selectedDashboard,
                            "name",
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.clearDashboardNameError()
                        },
                      ],
                    },
                  }),
                  !!_vm.dashboardNameErrorMessage?.length
                    ? _c("p", { staticClass: "aggregation-error__text" }, [
                        _vm._v(
                          " " + _vm._s(_vm.dashboardNameErrorMessage) + " "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "dashboard-btn-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.dashboardId
                            ? _vm.$router.go(-1)
                            : _vm.$router.push({
                                name: "dashboard",
                              })
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.savingDashboard },
                      on: {
                        click: function ($event) {
                          return _vm.saveDashboard()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedDashboard.id
                              ? "Save Changes"
                              : "Create Dashboard"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "dashboard-create__container" }, [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  {
                    staticClass: "target-element",
                    class: _vm.showSideBar ? null : "full-width",
                    attrs: { id: "content" },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          title: _vm.disabledPredefinedBtn
                            ? "You need to add at least 1 widget and select a table"
                            : "",
                          id: "predefined-filters-btn",
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mb-2 add-predefined-btn",
                            attrs: { disabled: _vm.disabledPredefinedBtn },
                            on: {
                              click: function ($event) {
                                return _vm.addNewPredefinedFilter()
                              },
                            },
                          },
                          [
                            _c("GjIcon", {
                              attrs: { name: "Plus_fill", size: "14" },
                            }),
                            _vm._v(" Add Predefined Filter(s) "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.predefinedFilters?.filters?.length > 0
                      ? _c("FilterTags", {
                          staticClass: "mt-2",
                          attrs: {
                            filters: _vm.predefinedFilters,
                            tableId: _vm.tableId,
                            isDisabled: false,
                            filterType: "predefined",
                          },
                          on: { "update-filters": _vm.savePredefinedFilters },
                        })
                      : _vm._e(),
                    _c("modal-filter", {
                      key: "predefinedFilter",
                      ref: "predefinedFilter",
                      attrs: {
                        filters: _vm.predefinedFilters.filters,
                        logicalOperator:
                          _vm.dashboard.predefinedGlobalFilterLogicalOperator,
                        "filter-type": "predefined",
                        "global-disabled": false,
                      },
                      on: {
                        "save-predefined": function ($event) {
                          return _vm.savePredefinedFilters($event, null)
                        },
                      },
                    }),
                    _c("GridLayout", {
                      attrs: {
                        mode: _vm.dashboardId !== null ? "edit" : "create",
                      },
                    }),
                    _c("div", { staticClass: "grid-builder extra-row" }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "toolbar__container" },
                  [_c("toolbar-wrapper")],
                  1
                ),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }