var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.column.field === "name"
    ? _c("span", [
        _c(
          "div",
          { staticClass: "dashboard-table-name" },
          [
            _c("ReadMore", {
              attrs: { text: _vm.data.row.name, to: _vm.viewLocation },
            }),
          ],
          1
        ),
      ])
    : _vm.data.column.field === "insertDateTime"
    ? _c("div", [_c("DateColumn", { attrs: { data: _vm.data } })], 1)
    : _vm.data.column.field === "options"
    ? _c(
        "div",
        { staticClass: "options-container" },
        [_c("DropdownActions", { attrs: { items: _vm.dropdownActions } })],
        1
      )
    : _c("span", [_vm._v(_vm._s(_vm.data.formattedRow[_vm.data.column.field]))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }