var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-overview" },
    [
      _vm.isDashobardFullScreen || _vm.fullScreenWidget
        ? _c("FullScreenHeader", {
            attrs: {
              "header-title":
                _vm.widgetTypes?.find(
                  (item) => item.id === _vm.fullScreenWidget?.widgetType
                )?.name || _vm.selectedDashboard?.name,
              type: "fullscreen",
            },
          })
        : _vm._e(),
      !_vm.isDashobardFullScreen && !_vm.fullScreenWidget
        ? _c(
            "div",
            [
              _vm.selectedDashboard.id !== null
                ? _c("Header", {
                    attrs: { "header-type": "view" },
                    on: {
                      "change-dashboard": _vm.changeDashboard,
                      "export-dashboard": _vm.exportDashboard,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.loadingDashboard && !_vm.fullScreenWidget
        ? _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between mt-5 mb-3 flex-wrap align-items-start",
              staticStyle: { gap: "12px" },
            },
            [
              _c(
                "div",
                { staticClass: "properties-filters__container" },
                [
                  _vm.properties
                    ? _c(
                        "MultipleProperties",
                        _vm._b(
                          {
                            attrs: {
                              id: "select-properties",
                              options: _vm.properties,
                              classes: [
                                "multiple-properties--one",
                                "multiple-properties__min-width",
                              ],
                            },
                            on: { "update-data": _vm.propertyOrDateSelected },
                          },
                          "MultipleProperties",
                          _vm.propertiesSelected?.length > 0
                            ? { selected: _vm.propertiesSelected }
                            : {},
                          false
                        )
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "justify-content-between",
                      on: {
                        click: function ($event) {
                          return _vm.addNewGlobalFilter()
                        },
                      },
                    },
                    [
                      _c("GjIcon", { attrs: { name: "Plus", size: "18" } }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("Add Filter"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "dashboard-configs-right" },
                [
                  _c("div", { staticClass: "date-position__right" }, [
                    _c("span", { staticClass: "calendar__range-label" }, [
                      _vm._v("Show data per:"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "header__wrapper-input calendar__range-input",
                      },
                      [
                        _c("date-picker-modal", {
                          attrs: {
                            "start-end-date": _vm.date,
                            "date-range": _vm.dateRange,
                            isOnShare: false,
                          },
                          on: {
                            "update-selected-date": _vm.updateSelectedDate,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "auto-refresh__dropdown",
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("GjIcon", {
                                      attrs: { name: "RefreshAlt", size: "18" },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.dashboardAutoRefresh.text) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                                _c("GjIcon", { attrs: { name: "ArrowDown" } }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1087342787
                      ),
                    },
                    _vm._l(_vm.autoRefreshOptions, function (item) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: item.id,
                          on: {
                            click: function ($event) {
                              return _vm.changeAutoRefresh(item, false)
                            },
                          },
                        },
                        [
                          _c("GjIcon", {
                            attrs: { name: "RefreshAlt", size: "18" },
                          }),
                          _vm._v(" " + _vm._s(item.text) + " "),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.predefinedFilters?.filters?.length > 0 &&
      !_vm.loadingDashboard &&
      !_vm.fullScreenWidget
        ? _c("FilterTags", {
            attrs: {
              filters: _vm.predefinedFilters,
              tableId: _vm.tableId,
              isDisabled: true,
              filterType: "predefined",
            },
            on: { "update-filters": _vm.savePredefinedFilters },
          })
        : _vm._e(),
      _vm.globalFilters.filters.length > 0 &&
      !_vm.loadingDashboard &&
      !_vm.fullScreenWidget
        ? _c("FilterTags", {
            attrs: { filters: _vm.globalFilters, tableId: _vm.tableId },
            on: { "update-filters": _vm.saveFilters },
          })
        : _vm._e(),
      _c("DeleteModal", {
        attrs: {
          id: "deleteDashboardModal",
          title: "Delete Dashboard",
          type: "dashboard",
          itemName: _vm.selectedDashboard?.name,
        },
        on: {
          delete: function ($event) {
            return _vm.deleteDashboard(_vm.selectedDashboard)
          },
        },
      }),
      _c("modal-filter", {
        key: "globalFilter",
        ref: "globalFilter",
        attrs: {
          filters: _vm.globalFilters.filters,
          logicalOperator: _vm.globalFilters.logicalOperator,
        },
        on: { "save-filters": _vm.saveFilters },
      }),
      _c("modal-filter", {
        key: "predefinedFilter",
        ref: "predefinedFilter",
        attrs: {
          filters: _vm.predefinedFilters.filters,
          logicalOperator: _vm.dashboard.predefinedGlobalFilterLogicalOperator,
          "filter-type": "predefined",
          "global-disabled": true,
        },
        on: { "save-predefined": _vm.savePredefinedFilters },
      }),
      _vm.loadingDashboard
        ? _c(
            "div",
            { staticClass: "loading-bar__wrapper" },
            [_c("LoadingBar")],
            1
          )
        : _c("GridLayout", { attrs: { mode: "view" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }