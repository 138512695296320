var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("LoadingBar", { staticClass: "loading-bar__wrapper" })
    : _c(
        "div",
        {
          staticClass:
            "dashboards__container view-share-details create-wrapper",
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "share__header" },
              [
                _c("ShareHeader", {
                  attrs: {
                    title: _vm.typeIsShare
                      ? "Share Dashboard"
                      : "Embed Dashboard",
                    desc: "These settings allow users to interact with the dashboard within these limits, while the shared dashboard's functionality for administrators remains unrestricted.",
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { disabled: !_vm.canModifyShare },
                    on: { click: _vm.editShare },
                  },
                  [
                    _c("GjIcon", {
                      attrs: { name: "Edit", size: "18", color: "#2c3e50" },
                    }),
                    _vm._v(" Edit "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "share-spacer" }),
            _c(
              "div",
              [
                _c("p", { staticClass: "config-title" }, [
                  _vm._v(_vm._s(_vm.typeIsShare ? "Link" : "Embed Code")),
                ]),
                _vm.typeIsShare
                  ? _c("p", { staticClass: "details-tag" }, [
                      _c("span", [_vm._v(_vm._s(_vm.data.shareUrl))]),
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.copyLink(_vm.data.shareUrl)
                            },
                          },
                        },
                        [
                          _c("GjIcon", {
                            attrs: {
                              name: "Copy",
                              color: "#A3B0C2",
                              size: "18",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _c(
                      "div",
                      { staticClass: "embed-details__container input-width" },
                      [
                        _c("b-form-textarea", {
                          staticClass: "embed-code__container",
                          attrs: {
                            disabled: "",
                            "no-resize": "",
                            rows: "13",
                            "max-rows": "13",
                          },
                          model: {
                            value: _vm.data.embedCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "embedCode", $$v)
                            },
                            expression: "data.embedCode",
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn embed-copy__btn",
                            on: {
                              click: function ($event) {
                                return _vm.copyLink(_vm.data.embedCode)
                              },
                            },
                          },
                          [
                            _c("GjIcon", {
                              attrs: {
                                name: "Copy",
                                color: "#A3B0C2",
                                size: "20",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                _c("p", { staticClass: "config-title" }, [_vm._v("Title")]),
                _c("span", { staticClass: "share-title__text" }, [
                  _vm._v(_vm._s(_vm.data.name)),
                ]),
                _c("div", { staticClass: "share-spacer" }),
                _c("p", { staticClass: "config-title" }, [
                  _vm._v("Properties"),
                ]),
                _c(
                  "div",
                  { staticClass: "input-width tag__container" },
                  _vm._l(_vm.shareDetails.propertyIds, function (property) {
                    return _c(
                      "span",
                      { key: property, staticClass: "detailed-tag" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.properties?.find((item) => item.id === property)
                              ?.name
                          )
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c("MessagePanel", {
                  staticClass: "info__container",
                  attrs: {
                    "background-color": "#F6F8F9",
                    "border-color": "#F6F8F9",
                    "icon-name": "Info",
                    "icon-color": "#7483A1",
                    "icon-size": "18",
                    text: `User is <b style='color:#455A82;'>${
                      _vm.shareDetails.enablePropertyFilter
                        ? "allowed"
                        : "not allowed"
                    }</b> to select/unselect the shared properties.`,
                    "text-color": "#7483A1",
                  },
                }),
                _c("p", { staticClass: "config-title" }, [
                  _vm._v("Share data between"),
                ]),
                _c(
                  "span",
                  { staticClass: "date__container" },
                  [
                    _c("GjIcon", {
                      attrs: { name: "Calendar", color: "#667C99", size: "14" },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatDate(_vm.shareDetails.startDate)) +
                        " - " +
                        _vm._s(_vm.formatDate(_vm.shareDetails.endDate)) +
                        " "
                    ),
                  ],
                  1
                ),
                _c("MessagePanel", {
                  staticClass: "info__container",
                  attrs: {
                    "background-color": "#F6F8F9",
                    "border-color": "#F6F8F9",
                    "icon-name": "Info",
                    "icon-color": "#7483A1",
                    "icon-size": "18",
                    text: `User is <b style='color:#455A82;'>${
                      _vm.shareDetails.enableDateFilter
                        ? "allowed"
                        : "not allowed"
                    }</b> to select different dates within the selected date range.`,
                    "text-color": "#7483A1",
                  },
                }),
                _c("p", { staticClass: "config-title" }, [
                  _vm._v("Filters included"),
                ]),
                _vm.shareDetails?.globalFilters?.length > 0
                  ? _c("FilterTags", {
                      staticClass: "shared-filters__container",
                      attrs: {
                        filters: _vm.shareDetails.globalFilters,
                        tableId: _vm.tableId,
                        "is-on-share": true,
                        "are-disabled": true,
                        "can-edit-tags": false,
                      },
                    })
                  : _c("MessagePanel", {
                      staticClass: "info__container",
                      attrs: {
                        "background-color": "#F6F8F9",
                        "border-color": "#F6F8F9",
                        "icon-name": "Info",
                        "icon-color": "#7483A1",
                        "icon-size": "18",
                        text: "No filters were included.",
                        "text-color": "#7483A1",
                      },
                    }),
                !_vm.typeIsShare
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "share-spacer" }),
                        _c("p", { staticClass: "config-title" }, [
                          _vm._v("Allowed Origins"),
                        ]),
                        _vm.data?.allowedOrigins?.length > 0
                          ? _c(
                              "div",
                              { staticClass: "input-width tag__container" },
                              [
                                _vm._l(
                                  _vm.data.allowedOrigins,
                                  function (origin, index) {
                                    return _c(
                                      "span",
                                      {
                                        key: index,
                                        staticClass: "detailed-tag",
                                      },
                                      [_vm._v(_vm._s(origin))]
                                    )
                                  }
                                ),
                                _c("MessagePanel", {
                                  staticClass:
                                    "input-width allowed-origins__message",
                                  attrs: {
                                    "background-color": "#F6F8F9",
                                    "border-color": "#F6F8F9",
                                    "icon-name": "Info",
                                    "icon-color": "#7483A1",
                                    text: "Allowed Origins are URLs that\n    will be allowed to make requests from JavaScript Clients to Bisko API\n    (typically used with CORS). By default, none of callback URLs will be\n    allowed. This field allows you to enter origins that you want to allow.",
                                    "text-color": "#7483A1",
                                  },
                                }),
                              ],
                              2
                            )
                          : _c("MessagePanel", {
                              staticClass:
                                "info__container input-width tag__container",
                              attrs: {
                                "background-color": "#F6F8F9",
                                "border-color": "#F6F8F9",
                                "icon-name": "Info",
                                "icon-color": "#7483A1",
                                "icon-size": "18",
                                text: "No origins were allowed.",
                                "text-color": "#7483A1",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("Footer", {
            attrs: {
              viewMode: true,
              "cancel-id": `cancel-create-share`,
              "create-id": `add-share`,
            },
            on: {
              back: function ($event) {
                return _vm.cancel()
              },
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }