<template>
  <div v-if="widget" class="side-bar">
    <div role="tablist" class="side-bar__holder">
      <div class="title__header">
        <h1>
          {{ widgetTypes.find((item) => item.id === widget.widgetType).name }}
        </h1>

        <button
          type="button"
          @click="closeConfiguration()"
          class="close-config-btn"
        >
          <img
            class="back-arrow__icon"
            :src="require(`@/assets/img/widget-menu-icon.svg`)"
          />
        </button>
      </div>
      <b-tabs class="widget__tabs">
        <b-tab value="configuration" active @click="handleActiveTab(0)">
          <template #title>
            <img
              class="back-arrow__icon"
              :src="require(`@/assets/img/settings-icon.svg`)"
              v-b-tooltip.hover
              :title="activeTab !== CONFIGURATION_VALUE ? 'Configuration' : ''"
            />
            <span v-if="activeTab === CONFIGURATION_VALUE">Configuration</span>
          </template>
          <div class="configurations__holder">
            <DocumentationLink
              documentation-text="To configure a widget, follow the steps described in the"
              :documentation-link="`${getDocumentationLink()}/widgets`"
            />
            <p class="config__title">Title</p>
            <input
              class="config__input"
              type="text"
              v-model="widget.title"
              @input="clearWidgetTitleError()"
              placeholder="Enter title here..."
            />
            <p v-if="!!widget.titleError" class="aggregation-error__text">
              {{ widget.titleError }}
            </p>
            <div class="configuration__items">
              <div class="spacer"></div>
              <div
                v-if="checkWidgetType(widgetTypes, widget, 'Bar Chart')"
                class="extra-bar-configs__container"
              >
                <div class="bar-stacked__config">
                  <p class="config__title">Stacked bar</p>
                  <b-form-checkbox
                    switch
                    size="md"
                    id="checkbox-1"
                    v-model="widget.stacked"
                    name="checkbox-1"
                  >
                  </b-form-checkbox>
                </div>
                <div class="bar-stacked__config">
                  <p class="config__title">Group by date</p>
                  <b-form-checkbox
                    switch
                    size="md"
                    id="checkbox-2"
                    v-model="widget.groupByDate"
                    name="checkbox-2"
                  >
                  </b-form-checkbox>
                </div>
              </div>

              <p class="config__title">Select Table</p>
              <v-select
                ref="tableSelect"
                v-if="tableOptions"
                v-model="selectedTable"
                :clearable="false"
                :options="
                  checkWidgetType(widgetTypes, widget, 'Map Chart')
                    ? tableOptions.filter(
                        (item) => item.hasGeoLocationCountry === true
                      )
                    : tableOptions
                "
                @input="tableSelected"
                :getOptionLabel="(x) => x.name"
                placeholder="Select"
                label="name"
              />
              <div class="spacer"></div>
              <div v-if="selectedTable">
                <p class="config__title">Select field(s)</p>
                <Multiselect
                  ref="multi-select-fields"
                  v-if="fieldsWithoutAggregations"
                  v-model="selectedFieldList"
                  :options="fieldsWithoutAggregations"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  selectLabel=""
                  selectGroupLabel=""
                  deselectGroupLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  placeholder="Search for fields..."
                  label="name"
                  track-by="columnId"
                  @select="selectField"
                  @remove="deleteField"
                  class="fields__multiselect fields__multiselect-only"
                >
                  <template slot="selection">
                    <span class="fields__placeholder">
                      <img
                        :src="require(`@/assets/img/plus-icon.svg`)"
                        alt="Plus icon"
                      />
                      Select field(s)
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span class="d-flex justify-content-between">
                      <span> {{ props.option.name }} </span>
                      <span
                        v-if="
                          selectedFieldList
                            ? selectedFieldList.find(
                                (field) =>
                                  field?.columnId === props.option.columnId
                              )
                            : false
                        "
                        class="mr-50"
                      >
                        <img
                          :src="require(`@/assets/img/check-icon.svg`)"
                          alt="Check icon"
                        />
                      </span>
                    </span>
                  </template>
                </Multiselect>
                <div class="spacer"></div>
                <div
                  v-if="selectedFieldList?.length > 0"
                  class="aggregation-container"
                >
                  <div class="aggregation__text-container">
                    <p class="config__title">Select aggregation for each</p>
                    <p
                      :class="
                        widget.fields.length > widget.maxAggregation
                          ? 'fields-error__text'
                          : 'fields-info__text'
                      "
                    >
                      {{ widget.fields.length }}/{{ widget.maxAggregation }}
                      selected
                    </p>
                  </div>
                  <div
                    class="aggregation-item"
                    v-for="field in selectedFieldList"
                    :key="field.id"
                  >
                    <Multiselect
                      v-if="fieldsList[widget.tableId]"
                      v-model="widget.fields"
                      :options="
                        fieldsList[widget.tableId].find(
                          (item) => item.id === field.columnId
                        )?.aggregationFunctions
                      "
                      label="name"
                      :multiple="true"
                      placeholder=""
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :toggle="true"
                      :searchable="false"
                      selectLabel=""
                      selectGroupLabel=""
                      deselectGroupLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      track-by="label"
                      class="fields__multiselect"
                      @select="addAggregation"
                      @remove="removeAggregation"
                    >
                      <template slot="selection">
                        <div class="aggregation__placeholder">
                          <span>
                            {{
                              fieldsList[widget.tableId].find(
                                (item) => item.id === field.columnId
                              ).groupName
                            }}
                            <span class="aggregation__length">
                              ({{
                                widget.fields.filter(
                                  (item) => item.columnId === field.columnId
                                ).length === 1
                                  ? widget.fields.find(
                                      (item) => item.columnId === field.columnId
                                    )?.name
                                  : widget.fields.filter(
                                      (item) => item.columnId === field.columnId
                                    ).length
                              }})
                            </span>
                          </span>
                          <button
                            title="Delete"
                            type="button"
                            @click="isFieldUsedForForecast(field)"
                            class="delete-filter btn-delete"
                          >
                            <GjIcon name="Delete" size="14" color="#E34850" />
                          </button>
                        </div>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span class="d-flex justify-content-between">
                          <span> {{ props.option.name }} </span>
                          <span
                            v-if="
                              widget.fields
                                ? widget.fields.some((field) =>
                                    readyFunctions?.includes(field.name)
                                      ? field?.columnId ===
                                        props.option.columnId
                                      : field?.columnId ===
                                          props.option.columnId &&
                                        field.aggregationType ===
                                          props.option.aggregationType
                                  )
                                : false
                            "
                            class="mr-50"
                          >
                            <img
                              :src="require(`@/assets/img/check-icon.svg`)"
                              alt="Check icon"
                            />
                          </span>
                        </span>
                      </template>
                    </Multiselect>
                  </div>
                  <p
                    v-if="widget.fields.length > widget.maxAggregation"
                    class="aggregation-error__text"
                  >
                    Aggregation function limit ({{ widget.maxAggregation }}) has
                    been reached.
                  </p>
                </div>

                <div
                  v-if="
                    showGroupBy(widget.widgetType) &&
                    basicFieldsList[widget.tableId]
                  "
                >
                  <p v-if="fieldsWithoutAggregations" class="config__title">
                    Group by
                  </p>
                  <v-select
                    v-if="parameters"
                    v-model="widget.groupByField"
                    :clearable="true"
                    :options="basicFieldsList[widget.tableId]"
                    :getOptionLabel="(x) => x.name"
                    label="name"
                    placeholder="Select"
                  />
                  <template
                    v-if="
                      widget.groupByField &&
                      widget.groupByField.type === columnTypes.KEY_VALUE_PAIR
                    "
                  >
                    <div class="spacer"></div>
                    <p class="config__title">Group by key *</p>
                    <input
                      class="config__input"
                      type="text"
                      v-model="widget.groupByFieldKey"
                      placeholder="Enter group by key"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab
          v-if="
            widget.widgetType ===
            widgetTypes.find((item) => item.name === 'Line Chart').id
          "
          value="forecast"
          :disabled="!aiConfigurations.forecastingIsEnabled"
          @click="handleActiveTab(1)"
        >
          <template #title>
            <img
              class="back-arrow__icon"
              :src="require(`@/assets/img/forecast.svg`)"
              v-b-tooltip.hover
              :title="activeTab !== FORECAST_VALUE ? 'Forecasting' : ''"
            />
            <span v-if="activeTab === FORECAST_VALUE">Forecasting</span>
          </template>
          <div class="configurations__holder">
            <div v-if="widget?.fields?.length > 0">
              <p class="config__title">Select Field</p>
              <v-select
                v-model="selectedForecast"
                :clearable="true"
                :options="widget.fields"
                @input="applyForecast"
                :getOptionLabel="(x) => x?.label"
                placeholder="Select"
                label="name"
              />
            </div>
            <div v-else class="tabs-empty-state__container">
              <h6>No configurations for this widget</h6>
              <p>
                Select the table and fields for this widget to configure the
                data you would like to display.
              </p>
            </div>
            <div class="spacer"></div>
            <div v-if="selectedForecast">
              <p class="config__title">Select date range</p>
              <b-form-group class="forecast-range">
                <b-form-radio-group
                  v-model="selectedForecast.forecastDateRange"
                  :options="forecastRanges"
                  @change="changeForecastDateRange"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </b-tab>
        <b-tab value="filter" @click="handleActiveTab(2)">
          <template #title>
            <img
              class="back-arrow__icon"
              :src="require(`@/assets/img/filter-icon.svg`)"
              v-b-tooltip.hover
              :title="activeTab !== FILTER_VALUE ? 'Filter by' : ''"
            />
            <span v-if="activeTab === FILTER_VALUE">Filter by</span>
          </template>
          <div class="configurations__holder">
            <ConfigurationFilter
              v-if="widget.filters.length !== 0 && widget.tableId"
              :filters="widget.filters"
              :has-forecast="
                widget.settings.forecastStatus &&
                widget.settings.forecastStatus !== 1
              "
              :globalDisabled="false"
              :operator="widget.logicalOperator"
              :table-id="widget.tableId"
              @update:operator="(value) => (widget.logicalOperator = value)"
            />
            <div
              v-else-if="widget.tableId"
              class="tabs-empty-state__container no-outline"
            >
              <button @click="addFilter" type="button" class="btn">
                <GjIcon name="Plus_fill" size="14" />
                Create new
              </button>
            </div>
            <div v-else class="tabs-empty-state__container">
              <h6>No configurations for this widget</h6>
              <p>
                Select the table and fields for this widget to configure the
                data you would like to display.
              </p>
            </div>
          </div>
        </b-tab>
        <b-tab
          v-if="!checkWidgetType(widgetTypes, widget, 'Card')"
          value="display"
          class="configurations__holder"
          @click="handleActiveTab(3)"
        >
          <template #title>
            <img
              class="back-arrow__icon"
              :src="require(`@/assets/img/display-icon.svg`)"
              v-b-tooltip.hover
              :title="activeTab !== DISPLAY_VALUE ? 'Display' : ''"
            />
            <span v-if="activeTab === DISPLAY_VALUE">Display</span>
          </template>
          <div v-if="widget?.fields.length > 0">
            <color-picker
              v-for="(field, index) in widget?.fields"
              :color="field?.color"
              :title="field?.label"
              :type="widget.widgetType"
              :name="field?.name"
              @update:label="(value) => updateLabel(value, field, widget)"
              @update:color="
                (value) => {
                  updateColor(value, field);
                }
              "
              :showColor="
                !checkWidgetType(widgetTypes, widget, 'Table') &&
                !checkWidgetType(widgetTypes, widget, 'Map Chart')
                  ? true
                  : false
              "
              :key="index"
            />
            <div v-if="checkWidgetType(widgetTypes, widget, 'Map Chart')">
              <color-picker
                :color="widget.settings.maxColor"
                title="Max Color"
                :type="widget.widgetType"
                :showColor="true"
                name="Max Color"
                @update:color="
                  (colorValue) => {
                    updateColor(colorValue, 'maxColor');
                  }
                "
              />
              <color-picker
                :color="widget.settings.minColor"
                title="Min Color"
                :type="widget.widgetType"
                :showColor="true"
                name="Min Color"
                @update:color="
                  (colorValue) => {
                    updateColor(colorValue, 'minColor');
                  }
                "
              />
            </div>
          </div>
          <div v-else class="tabs-empty-state__container">
            <h6>No configurations for this widget</h6>
            <p>
              Select the table and fields for this widget to configure the data
              you would like to display.
            </p>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div class="btn__container">
      <b-button
        variant="outline-primary"
        :disabled="
          widget.fields.length === 0 ||
          widget.fields.length > widget.maxAggregation ||
          (widget.groupByField &&
            widget.groupByField.type === columnTypes.KEY_VALUE_PAIR &&
            !widget.groupByFieldKey)
        "
        @click="visualizeData"
        >Visualize Data</b-button
      >
    </div>
    <b-modal
      ref="forecast-warning"
      id="forecast-warning"
      modal-class="forecast-warning__modal"
      ok-title="Remove anyway"
      ok-variant="danger"
      centered
      @ok="deleteField(null)"
    >
      <template #modal-header="{ close }">
        <span>Remove Field</span>
        <button size="sm" @click="close()" class="bg-transparent border-0">
          <GjIcon name="Close" />
        </button>
      </template>
      <div class="forecast-warning__contanier">
        <p>
          Forecasting relies on the
          <span>{{ fieldToDelete.label }}</span> field, and deselecting it will
          disable the forecasting process.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store";
import { GjIcon, showToast } from "@nodus/utilities-front";
import {
  BButton,
  BFormGroup,
  BFormRadioGroup,
  BModal,
  BTab,
  BTabs,
} from "bootstrap-vue";
import _ from "lodash";
import Multiselect from "vue-multiselect";
import { VueSelect } from "vue-select";
import Field from "../../models/Field";
import Filter from "../../models/Filter";
import { checkWidgetType } from "../../utils/checkWidgetType";
import { columnTypes } from "../../utils/constants";
import { getDocumentationLink } from "../../utils/documentationLinks";
import ColorPicker from "../ColorPicker.vue";
import DocumentationLink from "../DocumentationLink.vue";
import ConfigurationFilter from "./components/ConfigurationFilter.vue";

const CONFIGURATION_VALUE = 0;
const FORECAST_VALUE = 1;
const FILTER_VALUE = 2;
const DISPLAY_VALUE = 3;

export default {
  components: {
    "color-picker": ColorPicker,
    Multiselect,
    ConfigurationFilter,
    BButton,
    "v-select": VueSelect,
    BTabs,
    BTab,
    BFormGroup,
    BFormRadioGroup,
    BModal,
    GjIcon,
    DocumentationLink,
  },
  data() {
    return {
      columnTypes,
      CONFIGURATION_VALUE,
      FILTER_VALUE,
      DISPLAY_VALUE,
      FORECAST_VALUE,
      getDocumentationLink,
      activeTab: 0,
      checkWidgetType,
      selectedTable: null,
      selectedAggregations: null,
      checkbox: true,
      organizationId: this.$route?.params?.organizationId,
      fieldToDelete: {},
      aggregationToDelete: {},
      debounce: _.debounce(function (val, field) {
        let isMap = typeof field !== "object";
        if (!isMap) {
          let index = this.widget.fields.findIndex(
            (x) =>
              x.columnId === field.columnId &&
              x.aggregationType === field.aggregationType
          );
          this.widget.fields[index].color = val;
        } else {
          this.widget.settings[field] = val;
        }
      }, 100),
      forecastRanges: [
        {
          text: "Next 7 days",
          value: 7,
        },
        {
          text: "Next 30 days",
          value: 30,
        },
      ],
      selectedForecast: null,
      readyFunctions: [],
      tempFields: [],
    };
  },
  computed: {
    widget: {
      get() {
        let index = this.layout.findIndex(
          (item) => item.i === this.selectedItem
        );

        return this.layout[index];
      },
      set(value) {
        let index = this.layout.findIndex(
          (item) => item.i === this.selectedItem
        );
        this.layout[index] = value;
      },
    },
    colors: {
      get() {
        return this.widget.fields.map((x) => x.color);
      },
    },
    canClear() {
      return !!this.widget?.data?.height;
    },
    width: {
      get() {
        let index = this.layout.findIndex(
          (item) => item.i === this.selectedItem
        );

        return this.layout[index].w;
      },
      set(val) {
        let index = this.layout.findIndex(
          (item) => item.i === this.selectedItem
        );
        this.layout[index].w = Number(val);
      },
    },
    height: {
      get() {
        let index = this.layout.findIndex(
          (item) => item.i === this.selectedItem
        );
        return this.layout[index].h;
      },
      set(val) {
        let index = this.layout.findIndex(
          (item) => item.i === this.selectedItem
        );
        this.layout[index].h = Number(val);
      },
    },
    layout: {
      get() {
        return store.getters["getLayoutArray"];
      },
      set(value) {
        store.commit("SET_LAYOUT_ARRAY", value);
      },
    },
    fieldsWithoutAggregations: {
      get() {
        return store.getters["getFieldsWithoutAggregation"];
      },
      set(value) {
        store.commit("SET_FIELDS_WITHOUT_AGGREGATION_LIST", value);
      },
    },
    mouseXY: {
      get() {
        return store.getters["getMouseXY"];
      },
      set(val) {
        store.commit("SET_MOUSE_XY", val);
      },
    },
    DragPos: {
      get() {
        return store.getters["getDragPos"];
      },
      set(value) {
        store.commit("SET_DRAG_POS", value);
      },
    },
    selectedItem: {
      get() {
        return store.getters["getSelectedItem"];
      },
      set(value) {
        store.commit("SET_SELECTED_ITEM", value);
      },
    },
    tableList: {
      get() {
        return store.getters["getTableList"];
      },
    },
    fieldsList: {
      get() {
        return store.getters["getFieldsList"];
      },
      set(value) {
        store.commit("SET_FIELDS_LIST", value);
      },
    },
    basicFieldsList: {
      get() {
        return store.getters["getBasicFieldsList"];
      },
      set(value) {
        store.commit("SET_BASIC_FIELDS_LIST", value);
      },
    },
    selectedFieldList: {
      get() {
        return store.getters["getSelectedFields"];
      },
      set(value) {
        store.commit("SET_SELECTED_FIELDS_LIST", value);
      },
    },
    widgetTypes: {
      get() {
        return store.getters["getWidgetTypes"];
      },
      set(value) {
        store.commit("SET_WIDGET_TYPES", value);
      },
    },
    tableOptions: {
      get() {
        return this.tableList.map((x) => {
          return {
            id: x.id,
            name: x.name,
            hasGeoLocationCountry: x.hasGeoLocationCountry,
          };
        });
      },
    },
    parameters: {
      get() {
        return store.getters["getFilterParameters"];
      },
      set(value) {
        store.commit("SET_FILTER_PARAMETERS", value);
      },
    },
    aiConfigurations: {
      get() {
        return store.getters["getAiConfigurations"];
      },
    },
  },
  async mounted() {
    this.selectedTable = this.widget.tableId
      ? this.tableList.find((x) => x.id === this.widget.tableId)
      : null;
    if (this.selectedTable) {
      let toolbar = document.querySelector(".toolbar-wrapper");
      if (this.widget.filters.length !== 0) {
        toolbar.classList.add("filter-opened");
      } else {
        toolbar.classList.remove("filter-opened");
      }
      if (!this.fieldsList[this.selectedTable.id]) {
        await store.dispatch("getFields", {
          organizationId: this.organizationId,
          id: this.selectedTable.id,
          widgetType: this.widget.widgetType,
        });
        await store.dispatch("getGroupByFields", {
          organizationId: this.organizationId,
          id: this.selectedTable.id,
        });
      }
      this.fieldsWithoutAggregations = this.fieldsList[
        this.selectedTable.id
      ].map((item) => {
        return {
          columnId: item.id,
          name: item.groupName,
        };
      });

      const tempArr = this.widget.fields.map((item) => {
        return {
          id: item.id,
          columnId: item.columnId,
          name: this.fieldsList[this.selectedTable.id].find(
            (fieldOpt) => fieldOpt.id === item.columnId
          )?.groupName,
        };
      });

      const ids = tempArr.map(({ columnId }) => columnId);
      this.selectedFieldList = tempArr.filter(
        ({ columnId }, index) => !ids.includes(columnId, index + 1)
      );

      const tempReadyFunctions = this.fieldsList[this.selectedTable.id].filter(
        (item) => item.aggregationFunctions.length === 1
      );
      this.readyFunctions = tempReadyFunctions;

      await store.dispatch("getParameters", {
        organizationId: this.organizationId,
        id: this.selectedTable.id,
      });
      await store.dispatch("getOperators", {
        organizationId: this.organizationId,
      });
    }
    this.selectedForecast = this.widget.fields.find(
      (item) => item.forecast === true
    );
    this.tempFields = [...this.widget.fields];
    const type = this.widget.widgetType;
    this.changeMaxAggregation(type);
    let index = this.layout.findIndex((item) => item.i === this.selectedItem);
    this.width = this.layout[index].w;
    this.height = this.layout[index].h;
  },
  methods: {
    handleActiveTab(tabIndex) {
      this.activeTab = tabIndex;
    },
    changeMaxAggregation(type) {
      if (
        type === this.widgetTypes.find((item) => item.name == "Card").id ||
        type === this.widgetTypes.find((item) => item.name == "Pie Chart").id
      ) {
        this.widget.maxAggregation = 1;
      } else {
        this.widget.maxAggregation = 5;
      }
    },
    showGroupBy(type) {
      return (
        type !== this.widgetTypes.find((item) => item.name == "Map Chart").id &&
        type !== this.widgetTypes.find((item) => item.name == "Card").id
      );
    },
    isFieldUsedForForecast(field) {
      const deletingField = this.widget.fields.find(
        (item) => item.columnId === field.columnId
      );
      if (deletingField?.forecast) {
        this.fieldToDelete = { ...deletingField, type: "field" };
        this.$bvModal.show("forecast-warning");
      } else {
        this.deleteField(field);
      }
    },
    deleteField(field) {
      if (!field) {
        field = this.fieldToDelete;
        this.selectedForecast = null;
        this.$bvModal.hide("forecast-warning");
        if (this.fieldToDelete.type === "field") {
          this.widget.fields = this.widget.fields.filter(
            (item) => item.columnId !== field.columnId
          );
          this.selectedFieldList = this.selectedFieldList.filter(
            (selectedField) => selectedField.columnId !== field.columnId
          );
        } else {
          const index = this.widget.fields.findIndex(
            (item) =>
              item.columnId === field.columnId &&
              item.aggregationType === field.aggregationType
          );
          this.widget.fields.splice(index, 1);
        }
      } else {
        this.widget.fields = this.widget.fields.filter(
          (item) => item.columnId !== field.columnId
        );

        this.selectedFieldList = this.selectedFieldList.filter(
          (selectedField) => selectedField.id !== field.id
        );
      }
      this.tempFields = this.widget.fields;
    },
    updateColor(val, field) {
      this.debounce(val, field);
    },
    updateLabel(val, field, wid) {
      let index = wid.fields.findIndex(
        (x) => x.name === field.name && x.columnId === field.columnId
      );
      wid.fields[index].label = val;
    },
    async tableSelected(data) {
      this.widget.tableId = data.id;
      this.widget.groupByField = null;
      this.selectedFieldList = [];
      this.widget.filters = [];
      this.widget.fields = [];
      if (!this.fieldsList[data.id]) {
        await store.dispatch("getFields", {
          organizationId: this.organizationId,
          id: data.id,
          widgetType: this.widget.widgetType,
        });
      }
      const tempReadyFunctions = this.fieldsList[data.id].filter(
        (item) => item.aggregationFunctions.length === 1
      );
      this.readyFunctions = tempReadyFunctions;

      const newArr = this.fieldsList[data.id].map((item) => {
        return {
          columnId: item.id,
          name: item.groupName,
        };
      });
      this.fieldsWithoutAggregations = newArr;

      await store.dispatch("getGroupByFields", {
        organizationId: this.organizationId,
        id: data.id,
      });
      await store.dispatch("getParameters", {
        organizationId: this.organizationId,
        id: data.id,
      });
      await store.dispatch("getOperators", {
        organizationId: this.organizationId,
      });
    },
    closeConfiguration() {
      this.selectedItem = null;
      let toolbar = document.querySelector(".toolbar-wrapper");
      toolbar.classList.remove("filter-opened");
    },
    validateWidgetTitle(title) {
      if (
        this.checkWidgetType(this.widgetTypes, this.widget, "Card") &&
        !title.trim().length
      ) {
        this.widget.titleError = "Title for Card widget cannot be empty!";
        return;
      }

      if (title.length > 30) {
        this.widget.titleError = "Title must be 30 characters or fewer!";
        return;
      }

      const pattern = /^[a-zA-Z0-9\s()_\-,.]*$/;
      const isValid = pattern.test(title);
      if (!isValid)
        this.widget.titleError =
          "Title should contain only letters, numbers, spaces, and ( ) _ - , .";
    },
    clearWidgetTitleError() {
      this.widget.titleError = null;
    },
    async visualizeData() {
      this.validateWidgetTitle(this.widget.title);
      let filtersInvalid = false;

      this.widget.filters.forEach((filter) => {
        if (!this.isFilterValid(filter)) {
          filter.invalid = true;
          filtersInvalid = true;
        } else {
          filter.invalid = false;
        }
      });

      if (filtersInvalid || !!this.widget.titleError) {
        if (filtersInvalid) showToast("error", `The filter is invalid!`);
        return;
      }

      this.widget.visualizing = true;
      this.widget.show = true;
      this.widget.loading = true;

      let res = await store.dispatch("executeQuery", {
        organizationId: this.organizationId,
        data: {
          upsertWidget: {
            ...this.widget,
            data: {},
            groupByField: this.widget?.groupByField?.id || null,
          },
        },
      });
      this.widget.data = res?.data?.queryData.data;
      if (res.error) {
        this.widget.hasError = true;
      } else {
        this.widget.hasError = false;
      }

      this.widget.loading = false;
      this.widget.visualizing = false;
    },
    addFilter() {
      let toolbar = document.querySelector(".toolbar-wrapper");
      toolbar.classList.add("filter-opened");
      this.widget.filters.push(
        new Filter({ id: null, columnId: null, filterValues: [] })
      );
    },
    isFilterValid(filter) {
      return (
        filter.columnId !== null &&
        filter.operator !== null &&
        (filter.filterValues.key
          ? !filter.filterValues.key
          : filter.filterValues.length !== 0) &&
        (this.getOperators(filter.columnId) === columnTypes.KEY_VALUE_PAIR
          ? !!filter.tempFilterKey
          : true)
      );
    },
    getOperators(id) {
      if (!id) return null;
      return this.parameters[this.widget.tableId]?.parameters.find(
        (x) => x.columnId === id
      )?.columnType;
    },
    selectField(field) {
      const list = this.fieldsList[this.widget.tableId].find(
        (item) => item?.id === field.columnId
      ).aggregationFunctions;
      const defaultItemOnList = list[0];
      this.selectedFieldList[this.selectedFieldList.length - 1] = {
        id: defaultItemOnList.id,
        columnId: defaultItemOnList.columnId,
        name: defaultItemOnList.name,
      };

      this.widget.fields.push(new Field({ ...defaultItemOnList, id: null }));
      this.widget.fields = _.cloneDeep(this.widget.fields);
    },
    addAggregation(field) {
      this.widget.fields[this.widget.fields.length - 1] = {
        ...field,
        id: null,
      };
    },
    removeAggregation(field) {
      const aggregation = this.tempFields.find(
        (item) =>
          item.columnId === field.columnId &&
          item.aggregationType === field.aggregationType
      );
      if (aggregation?.forecast) {
        this.widget.fields = [...this.tempFields];
        this.fieldToDelete = { ...aggregation, type: "aggregation" };
        this.aggregationToDelete = aggregation;
        this.$bvModal.show("forecast-warning");
        return;
      }
      this.tempFields = this.widget.fields;
    },
    applyForecast(field) {
      if (field) {
        this.widget.fields.map((item) => {
          if (item.label !== field.label) {
            item.forecast = false;
            item.forecastDateRange = 0;
          }
        });
        field.forecast = true;
        field.forecastDateRange = 7;
      } else {
        this.deleteForecast();
      }
    },
    deleteForecast() {
      const fieldWithForecast = this.widget.fields.find(
        (item) => item.forecast === true
      );
      fieldWithForecast.forecast = false;
      fieldWithForecast.forecastDateRange = 0;
    },
    changeForecastDateRange(value) {
      this.selectedForecast.forecastDateRange = value;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/configuration-toolbar.scss";
</style>
