import moment from "moment";
import Widget from "../../models/Widget";
import { DATE_RANGE_TYPES } from "../../utils/constants";

export default {
  SET_WIDGET_TYPES(state, data) {
    state.widgetTypes = data.widgetTypes;
  },

  SET_SELECTED_ITEM(state, data) {
    state.selectedItem = data;
  },

  SET_SHOW_SIDEBAR(state, data) {
    state.showSideBar = data;
  },

  SET_LAYOUT_ARRAY: (state, data) => {
    state.layout = data;
  },

  RESET_DRAG_POS: (state) => {
    state.DragPos = new Widget({
      id: null,
      title: null,
      widgetType: 0,
      width: 1,
      height: 1,
      positionX: 5,
      positionY: 0,
      tableId: null,
      index: "drop",
      groupByField: null,
      logicalOperator: 1,
      fields: [],
      filters: [],
      settings: {
        id: null,
        minColor: "#3A81F6",
        maxColor: "#173163",
      },
    });
  },

  SET_WIDGET_COLOR_OPTION: (state, data) => {
    state.layout[data.i].options.colors[data.index] = data.color;
  },

  PUSH_TO_LAYOUT_ARRAY: (state, data) => {
    state.layout.push(data);
  },

  REMOVE_FROM_LAYOUT_ARRAY: (state, val) => {
    const index = this.layout.map((item) => item.i).indexOf(val);
    state.layout[index].show = false;
    state.layout.splice(index, 1);
  },

  SET_MOUSE_XY: (state, data) => {
    state.mouseXY = data;
  },

  SET_DRAG_POS: (state, data) => {
    state.DragPos = data;
  },

  SET_DASHBOARD_LIST: (state, data) => {
    state.dashboardsData.rows = data;
  },

  SET_TABLE_PAGE(state, page) {
    state.dashboardsData.page = page;
  },

  SET_TOTAL_RECORDS: (state, count) => {
    state.dashboardsData.totalRecords = count;
  },

  SET_SELECTED_DASHBOARD: (state, data) => {
    state.selectedDashboard = data;
    if (!data?.id) {
      state.layout = [];
    }
  },

  SET_TABLE_LIST: (state, data) => {
    state.tableList = data;
  },

  SET_FIELDS_LIST: (state, data) => {
    state.fieldsList[data.id] = data.fields;
  },

  SET_FIELDS_WITHOUT_AGGREGATION_LIST: (state, data) => {
    state.fieldsWithoutAggregations = data;
  },

  SET_BASIC_FIELDS_LIST: (state, data) => {
    state.basicFieldsList[data.id] = data.groupByField;
  },

  SET_SELECTED_FIELDS_LIST: (state, data) => {
    state.selectedFieldList = data;
  },

  SET_FILTER_PARAMETERS: (state, data) => {
    state.filterParameters[data.id] = data;
  },

  SET_FILTER_OPERATORS: (state, data) => {
    state.filterOperators = data;
  },

  SET_PROPERTIES: (state, data) => {
    state.properties = data;
  },

  SET_PROPERTIES_SELECTED: (state, propertyId) => {
    state.propertiesSelected = propertyId;
  },

  SET_SELECTED_DATES: (state, data) => {
    state.selectedDates = data;
  },

  SET_EMBED_FILTERS: (state, data) => {
    state.embedFilters = data;
  },

  SET_DASHBOARD: (state, dashboard) => {
    state.dashboard = dashboard;
  },

  SET_GLOBAL_FILTERS: (state, data) => {
    state.globalFilters = data;
  },

  SET_DASHBOARD_FILTERED_PARAMS: (state, data) => {
    state.filteredDashboardParameters = data;
  },

  SET_DASHBOARD_TABLE_IDS: (state, data) => {
    state.dashboardTableIds = data;
  },
  SET_IS_DASHBOARD_FULL_SCREEN: (state, data) => {
    state.isDashobardFullScreen = data;
  },
  SET_FULL_SCREEN_WIDGET: (state, data) => {
    state.fullScreenWidget = data;
  },

  SET_RANGE_DATE_OPTIONS(state) {
    state.customRangeDate = [];

    const now = new Date();
    const toToday = moment(now).endOf("day").format();
    const fromToday = moment(now).startOf("day").format();
    const today = {
      id: DATE_RANGE_TYPES.TODAY,
      disabled: false,
      text: "Today",
      fromDate: moment(fromToday).format("yyyy-MM-DD"),
      toDate: moment(toToday).format("yyyy-MM-DD"),
      displayDate: `${moment(fromToday).format("yyyy-MM-DD")} to ${moment(
        toToday
      ).format("yyyy-MM-DD")}`,
    };

    const fromYesterday = moment(now).subtract(1, "days").format();

    const yesterday = {
      id: DATE_RANGE_TYPES.YESTERDAY,
      disabled: false,
      text: "Yesterday",
      fromDate: moment(fromYesterday).format("yyyy-MM-DD"),
      toDate: moment(fromYesterday).format("yyyy-MM-DD"),
      displayDate: `${moment(fromYesterday).format("yyyy-MM-DD")} to ${moment(
        fromYesterday
      ).format("yyyy-MM-DD")}`,
    };

    const LAST_7_DAYSStartDate = moment(now)
      .subtract(6, "days")
      .format("YYYY-MM-DD");

    const LAST_7_DAYS = {
      id: DATE_RANGE_TYPES.LAST_7_DAYS,
      disabled: false,
      text: "Last 7 Days",
      fromDate: LAST_7_DAYSStartDate,
      toDate: moment(toToday).format("yyyy-MM-DD"),
      displayDate: `${LAST_7_DAYSStartDate} to ${moment(toToday).format(
        "yyyy-MM-DD"
      )}`,
    };

    const fromThisWeek = moment(now).startOf("isoWeek").format();

    const thisWeek = {
      id: DATE_RANGE_TYPES.THIS_WEEK,
      disabled: false,
      text: "This Week",
      fromDate: moment(fromThisWeek).format("yyyy-MM-DD"),
      toDate: moment(toToday).format("yyyy-MM-DD"),
      displayDate: `${moment(fromThisWeek).format("yyyy-MM-DD")} to ${moment(
        toToday
      ).format("yyyy-MM-DD")}`,
    };

    const fromThisMonth = moment(now).startOf("month").format();

    const thisMonth = {
      id: DATE_RANGE_TYPES.THIS_MONTH,
      disabled: false,
      text: "This Month",
      fromDate: moment(fromThisMonth).format("yyyy-MM-DD"),
      toDate: moment(toToday).format("yyyy-MM-DD"),
      displayDate: `${moment(fromThisMonth).format("yyyy-MM-DD")} to ${moment(
        toToday
      ).format("yyyy-MM-DD")}`,
    };

    const LAST_30_DAYSStartDate = moment(toToday)
      .subtract(29, "days") // Subtracting 29 days to get the start date
      .format("YYYY-MM-DD");

    const LAST_30_DAYS = {
      id: DATE_RANGE_TYPES.LAST_30_DAYS,
      disabled: false,
      text: "Last 30 Days",
      fromDate: LAST_30_DAYSStartDate,
      toDate: moment(toToday).format("yyyy-MM-DD"),
      displayDate: `${LAST_30_DAYSStartDate} to ${moment(toToday).format(
        "yyyy-MM-DD"
      )}`,
    };

    const LAST_90_DAYSStartDate = moment(toToday)
      .subtract(89, "days")
      .format("YYYY-MM-DD");

    const LAST_90_DAYS = {
      id: DATE_RANGE_TYPES.LAST_90_DAYS,
      disabled: false,
      text: "Last 90 Days",
      fromDate: LAST_90_DAYSStartDate,
      toDate: moment(toToday).format("yyyy-MM-DD"),
      displayDate: `${LAST_90_DAYSStartDate} to ${moment(toToday).format(
        "yyyy-MM-DD"
      )}`,
    };

    const custom = {
      id: DATE_RANGE_TYPES.CUSTOM,
      disabled: false,
      text: "Custom",
      fromDate: toToday,
      toDate: toToday,
      displayDate: null,
    };

    state.customRangeDate.push(today);
    state.customRangeDate.push(yesterday);
    state.customRangeDate.push(thisWeek);
    state.customRangeDate.push(LAST_7_DAYS);
    state.customRangeDate.push(thisMonth);
    state.customRangeDate.push(LAST_30_DAYS);
    state.customRangeDate.push(LAST_90_DAYS);
    state.customRangeDate.push(custom);
  },

  SET_DASHBOARD_NAME: (state, data) => {
    state.dashboardName = data;
  },

  SET_DASHBOARD_TEMPLATES: (state, data) => {
    state.dashboardTemplates = data.dashboardTemplates;
  },

  SET_RANGE_DATE_OPTIONS_FILTERED(state, data) {
    state.customRangeDate = data;
  },

  SET_DASHBOARD_AUTO_REFRESH: (state, data) => {
    state.dashboardAutoRefresh = data;
  },

  SET_REFRESH_DASHBOARD_DATA: (state, data) => {
    state.refreshDashboardData = data;
  },

  SET_DASHBOARD_HASH: (state, data) => {
    state.dashboardHash = data;
  },

  SET_DASHBOARD_SHARE_DATA: (state, data) => {
    state.dashboardSharingData.rows = data;
  },

  SET_TOTAL_DASHBOARD_SHARE_RECORDS: (state, data) => {
    state.dashboardSharingData.totalRecords = data;
  },

  SET_DASHBOARD_SHARE_STATUS: (state, data) => {
    const updatedRow = state.dashboardSharingData.rows.find(
      (item) => item.id === data.id
    );
    updatedRow.isShareEnabled = data.status;
  },

  SET_SHARE_DETAILS: (state, data) => {
    state.shareDetails = data;
  },

  SET_SHARE_TO_DELETE: (state, data) => {
    state.shareToDelete = data;
  },

  SET_SHARE_TABLE_PAGE: (state, page) => {
    state.dashboardSharingData.page = page;
  },

  SET_ACTIONS_ENABLED: (state, data) => {
    state.actionsEnabled = data;
  },

  SET_CAN_MODIFY_SHARE: (state, data) => {
    state.canModifyShare = data;
  },
  SET_DASHBOARD_CONFIGS: (state, data) => {
    state.dashboardConfigs = data;
  },
  SET_EMBED_CONFIG: (state, data) => {
    state.embedConfig = data;
  },

  SET_AI_CONFIGURATIONS: (state, data) => {
    state.aiConfigurations = data;
  },

  SET_DASHBOARDS_DROPDOWN_LIST: (state, data) => {
    state.dashboardList = data;
  },
};
