var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.filter.customLabel
    ? _c(
        "div",
        {
          attrs: { id: `filter-${_vm.filter.id}` },
          on: {
            click: function ($event) {
              return _vm.onTagItemClick(_vm.filter.id)
            },
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.filter.customLabel) + " "),
          _vm.filter.customLabel.length > _vm.maxFilterLength && _vm.showTooltip
            ? _c(
                "b-tooltip",
                {
                  attrs: {
                    target: `filter-${_vm.filter.id}`,
                    triggers: "hover",
                    placement: "top",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.filter.customLabel) + " ")]
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        {
          attrs: { id: `filter-${_vm.filter.id}` },
          on: {
            click: function ($event) {
              return _vm.onTagItemClick(_vm.filter.id)
            },
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.parameters[_vm.tableId]?.parameters?.find(
                  (item) => item.columnId === _vm.filter?.columnId
                )?.parameterName
              ) +
              " "
          ),
          _c("span", { staticClass: "filter-operator" }, [
            _vm._v(_vm._s(_vm.operatorIdToText) + " "),
          ]),
          _c("span", [_vm._v(" " + _vm._s(_vm.filterValues) + " ")]),
          _vm.showTooltip
            ? _c(
                "b-tooltip",
                {
                  attrs: {
                    target: `filter-${_vm.filter.id}`,
                    triggers: "hover",
                    placement: "top",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parameters[_vm.tableId]?.parameters?.find(
                          (item) => item.columnId === _vm.filter?.columnId
                        )?.parameterName
                      ) +
                      " " +
                      _vm._s(_vm.operatorIdToText) +
                      " " +
                      _vm._s(_vm.filterValues) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }