<template>
  <div class="toolbar-wrapper" :class="!showSideBar ? 'toolbar-hidden' : ''">
    <button type="button" class="pull-tab" v-on:click="toggleSideBar()">
      <img :src="require(`@/assets/img/pull-tab.svg`)" alt="Widget image" />
    </button>
    <widgets-toolbar v-if="!selectedItem" />
    <configuration-toolbar v-else-if="selectedItem" />
  </div>
</template>

<script>
import store from "@/store";
import ConfigurationToolbar from "./ConfigurationToolbar.vue";
import WidgetsToolbar from "./WidgetsToolbar.vue";

export default {
  name: "toolbar-wrapper",
  components: {
    WidgetsToolbar,
    ConfigurationToolbar,
  },
  data() {
    return {};
  },
  computed: {
    selectedItem: {
      get() {
        return store.getters["getSelectedItem"];
      },
      set(value) {
        store.commit("SET_SELECTED_ITEM", value);
      },
    },
    showSideBar: {
      get() {
        return store.getters["getShowSideBar"];
      },
      set(value) {
        store.commit("SET_SHOW_SIDEBAR", value);
      },
    },
  },
  methods: {
    toggleSideBar() {
      if (this.showSideBar) {
        setTimeout(() => {
          this.selectedItem = null;
        }, 250);
      }
      this.showSideBar = !this.showSideBar;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/toolbar-wrapper.scss";
</style>
