var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal-lg-create-from-template",
      attrs: {
        id: "modal-lg-create-from-template",
        "modal-class": "template-modal",
        size: "lg",
        title: "Choose a dashboard template",
        "ok-title": "Create",
        "cancel-variant": "outline-secondary",
        "cancel-title": "Cancel",
        centered: "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-disabled": !_vm.templateType,
      },
      on: {
        ok: _vm.createDashboard,
        cancel: _vm.clearSelectedTemplate,
        close: _vm.clearSelectedTemplate,
      },
    },
    [
      _c("div", [
        !_vm.templateAvailable
          ? _c("div", { staticClass: "no-templates__txt" }, [
              _vm._v(
                " Currently, there are no dashboard templates available. "
              ),
            ])
          : _c(
              "div",
              { staticClass: "templates__container" },
              _vm._l(_vm.templates, function (template) {
                return _c(
                  "button",
                  {
                    key: template.id,
                    staticClass: "template",
                    class:
                      _vm.templateType === template?.id
                        ? "template-selected"
                        : "",
                    on: {
                      click: function ($event) {
                        return _vm.setTemplate(template.id)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          template.icon ||
                          require("@/assets/img/template-img.png"),
                        alt: "template image",
                      },
                    }),
                    _c("span", [_vm._v(_vm._s(template.name))]),
                    _c("p", [_vm._v(_vm._s(template.description))]),
                  ]
                )
              }),
              0
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }