var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("LoadingBar", { staticClass: "loading-bar__wrapper" })
    : _c(
        "div",
        {
          staticClass: "create-share-view dashboards__container create-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "mb-1" },
            [
              _c("ShareHeader", {
                attrs: {
                  title: `Share ${_vm.selectedDashboard.name}`,
                  desc: "These settings allow users to interact with the dashboard within these limits, while the shared dashboard's functionality for administrators remains unrestricted.",
                },
              }),
              _c("div", { staticClass: "share-spacer" }),
              _c(
                "div",
                { staticClass: "types__wrapper" },
                _vm._l(_vm.SHARE_TYPES, function (item) {
                  return _c(
                    "div",
                    {
                      key: item?.id,
                      staticClass: "types__wrapper-content",
                      class: [
                        item?.id === _vm.selectedType?.id
                          ? "types__wrapper-content--active"
                          : "",
                        !!_vm.id ? "types__wrapper-content--disabled" : "",
                      ],
                      attrs: { id: `${item?.text}` },
                      on: {
                        click: function ($event) {
                          return _vm.chooseType(item)
                        },
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedType.id,
                            expression: "selectedType.id",
                          },
                        ],
                        attrs: {
                          id: item?.id,
                          disabled: !!_vm.id,
                          type: "radio",
                          name: "share",
                        },
                        domProps: {
                          value: item?.id,
                          checked: _vm._q(_vm.selectedType.id, item?.id),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.selectedType, "id", item?.id)
                          },
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "types__wrapper-content-info" },
                        [
                          item?.icon
                            ? _c("GjIcon", { attrs: { name: item?.icon } })
                            : _vm._e(),
                          _c("label", [_vm._v(_vm._s(item.text))]),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm.dashboardToShare
                ? _c(
                    "div",
                    [
                      _c("p", { staticClass: "config-title" }, [
                        _vm._v("Title*"),
                      ]),
                      _c("b-form-input", {
                        staticClass: "input-width",
                        attrs: { placeholder: "Add title" },
                        model: {
                          value: _vm.dashboardToShare[_vm.shareData].name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dashboardToShare[_vm.shareData],
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "dashboardToShare[shareData].name",
                        },
                      }),
                      _c("div", { staticClass: "share-spacer" }),
                      _c("p", { staticClass: "config-title" }, [
                        _vm._v("Properties"),
                      ]),
                      _vm.properties
                        ? _c(
                            "MultipleProperties",
                            _vm._b(
                              {
                                staticClass: "input-width",
                                attrs: {
                                  id: "select-properties",
                                  options: _vm.properties,
                                  classes: [
                                    "multiple-properties--one",
                                    "multiple-properties__min-width",
                                  ],
                                },
                                on: { "update-data": _vm.addProperty },
                              },
                              "MultipleProperties",
                              _vm.dashboardToShare?.propertyIds?.length > 0
                                ? {
                                    selected: _vm.dashboardToShare?.propertyIds,
                                  }
                                : {},
                              false
                            )
                          )
                        : _vm._e(),
                      _vm.selectedType.text === "Link"
                        ? _c(
                            "b-form-checkbox",
                            {
                              staticClass: "config-checkbox",
                              attrs: { id: "properties-checkbox" },
                              model: {
                                value:
                                  _vm.dashboardToShare.enablePropertyFilter,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dashboardToShare,
                                    "enablePropertyFilter",
                                    $$v
                                  )
                                },
                                expression:
                                  "dashboardToShare.enablePropertyFilter",
                              },
                            },
                            [
                              _vm._v(
                                " Allow users to select/unselect the shared properties. "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "share-spacer" }),
                      _c("p", { staticClass: "config-title" }, [
                        _vm._v("Share data between"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "header__wrapper-input calendar__range-input share-date__container input-width",
                        },
                        [
                          _c("DatePickerModal", {
                            attrs: {
                              "start-end-date": _vm.date,
                              "date-range":
                                _vm.dashboardToShare
                                  ?.dashboardSharingPeriodType,
                              "input-position": "bottom",
                            },
                            on: {
                              "update-selected-date":
                                _vm.updateSelectedShareDate,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.selectedType.text === "Link"
                        ? _c(
                            "b-form-checkbox",
                            {
                              staticClass: "config-checkbox",
                              attrs: { id: "date-checkbox" },
                              model: {
                                value: _vm.dashboardToShare.enableDateFilter,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dashboardToShare,
                                    "enableDateFilter",
                                    $$v
                                  )
                                },
                                expression: "dashboardToShare.enableDateFilter",
                              },
                            },
                            [
                              _vm._v(
                                " Allow users to select different dates within the selected date range. "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "share-spacer" }),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass:
                            "config-checkbox config-checkbox-filters",
                          attrs: {
                            switch: "",
                            size: "md",
                            id: "include-filters-checkbox",
                          },
                          model: {
                            value: _vm.includeFilters,
                            callback: function ($$v) {
                              _vm.includeFilters = $$v
                            },
                            expression: "includeFilters",
                          },
                        },
                        [_vm._v(" Include Filters ")]
                      ),
                      _vm.includeFilters
                        ? _c("FilterTags", {
                            staticClass:
                              "shared-filters__container input-width",
                            attrs: {
                              filters: _vm.dashboardToShare?.globalFilters,
                              tableId: _vm.tableId,
                              "is-on-share": true,
                              "filter-type": "share",
                            },
                            on: { "open-share-filter": _vm.openFilterModal },
                          })
                        : _vm._e(),
                      _vm.selectedType.text === "Link"
                        ? _c(
                            "b-form-checkbox",
                            {
                              staticClass: "config-checkbox",
                              attrs: { id: "filters-checkbox" },
                              model: {
                                value:
                                  _vm.dashboardToShare
                                    .enableGlobalFiltersFilter,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dashboardToShare,
                                    "enableGlobalFiltersFilter",
                                    $$v
                                  )
                                },
                                expression:
                                  "dashboardToShare.enableGlobalFiltersFilter",
                              },
                            },
                            [
                              _vm._v(
                                " Allow users to add, modify and delete additional filters. "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.selectedType.text === "Embed"
                        ? _c(
                            "div",
                            [
                              _c("div", { staticClass: "share-spacer" }),
                              _c("p", { staticClass: "config-title" }, [
                                _vm._v("Allowed Origins*"),
                              ]),
                              _c("b-form-tags", {
                                staticClass:
                                  "input-width allowed-origins__input",
                                attrs: {
                                  placeholder: "Enter Origins",
                                  "remove-on-delete": "",
                                  "add-on-change": "",
                                  "tag-validator": _vm.originValidator,
                                  "invalid-tag-text": "Invalid origin",
                                },
                                model: {
                                  value:
                                    _vm.dashboardToShare.dashboardSharingEmbed
                                      .allowedOrigins,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dashboardToShare
                                        .dashboardSharingEmbed,
                                      "allowedOrigins",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dashboardToShare.dashboardSharingEmbed.allowedOrigins",
                                },
                              }),
                              _c("MessagePanel", {
                                staticClass:
                                  "input-width allowed-origins__message",
                                attrs: {
                                  "background-color": "#F6F8F9",
                                  "border-color": "#F6F8F9",
                                  "icon-name": "Info",
                                  "icon-color": "#7483A1",
                                  text: "Allowed Origins are URLs that\n        will be allowed to make requests from JavaScript Clients to Bisko API\n        (typically used with CORS). By default, none of callback URLs will be\n        allowed. This field allows you to enter origins that you want to allow.\n        <br/>\n        <b>Example: https://example.com, http://example.com, *</b>",
                                  "text-color": "#7483A1",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.dashboardToShare
            ? _c("modal-filter", {
                key: "shareFilter",
                ref: "shareFilter",
                attrs: {
                  "filter-type": "share",
                  filters: _vm.dashboardToShare?.globalFilters,
                  logicalOperator:
                    _vm.dashboardToShare?.globalFilterLogicalOperator,
                },
                on: { "add-new-filter": _vm.addNewShareFilter },
              })
            : _vm._e(),
          _c("Footer", {
            attrs: {
              submitted: _vm.submitted,
              disableButton:
                (_vm.selectedType.text === "Link" && !_vm.validName) ||
                (_vm.selectedType.text === "Embed" && _vm.emptyNameOrOrigin),
              editMode: !!_vm.id || false,
              "cancel-id": `cancel-create-share`,
              "create-id": `add-share`,
            },
            on: {
              back: function ($event) {
                return _vm.cancel()
              },
              create: function ($event) {
                _vm.id ? _vm.update() : _vm.create()
              },
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }