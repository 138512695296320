var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "child-component widget-table" },
    [
      !_vm.showTable
        ? _c(
            "div",
            { staticClass: "table-spinner" },
            [
              _c("b-spinner", {
                attrs: { variant: "primary", label: "Spinning" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.show
        ? _c(
            "vue-good-table",
            {
              class: !_vm.showTable ? "table-loading" : "",
              attrs: {
                id: _vm.componentId,
                mode: "remote",
                columns: _vm.data.columns,
                "total-rows": _vm.data.totalRowCount,
                rows: _vm.data.rows,
                "fixed-header": false,
                height: `${_vm.height * 200}px`,
                "sort-options": {
                  enabled: _vm.createMode ? false : true,
                },
                "pagination-options": {
                  enabled: _vm.createMode ? false : true,
                },
              },
              on: { "on-sort-change": (params) => _vm.sortChanged(params) },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-row",
                    fn: function (data) {
                      return [
                        data.column.field === "PageDomain"
                          ? _c("span", [
                              _c(
                                "div",
                                { staticClass: "domains-table__container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "domains-table__container-img",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getDomainFavicon(
                                            data.row.PageDomain
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("span", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: `https://${data.row.PageDomain}`,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(data.row.PageDomain))]
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : data.column.field === "PageUrl"
                          ? _c("span", { key: data.row.originalIndex }, [
                              _c(
                                "div",
                                { staticClass: "domains-table__container" },
                                [
                                  data.row.PageUrl.length > _vm.maxRowLength
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              value: data.row.PageUrl,
                                              expression: "data.row.PageUrl",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "page-url__tooltip",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              data.row.PageUrl.substring(
                                                0,
                                                _vm.maxRowLength
                                              )
                                            ) + "..."
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(data.row.PageUrl)),
                                      ]),
                                ]
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                " " + _vm._s(data.row[data.column.field]) + " "
                              ),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                3380880234
              ),
            },
            [
              _c("template", { slot: "emptystate" }, [
                _c(
                  "div",
                  {
                    staticClass: "table-empty-state",
                    style: {
                      height:
                        _vm.height === 1
                          ? "unset"
                          : `${_vm.height * 200 - 230}px`,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/no-data-in-table.svg"),
                      },
                    }),
                    _vm._v(" No data have been added yet... "),
                  ]
                ),
              ]),
              _c("template", { slot: "pagination-bottom" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex table__pagination justify-content-between flex-wrap",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "table__pagination-left d-flex align-items-center mb-0",
                      },
                      [
                        _c("span", { staticClass: "text-nowrap" }, [
                          _vm._v("Rows per page: "),
                        ]),
                        _c("b-form-select", {
                          staticClass: "mx-25 per-page__input",
                          attrs: {
                            id: "per-page-dropdown",
                            options: ["5", "10", "50", "100"],
                            disabled: !_vm.showTable,
                          },
                          on: { input: _vm.changePerPage },
                          model: {
                            value: _vm.data.count,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "count", $$v)
                            },
                            expression: "data.count",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "table__pagination-right" }, [
                      _c(
                        "div",
                        { staticClass: "specific-page__container" },
                        [
                          _c("span", [_vm._v("Page")]),
                          _c("b-form-input", {
                            style: `--inputlength: ${_vm.pageInputLength}ch`,
                            attrs: {
                              id: "specific-page",
                              type: "number",
                              min: "1",
                              max: _vm.totalPages,
                              disabled:
                                !_vm.data.totalRowCount || !_vm.showTable,
                            },
                            on: { input: _vm.debouncedChangePage },
                            model: {
                              value: _vm.data.page,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "page", $$v)
                              },
                              expression: "data.page",
                            },
                          }),
                          _c("span", [
                            _vm._v("of " + _vm._s(_vm.totalPages || 1)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "navigate__button",
                              attrs: {
                                id: "previous-page",
                                variant: "outline-secondary",
                                disabled:
                                  _vm.cantNavigate("previous") ||
                                  !_vm.showTable,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.navigate("previous")
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "pagination__icon",
                                attrs: {
                                  src: require("@/assets/img/left-arrow.svg"),
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "pagination__text ml-25",
                                  class: {
                                    "pagination__text--disabled":
                                      _vm.cantNavigate("previous"),
                                  },
                                },
                                [_vm._v("Previous")]
                              ),
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "navigate__button",
                              attrs: {
                                id: "next-page",
                                variant: "outline-secondary",
                                disabled:
                                  _vm.cantNavigate("next") || !_vm.showTable,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.navigate("next")
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "pagination__text mr-25",
                                  class: {
                                    "pagination__text--disabled":
                                      _vm.cantNavigate("next"),
                                  },
                                },
                                [_vm._v("Next")]
                              ),
                              _c("img", {
                                staticClass: "pagination__icon",
                                attrs: {
                                  src: require("@/assets/img/right-arrow.svg"),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }