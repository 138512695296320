var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vue-grid-item v-grid-item full-screen__widget",
      class: [!_vm.item.show ? "show-placeholder" : ""],
      attrs: { id: `fullscreen-${_vm.item.i}` },
    },
    [
      _c("div", { staticClass: "filter-title__container" }, [
        _vm.item.title
          ? _c("span", { staticClass: "widget__title" }, [
              _vm._v(" " + _vm._s(_vm.item.title) + " "),
            ])
          : _vm._e(),
        _vm.type !== "embed" &&
        _vm.item?.data?.globalFilters &&
        _vm.item?.data?.globalFilters?.length > 0 &&
        !_vm.item.loading
          ? _c("div", { staticClass: "applied-filters" }, [
              _c(
                "button",
                {
                  staticClass: "btn filter-btn rounded-pill",
                  attrs: { id: `filter-btn-${_vm.item.id}` },
                  on: {
                    mouseenter: function ($event) {
                      return _vm.triggerStatusDropdown(_vm.item, true)
                    },
                    mouseleave: function ($event) {
                      return _vm.triggerStatusDropdown(null, false)
                    },
                  },
                },
                [
                  _c("GjIcon", {
                    attrs: { name: "IconparkFilter", size: "20" },
                  }),
                  _vm.openDropdown && _vm.filtersModalData?.id === _vm.item.id
                    ? _c("div", { staticClass: "filter-dropdown__container" }, [
                        _c(
                          "div",
                          { staticClass: "filter-status-dropdown" },
                          [
                            _c("FilterStatusContainer", {
                              key: _vm.filtersModalData.id,
                              attrs: {
                                filters:
                                  _vm.filtersModalData.data.globalFilters,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.item?.data?.globalFilters?.find(
                (filter) => filter.isApplied === true
              )
                ? _c("div", { staticClass: "red-circle" })
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _c("Widgets", {
        key: _vm.item.id,
        style: {
          paddingTop:
            _vm.item.title !== "" && _vm.item.title !== null ? "30px" : null,
        },
        attrs: {
          mode: "fullscreen",
          widget: _vm.item,
          widgetType: _vm.item.widgetType,
          componentId: `fullscreen-${_vm.item.widgetType}-${_vm.item.i}`,
          options: _vm.item.options,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }