<template>
  <div class="filter-status__container">
    <div class="dropdown__header">
      <span
        >Filters
        {{
          filters.filter((item) => item.isApplied === true).length > 0
            ? `(${filters.length})`
            : ""
        }}
      </span>
    </div>
    <div v-if="!hasAppliedFilters" class="no-filters__state">
      <span class="no-filters__state-title"
        >No filters have been added yet.</span
      >
      <span class="no-filters__state-desc"
        >The filters you configured were not applied in this widget.</span
      >
    </div>
    <div v-else class="filter-details__container">
      <div
        v-for="(filter, index) in appliedFilters"
        :key="index"
        class="filter-status-tag"
      >
        <GjIcon name="Check" size="18" color="#36B37E" />
        <TagText :filter="filter" :tableId="tableId" />
      </div>
      <div class="not-applied-filters__container" v-if="isShowMoreActive">
        <div
          v-for="(filter, index) in notAppliedFilters"
          class="filter-status-tag"
          :key="index"
        >
          <GjIcon name="Close" size="18" color="#E34850" />
          <TagText :filter="filter" :tableId="tableId" />
        </div>
      </div>
      <button
        v-if="showSeeMoreButton"
        class="btn"
        @click="toggleNotAppliedFilters()"
      >
        {{ isShowMoreActive ? "View Less" : "View More" }}
      </button>
    </div>
  </div>
</template>

<script>
import TagText from "./TagText.vue";
import { GjIcon } from "@nodus/utilities-front";

export default {
  props: {
    filters: {
      type: [Array, Object],
    },
  },
  components: {
    TagText,
    GjIcon,
  },
  data() {
    return {
      isShowMoreActive: false,
      tableId: "dashboard",
    };
  },
  computed: {
    appliedFilters() {
      return this.filters?.filter((item) => item.isApplied === true);
    },
    notAppliedFilters() {
      return this.filters?.filter((item) => item.isApplied === false);
    },
    hasAppliedFilters() {
      return this.appliedFilters?.length > 0;
    },
    showSeeMoreButton() {
      return this.notAppliedFilters?.length > 0;
    },
  },
  methods: {
    toggleNotAppliedFilters() {
      this.isShowMoreActive = !this.isShowMoreActive;
    },
  },
};
</script>
