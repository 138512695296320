<template>
  <div id="grid-layout" class="dashboard-overview-grid">
    <grid-layout
      v-show="!fullScreenWidget"
      :class="mode !== 'view' ? 'grid-builder' : 'dashboard-grid-layout'"
      ref="gridlayout"
      :layout.sync="layout"
      :col-num="6"
      :row-height="200"
      :is-draggable="mode !== 'view' ? true : false"
      :is-resizable="mode !== 'view' ? true : false"
      :vertical-compact="true"
      :use-css-transforms="true"
      v-on:click.stop="clearSelectedItem"
    >
      <grid-item
        class="v-grid-item"
        :class="[
          selectedItem === item.i && mode !== 'view' ? 'selected-item' : null,
          !item.show ? 'show-placeholder' : '',
        ]"
        @resized="resizedEvent(item.h, item.i)"
        :id="item.i"
        :key="item.i"
        v-for="item in layout"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :minW="1"
        :minH="1"
        :maxW="6"
      >
        <span
          v-if="mode !== 'view'"
          class="overlay__container"
          v-on:click="mode !== 'view' ? editWidget(item.i) : null"
        ></span>
        <div
          v-if="mode !== 'view'"
          class="widget__actions"
          :class="selectedItem === item.i ? 'selected' : null"
        >
          <button
            type="button"
            @click="editWidget(item.i)"
            title="Edit"
            class="edit__btn"
          >
            <img
              :src="require('@/assets/img/edit-icon.svg')"
              class="edit__btn"
            />
          </button>
          <button
            type="button"
            v-b-modal.duplicateModal
            @click="setItemToDuplicate(item)"
            title="Duplicate"
          >
            <img :src="require('@/assets/img/duplicate-icon.svg')" />
          </button>
          <button
            type="button"
            v-b-modal.widgetModal
            @click="setItemToDelete(item.i)"
            title="Delete"
          >
            <img :src="require('@/assets/img/delete-icon.svg')" />
          </button>
        </div>
        <div class="filter-title__container">
          <div class="title-with-forecast">
            <span
              v-b-tooltip.hover.top
              v-bind="item.title.length >= 20 ? { title: item.title } : null"
              class="widget__title"
            >
              {{ item.title }}
            </span>
            <ProcessingForecast
              v-if="
                mode === 'view' &&
                checkWidgetType(widgetTypes, item, 'Line Chart') &&
                item.settings.forecastStatus !== forecastStatus.NOT_ACTIVE
              "
              :data="item.settings"
              :id="item.id"
            />
          </div>
          <div class="filter-fullscreen__container">
            <div
              v-if="
                mode === 'view' &&
                type !== 'embed' &&
                item?.data?.globalFilters &&
                item?.data?.globalFilters?.length > 0 &&
                !item.loading
              "
              class="applied-filters"
            >
              <button
                class="btn filter-btn rounded-pill p-2"
                :id="`filter-btn-${item.id}`"
                @mouseenter="triggerStatusDropdown(item, true)"
                @mouseleave="triggerStatusDropdown(null, false)"
              >
                <GjIcon name="IconparkFilter" size="20" />
                <div
                  class="filter-dropdown__container"
                  v-if="openDropdown && filtersModalData?.id === item.id"
                >
                  <div class="filter-status-dropdown">
                    <FilterStatusContainer
                      :filters="filtersModalData.data.globalFilters"
                      :key="filtersModalData.id"
                    />
                  </div>
                </div>
              </button>
              <div
                v-if="
                  item?.data?.globalFilters?.find(
                    (filter) => filter.isApplied === true
                  )
                "
                class="red-circle"
              ></div>
            </div>
            <button
              v-if="
                mode === 'view' &&
                !noFullscreen &&
                !isDashobardFullScreen &&
                !fullScreenWidget &&
                !item.loading
              "
              @click="makeWidgetFullScreen(item)"
              class="btn filter-btn rounded-pill"
              :id="`fullscreen-btn-${item.id}`"
            >
              <GjIcon name="Fullscreen" size="20" />
            </button>
          </div>
        </div>

        <img
          v-if="!item.show && mode !== 'view'"
          class="widget-placeholder"
          :src="
            widgetTypes.find((widget) => widget.id === item.widgetType).image
          "
          alt="Widget image"
        />
        <Widgets
          v-else
          :mode="mode"
          :key="item.id"
          :widget="item"
          :widgetType="item.widgetType"
          :componentId="`${item.widgetType}-${item.i}`"
          :options="item.options"
          :style="{
            paddingTop:
              item.title !== '' && item.title !== null ? '30px' : null,
          }"
        />
      </grid-item>
    </grid-layout>
    <FullScreenWidget
      v-if="fullScreenWidget"
      :item="fullScreenWidget"
      :type="type"
    />
    <DeleteModal
      id="widgetModal"
      title="Delete Widget"
      type="widget"
      @delete="removeItem"
    />
  </div>
</template>

<script>
import store from "@/store";
import generateGuid from "@/utils/guid.js";
import { DeleteModal, GjIcon } from "@nodus/utilities-front";
import _ from "lodash";
import VueGridLayout from "vue-grid-layout";
import FullScreenWidget from "./FullScreenWidget.vue";
import Widgets from "./Widgets.vue";
import FilterStatusContainer from "./global-filters/FilterStatusContainer.vue";
import ProcessingForecast from "@/components/icons/ProcessingForecast.vue";
import { forecastStatus } from "@/utils/constants";
import { checkWidgetType } from "@/utils/checkWidgetType.js";

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Widgets,
    DeleteModal,
    FilterStatusContainer,
    ProcessingForecast,
    FullScreenWidget,
    GjIcon,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    type: String,
    noFullscreen: Boolean,
  },
  data() {
    return {
      checkWidgetType,
      itemToDelete: "",
      itemToDuplicate: {},
      filtersModalData: null,
      openDropdown: false,
      filterDropdownWidth: 356,
      forecastStatus,
    };
  },
  mounted() {
    if (this.mode !== "view") {
      let that = this;
      document.addEventListener(
        "dragover",
        function (e) {
          that.mouseXY.x = e.clientX;
          that.mouseXY.y = e.clientY;
        },
        false
      );
    }
  },
  computed: {
    widgetTypes: {
      get() {
        return store.getters["getWidgetTypes"];
      },
      set(value) {
        store.commit("SET_WIDGET_TYPES", value);
      },
    },
    selectedItem: {
      get() {
        return store.getters["getSelectedItem"];
      },
      set(value) {
        store.commit("SET_SELECTED_ITEM", value);
      },
    },
    mouseXY: {
      get() {
        return store.getters["getMouseXY"];
      },
      set(val) {
        store.commit("SET_MOUSE_XY", val);
      },
    },
    DragPos: {
      get() {
        return store.getters["getDragPos"];
      },
      set(value) {
        store.commit("SET_DRAG_POS", value);
      },
    },
    showSideBar: {
      get() {
        return store.getters["getShowSideBar"];
      },
      set(value) {
        store.commit("SET_SHOW_SIDEBAR", value);
      },
    },
    layout: {
      get() {
        return store.getters["getLayoutArray"];
      },
      set(value) {
        store.commit("SET_LAYOUT_ARRAY", value);
      },
    },
    isDashobardFullScreen: {
      get() {
        return store.getters["getIsDashobardFullScreen"];
      },
      set(value) {
        store.commit("SET_IS_DASHBOARD_FULL_SCREEN", value);
      },
    },
    fullScreenWidget: {
      get() {
        return store.getters["getFullScreenWidget"];
      },
      set(value) {
        store.commit("SET_FULL_SCREEN_WIDGET", value);
      },
    },
  },
  destroyed() {
    this.selectedItem = null;
  },
  methods: {
    clearSelectedItem(e) {
      if (
        e &&
        e.target ==
          document.querySelector("div.vue-grid-layout.grid-builder") &&
        e.target !== document.querySelector(".vue-resizable-handle")
      ) {
        this.selectedItem = null;
      }
    },
    removeItem() {
      const val = this.itemToDelete;
      const index = this.layout.map((item) => item.i).indexOf(val);
      this.layout[index].show = false;
      this.layout.splice(index, 1);
      if (this.selectedItem === val) {
        this.selectedItem = null;
      }
    },
    resizedEvent: function (height, i) {
      let index = this.layout.findIndex((item) => item.i === i);
      this.layout[index].data.height = height;
      this.selectedItem = i;
      if (
        checkWidgetType(this.widgetTypes, this.layout[index], "Line Chart") &&
        this.layout[index]?.data?.categories?.length < 2
      ) {
        this.layout[index].loading = true;
        setTimeout(() => {
          this.layout[index].loading = false;
        }, 100);
      }
    },
    editWidget(id) {
      if (id === this.selectedItem) return;
      store.commit("RESET_DRAG_POS");
      this.selectedItem = null;
      setTimeout(() => {
        this.selectedItem = id;
        this.showSideBar = true;
      }, 0);
    },
    setItemToDuplicate(item) {
      this.itemToDuplicate = item;
      this.duplicateItem();
    },
    setItemToDelete(item) {
      this.itemToDelete = item;
    },
    duplicateItem() {
      const item = _.cloneDeep(this.itemToDuplicate);

      let guid = generateGuid();

      item.fields.map((item) => (item.id = null));
      item.filters.map((item) => (item.id = null));
      let pos = this.findAvailablePosition(this.layout, item.w, item.h);
      let settings = item.settings;
      settings.id = null;
      store.commit("PUSH_TO_LAYOUT_ARRAY", {
        data: item.data,
        fields: item.fields,
        filters: item.filters,
        groupByField: item.groupByField,
        loading: item.loading,
        h: item.h,
        i: guid,
        id: null,
        maxAggregation: item.maxAggregation,
        logicalOperator: item.logicalOperator,
        groupByDate: item.groupByDate,
        options: item.options,
        show: item.show,
        tableId: item.tableId,
        title: item.title,
        w: item.w,
        settings,
        widgetType: item.widgetType,
        x: pos.x,
        y: pos.y,
      });

      setTimeout(() => {
        this.editWidget(guid);
        this.scrollToElement(guid);
      }, 200);
    },
    scrollToElement(elementId) {
      var element = document.getElementById(elementId);
      if (element && !this.isElementInViewport(element)) {
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = window.pageYOffset + elementPosition;
        window.scrollTo({ top: offsetPosition, behavior: "smooth" });
      }
    },
    isElementInViewport(element) {
      if (!element) return false;

      let bounding = element.getBoundingClientRect();

      if (
        element?.checkVisibility() &&
        bounding.top >= -element.offsetHeight &&
        bounding.left >= -element.offsetWidth &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth) +
            element.offsetWidth &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) +
            element.offsetHeight / 2
      ) {
        return true;
      }
      return false;
    },
    findAvailablePosition(layout, w, h) {
      let occupiedPositions = {};
      for (let i = 0; i < layout.length; i++) {
        let item = layout[i];
        for (let y = item.y; y < item.y + item.h; y++) {
          for (let x = item.x; x < item.x + item.w; x++) {
            let pos = x + "," + y;
            occupiedPositions[pos] = true;
          }
        }
      }
      for (let y = 0; ; y++) {
        for (let x = 0; x <= 6 - w; x++) {
          let available = true;
          for (let y2 = y; y2 < y + h; y2++) {
            for (let x2 = x; x2 < x + w; x2++) {
              let pos = x2 + "," + y2;
              if (occupiedPositions[pos]) {
                available = false;
                break;
              }
            }
            if (!available) break;
          }
          if (available) {
            return { x: x, y: y };
          }
        }
      }
    },
    triggerStatusDropdown(item, state) {
      this.filtersModalData = item;
      this.openDropdown = state;

      let dropdownEl;
      if (state) {
        let e = window.event;
        let posX = e.clientX;
        let maxWidth = document.body.clientWidth;
        let openLeft = posX + this.filterDropdownWidth > maxWidth;
        setTimeout(() => {
          dropdownEl = document.querySelector(".filter-dropdown__container");
          if (openLeft) {
            dropdownEl?.classList.add("open-left");
          } else {
            dropdownEl?.classList.add("open-right");
          }
        }, 0);
      }
    },
    makeWidgetFullScreen(widget) {
      this.fullScreenWidget = widget;
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/style/components/grid-layout.scss";
</style>
