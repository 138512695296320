var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "side-bar" }, [
    _c("div", { staticClass: "widgets__toolbar" }, [
      _c("h1", [_vm._v("Widgets")]),
      _c(
        "div",
        { staticClass: "widgets__container" },
        _vm._l(_vm.widgetTypes, function (widget) {
          return _c("div", { key: widget.id }, [
            _c(
              "div",
              {
                staticClass: "widget__card",
                class:
                  _vm.selectedWidget === widget.id ? "selected-widget" : "",
                attrs: { draggable: "true", unselectable: "on", id: "map" },
                on: {
                  drag: function ($event) {
                    return _vm.drag(widget.id)
                  },
                  dragend: _vm.dragend,
                  click: function ($event) {
                    return _vm.selectWidget(widget.id)
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: widget.image, alt: `${widget.name} image` },
                }),
                _c("p", [_vm._v(_vm._s(widget.name))]),
              ]
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }