var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "child-component" }, [
    _c("div", { staticClass: "card-component" }, [
      _c("div", { staticClass: "card-counter" }, [
        _c(
          "svg",
          {
            staticStyle: { "max-width": "100%" },
            attrs: {
              height: "100%",
              width: "100%",
              viewBox: "0 0 320 80",
              preserveAspectRatio: "xMinYMid meet",
              xmlns: "http://www.w3.org/2000/svg",
              "xmlns:xlink": "http://www.w3.org/1999/xlink",
            },
          },
          [
            _c(
              "text",
              {
                staticStyle: { "text-anchor": "middle" },
                attrs: {
                  x: "50%",
                  y: "50%",
                  "font-size": "55",
                  fill: "#031c3c",
                  "text-anchor": "middle",
                  "dominant-baseline": "middle",
                },
              },
              [_vm._v(" " + _vm._s(_vm.data.value) + " ")]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }