var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.widget.loading
    ? _c(
        "div",
        { staticClass: "loading-bar__wrapper" },
        [_c("LoadingBar", { attrs: { classList: "medium" } })],
        1
      )
    : _vm.widget.hasError
    ? _c("div", { staticClass: "main-component" }, [
        _c("div", { staticClass: "error-state__container" }, [
          _c("img", {
            attrs: { src: require(`../assets/img/error-icon.svg`) },
          }),
          _c("h5", [_vm._v("Oops! Widget failed to load")]),
          _c("p", [
            _vm._v(
              "Something went wrong and we were unable to load your data."
            ),
          ]),
        ]),
      ])
    : _vm.widget.data
    ? _c(
        "div",
        {
          staticClass: "main-component",
          class: _vm.checkWidgetType(_vm.widgetTypes, _vm.widget, "Table")
            ? "main-component-table"
            : "",
        },
        [
          (_vm.widget.data.series && _vm.widget.data?.series?.length <= 0) ||
          (_vm.widget.data.data &&
            Object.keys(_vm.widget.data.data).length <= 0)
            ? _c("div", { staticClass: "empty-state__container" }, [
                _vm.getWidgetType() !== ""
                  ? _c("img", {
                      staticClass: "empty-state__img",
                      attrs: {
                        src: require(`../assets/img/empty-${_vm.getWidgetType()}.png`),
                        alt: `Empty ${_vm.getWidgetType()}`,
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "empty-state__text" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/no-data-icon.svg") },
                  }),
                  _c("p", [
                    _vm._v(
                      "There is no data to display for the selected filters/date range."
                    ),
                  ]),
                ]),
              ])
            : _vm.checkWidgetType(_vm.widgetTypes, _vm.widget, "Line Chart") ||
              _vm.checkWidgetType(_vm.widgetTypes, _vm.widget, "Pie Chart") ||
              _vm.checkWidgetType(_vm.widgetTypes, _vm.widget, "Bar Chart")
            ? _c("Chart", {
                attrs: {
                  componentId: _vm.componentId,
                  stacked: _vm.widget.stacked,
                  data: _vm.widget.data,
                  componentType: _vm.getWidgetType(),
                  fields: _vm.widget.fields,
                  "is-group-by": !!_vm.widget.groupByField,
                  settings: _vm.widget.settings,
                },
              })
            : _vm.checkWidgetType(_vm.widgetTypes, _vm.widget, "Table")
            ? _c("Table", {
                attrs: {
                  componentId: _vm.componentId,
                  data: _vm.widget.data,
                  height: _vm.widget.h,
                  showTable: _vm.showTable,
                },
                on: { "update-table": _vm.updatedData },
              })
            : _vm.checkWidgetType(_vm.widgetTypes, _vm.widget, "Map Chart")
            ? _c("Map", {
                attrs: {
                  componentId: _vm.componentId,
                  options: _vm.options,
                  data: _vm.widget.data,
                  settings: _vm.widget.settings,
                },
              })
            : _vm.checkWidgetType(_vm.widgetTypes, _vm.widget, "Card")
            ? _c("card", {
                attrs: {
                  componentId: _vm.componentId,
                  options: _vm.options,
                  data: {
                    value: _vm.widget.data.value,
                    name: _vm.widget.title,
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }