export const checkWidgetType = (allWidgets, widget, typeToCheck) => {
  if (typeToCheck === null) {
    const widgetName = allWidgets?.find(
      (item) => item.id === widget?.widgetType
    )?.name;
    return widgetName;
  }

  const isWidgetRequestedType =
    allWidgets?.find((item) => item.name == typeToCheck).id ===
    widget?.widgetType;
  return isWidgetRequestedType;
};
