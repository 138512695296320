var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "filter-tags__container", class: _vm.filterType },
        [
          (_vm.isDisabled || _vm.isFilterTypeGlobal) && !_vm.isOnShare
            ? _c("GjIcon", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "info-icon",
                attrs: {
                  name: "IconparkInfo",
                  size: "18",
                  color: "#A3B0C2",
                  title: _vm.isFilterTypeGlobal
                    ? "Global Filters"
                    : "Predefined Filters",
                },
              })
            : _vm._e(),
          _vm.overflowing && !_vm.isOnShare
            ? _c(
                "div",
                [
                  _vm._l(_vm.filtersArr, function (filter, filterIndex) {
                    return _c(
                      "div",
                      { key: filterIndex, staticClass: "d-inline" },
                      [
                        filterIndex < _vm.index || _vm.index === -1
                          ? _c(
                              "span",
                              {
                                staticClass: "filter-tag",
                                class: _vm.isFilterTypeGlobal
                                  ? null
                                  : "predefined-filter",
                              },
                              [
                                _c("TagText", {
                                  attrs: {
                                    filter: filter,
                                    showTooltip: true,
                                    tableId: _vm.tableId,
                                  },
                                  on: {
                                    "open-filter-modal": function ($event) {
                                      return _vm.onFiltersModalOpen(filter.id)
                                    },
                                  },
                                }),
                                !_vm.areDisabled
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn remove-tag-btn",
                                        attrs: {
                                          type: "button",
                                          disabled: _vm.coolDownBtn,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleFilter(filter.id)
                                          },
                                        },
                                      },
                                      [
                                        _vm.isFilterTypeGlobal ||
                                        !_vm.isDisabled
                                          ? _c("GjIcon", {
                                              attrs: {
                                                name: "Close_fill",
                                                size: "18",
                                                color: "#7483a1",
                                              },
                                            })
                                          : _c("GjIcon", {
                                              attrs: {
                                                color: !filter.hidden
                                                  ? "#052D61"
                                                  : "#667C99",
                                                size: "18",
                                                name: !filter.hidden
                                                  ? "EyeVisibilityPassword"
                                                  : "IconparkPreviewClose",
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "filter-tag",
                      class: _vm.isFilterTypeGlobal
                        ? null
                        : "predefined-filter",
                    },
                    [
                      _vm._v(
                        " + " + _vm._s(_vm.filtersArr.length - _vm.index) + " "
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn view-all__btn",
                      on: { click: _vm.onFiltersModalOpen },
                    },
                    [_vm._v(" View All ")]
                  ),
                  _vm.isFilterTypeGlobal || !_vm.isDisabled
                    ? _c(
                        "button",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.clearGlobalFilters()
                            },
                          },
                        },
                        [_vm._v(" Clear All ")]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _c(
                "div",
                { class: _vm.isOnShare ? "wrapped-tags" : "" },
                [
                  _vm._l(_vm.filtersArr, function (filter, filterIndex) {
                    return _c(
                      "div",
                      {
                        key: filterIndex,
                        class: _vm.isOnShare ? "d-flex" : "d-inline",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "filter-tag",
                            class: _vm.isOnShare
                              ? null
                              : _vm.isFilterTypeGlobal
                              ? null
                              : "predefined-filter",
                          },
                          [
                            _c("TagText", {
                              attrs: {
                                showTooltip: true,
                                filter: filter,
                                tableId: _vm.tableId,
                              },
                              on: {
                                "open-filter-modal": function ($event) {
                                  _vm.canEditTags
                                    ? _vm.onFiltersModalOpen(filter.id)
                                    : {}
                                },
                              },
                            }),
                            !_vm.areDisabled
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn remove-tag-btn",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.coolDownBtn,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleFilter(filter.id)
                                      },
                                    },
                                  },
                                  [
                                    _vm.isFilterTypeGlobal || !_vm.isDisabled
                                      ? _c("GjIcon", {
                                          attrs: {
                                            name: "Close_fill",
                                            size: "18",
                                            color: "#7483a1",
                                          },
                                        })
                                      : _c("GjIcon", {
                                          attrs: {
                                            color: !filter.hidden
                                              ? "#052D61"
                                              : "#667C99",
                                            size: "18",
                                            name: !filter.hidden
                                              ? "EyeVisibilityPassword"
                                              : "IconparkPreviewClose",
                                          },
                                        }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm.isOnShare && _vm.canEditTags
                    ? _c(
                        "button",
                        {
                          staticClass: "btn view-all__btn",
                          on: { click: _vm.addFilter },
                        },
                        [
                          _c("GjIcon", { attrs: { name: "Plus", size: "14" } }),
                          _vm._v(" Add filter "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }