import actions from "./visualizerModuleActions";
import getters from "./visualizerModuleGetters";
import mutations from "./visualizerModuleMutations";
import state from "./visualizerModuleState";

export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters,
};
