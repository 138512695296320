<template>
  <div v-if="widget.loading" class="loading-bar__wrapper">
    <LoadingBar classList="medium" />
  </div>
  <div v-else-if="widget.hasError" class="main-component">
    <div class="error-state__container">
      <img :src="require(`../assets/img/error-icon.svg`)" />
      <h5>Oops! Widget failed to load</h5>
      <p>Something went wrong and we were unable to load your data.</p>
    </div>
  </div>
  <div
    v-else-if="widget.data"
    class="main-component"
    :class="
      checkWidgetType(widgetTypes, widget, 'Table')
        ? 'main-component-table'
        : ''
    "
  >
    <div
      v-if="
        (widget.data.series && widget.data?.series?.length <= 0) ||
        (widget.data.data && Object.keys(widget.data.data).length <= 0)
      "
      class="empty-state__container"
    >
      <img
        v-if="getWidgetType() !== ''"
        :src="require(`../assets/img/empty-${getWidgetType()}.png`)"
        :alt="`Empty ${getWidgetType()}`"
        class="empty-state__img"
      />
      <div class="empty-state__text">
        <img :src="require('@/assets/img/no-data-icon.svg')" />
        <p>There is no data to display for the selected filters/date range.</p>
      </div>
    </div>
    <Chart
      :componentId="componentId"
      :stacked="widget.stacked"
      :data="widget.data"
      :componentType="getWidgetType()"
      :fields="widget.fields"
      :is-group-by="!!widget.groupByField"
      :settings="widget.settings"
      v-else-if="
        checkWidgetType(widgetTypes, widget, 'Line Chart') ||
        checkWidgetType(widgetTypes, widget, 'Pie Chart') ||
        checkWidgetType(widgetTypes, widget, 'Bar Chart')
      "
    />
    <Table
      :componentId="componentId"
      :data="widget.data"
      :height="widget.h"
      :showTable="showTable"
      v-else-if="checkWidgetType(widgetTypes, widget, 'Table')"
      @update-table="updatedData"
    />
    <Map
      :componentId="componentId"
      :options="options"
      :data="widget.data"
      :settings="widget.settings"
      v-else-if="checkWidgetType(widgetTypes, widget, 'Map Chart')"
    />
    <card
      :componentId="componentId"
      :options="options"
      :data="{ value: widget.data.value, name: widget.title }"
      v-else-if="checkWidgetType(widgetTypes, widget, 'Card')"
    />
  </div>
</template>

<script>
/*eslint-disable vue/no-mutating-props*/
import { LoadingBar, showToast } from "@nodus/utilities-front";
import store from "../store";
import { checkWidgetType } from "../utils/checkWidgetType";
import Card from "./Card.vue";
import Chart from "./Chart.vue";
import Map from "./Map.vue";
import Table from "./Table.vue";

export default {
  components: {
    LoadingBar,
    Table,
    Map,
    Card,
    Chart,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    widgetType: {
      type: Number,
      required: false,
      default: () => 0,
    },
    componentId: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    widgetTypes: {
      get() {
        return store.getters["getWidgetTypes"];
      },
      set(value) {
        store.commit("SET_WIDGET_TYPES", value);
      },
    },
    properties: {
      get() {
        return store.getters["getPropertiesList"];
      },
      set(value) {
        store.commit("SET_PROPERTIES", value);
      },
    },
    propertiesSelected: {
      get() {
        return store.getters["getPropertiesSelectedList"];
      },
      set(value) {
        store.commit("SET_PROPERTIES_SELECTED", value);
      },
    },
    datesSelected: {
      get() {
        return store.getters["getSelectedDates"];
      },
      set(value) {
        store.commit("SET_SELECTED_DATES", value);
      },
    },
    dashboardAutoRefresh: {
      get() {
        return store.getters["getDashboardAutoRefresh"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_AUTO_REFRESH", value);
      },
    },
    embedFilters: {
      get() {
        return store.getters["getEmbedFilters"];
      },
      set(value) {
        store.commit("SET_EMBED_FILTERS", value);
      },
    },
    dashboardConfigs: {
      get() {
        return store.getters["getDashboardConfigs"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_CONFIGS", value);
      },
    },
    embedConfig: {
      get() {
        return store.getters["getEmbedConfig"];
      },
      set(value) {
        store.commit("SET_EMBED_CONFIG", value);
      },
    },
    refreshDashboardData: {
      get() {
        return store.getters["getRefreshDashboardData"];
      },
      set(value) {
        store.commit("SET_REFRESH_DASHBOARD_DATA", value);
      },
    },
  },
  data() {
    return {
      organizationId: this.$route?.params?.organizationId,
      isEmptyState: false,
      colors: [],
      showTable: true,
      mapCountriesCount: 300,
      refreshInterval: null,
      checkWidgetType,
    };
  },
  watch: {
    dashboardAutoRefresh: {
      handler() {
        clearInterval(this.refreshInterval);
        if (
          this.mode !== "edit" &&
          this.mode !== "create" &&
          this?.dashboardAutoRefresh &&
          this?.dashboardAutoRefresh?.minutes !== 0
        ) {
          this.refreshInterval = setInterval(async () => {
            if (this?.dashboardAutoRefresh) {
              await this?.getData();
            }
          }, this?.dashboardAutoRefresh?.minutes * 60000);
        }
      },
      immediate: false,
    },
    refreshDashboardData: {
      handler() {
        this.getData();
      },
    },
  },
  async created() {
    if (!this.widget.visualizing && this.mode !== "fullscreen") this.getData();
    if (this.mode === "fullscreen") this.widget.loading = false;
    if (
      this.mode !== "edit" &&
      this.mode !== "create" &&
      this?.dashboardAutoRefresh &&
      this?.dashboardAutoRefresh?.minutes !== 0
    ) {
      this.refreshInterval = setInterval(async () => {
        if (this?.dashboardAutoRefresh) {
          await this?.getData();
        }
      }, this?.dashboardAutoRefresh?.minutes * 60000);
    }
  },
  destroyed() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  },
  methods: {
    async getData(updatedTable) {
      if (!updatedTable) {
        this.widget.loading = true;
      }
      this.showTable = false;

      let propertyIds = [];
      if (this.propertiesSelected.length > 0) {
        propertyIds = this.propertiesSelected.map((item) => item.id);
      } else if (this.dashboardConfigs?.propertyIds.length > 0) {
        propertyIds = this.dashboardConfigs.propertyIds;
      }

      const { startDate, endDate } = this.datesSelected;
      const isTable =
        this.widgetTypes.find((item) => item.name == "Table").id ===
        this.widget.widgetType;
      const { page, sortingOrder, orderBy, count } = this.widget.data;

      try {
        let res = await store.dispatch("executeQuery", {
          organizationId: this.organizationId,
          data: {
            upsertWidget: {
              ...this.widget,
              data: {},
              groupByField: this.widget?.groupByField?.id || null,
            },
            properties: propertyIds,
            ...(isTable && { page: page ? page : 1 }),
            ...(isTable && { count: count ? count : 10 }),
            ...(isTable && { sortingOrder: sortingOrder }),
            ...(isTable && { orderBy: orderBy }),
            startDate,
            endDate,
            rawEmbedFilters: this.embedFilters,
            rawEmbedFiltersLogicalOperator: this.embedConfig?.logicalOperator,
          },
        });
        if (res?.code === "ERR_CANCELED") return;

        this.widget.hasError = res.error;
        this.widget.data = {
          ...res?.data?.queryData?.data,
          globalFilters: res?.data?.globalFilters,
          page: page ? page : 1,
          count: count ? count : 10,
          sortingOrder,
          orderBy,
          startDate,
          endDate,
        };
        if (!updatedTable) this.widget.loading = false;
        this.showTable = true;
        this.widget.loading = false;
      } catch (err) {
        showToast("error", err.message);
      }
    },
    updatedData() {
      const updatedTable = true;
      this.getData(updatedTable);
    },
    getWidgetType() {
      return (
        this.widgetTypes
          .find((item) => item.id === this.widgetType)
          .name.toLowerCase()
          .replace(" ", "-") || ""
      );
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/widgets.scss";
</style>
