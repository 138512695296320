var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "child-component" },
    [
      !_vm.loading && _vm.data
        ? _c("vue-apex-charts", {
            class: `apex-chart ${_vm.chartType}`,
            attrs: {
              type: _vm.getChartType(),
              height: "100%",
              width: "100%",
              options: _vm.chartOptions,
              series: _vm.chartSeries,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }