<template>
  <LoadingBar v-if="loading" class="loading-bar__wrapper" />
  <div v-else class="create-share-view dashboards__container create-wrapper">
    <div class="mb-1">
      <ShareHeader
        :title="`Share ${selectedDashboard.name}`"
        desc="These settings allow users to interact with the dashboard within these limits, while the shared dashboard's functionality for administrators remains unrestricted."
      />
      <div class="share-spacer"></div>
      <div class="types__wrapper">
        <div
          v-for="item in SHARE_TYPES"
          :id="`${item?.text}`"
          :key="item?.id"
          class="types__wrapper-content"
          :class="[
            item?.id === selectedType?.id
              ? 'types__wrapper-content--active'
              : '',
            !!id ? 'types__wrapper-content--disabled' : ''
          ]"
          @click="chooseType(item)"
        >
          <input
            v-model="selectedType.id"
            :id="item?.id"
            :value="item?.id"
            :disabled="!!id"
            type="radio"
            name="share"
          />
          <div class="types__wrapper-content-info">
            <GjIcon v-if="item?.icon" :name="item?.icon"></GjIcon>
            <label>{{ item.text }}</label>
          </div>
        </div>
      </div>
      <div v-if="dashboardToShare">
        <p class="config-title">Title*</p>
        <b-form-input
          v-model.trim="dashboardToShare[shareData].name"
          placeholder="Add title"
          class="input-width"
        ></b-form-input>
        <div class="share-spacer"></div>
        <p class="config-title">Properties</p>
        <MultipleProperties
          v-if="properties"
          v-bind="
            dashboardToShare?.propertyIds?.length > 0
              ? { selected: dashboardToShare?.propertyIds }
              : {}
          "
          id="select-properties"
          :options="properties"
          class="input-width"
          :classes="[
            'multiple-properties--one',
            'multiple-properties__min-width'
          ]"
          @update-data="addProperty"
        />
        <b-form-checkbox
          v-if="selectedType.text === 'Link'"
          v-model="dashboardToShare.enablePropertyFilter"
          id="properties-checkbox"
          class="config-checkbox"
        >
          Allow users to select/unselect the shared properties.
        </b-form-checkbox>
        <div class="share-spacer"></div>
        <p class="config-title">Share data between</p>
        <div
          class="header__wrapper-input calendar__range-input share-date__container input-width"
        >
          <DatePickerModal
            :start-end-date="date"
            :date-range="dashboardToShare?.dashboardSharingPeriodType"
            input-position="bottom"
            @update-selected-date="updateSelectedShareDate"
          />
        </div>
        <b-form-checkbox
          v-if="selectedType.text === 'Link'"
          v-model="dashboardToShare.enableDateFilter"
          id="date-checkbox"
          class="config-checkbox"
        >
          Allow users to select different dates within the selected date range.
        </b-form-checkbox>
        <div class="share-spacer"></div>
        <b-form-checkbox
          v-model="includeFilters"
          switch
          size="md"
          id="include-filters-checkbox"
          class="config-checkbox config-checkbox-filters"
        >
          Include Filters
        </b-form-checkbox>
        <FilterTags
          v-if="includeFilters"
          :filters="dashboardToShare?.globalFilters"
          :tableId="tableId"
          :is-on-share="true"
          filter-type="share"
          class="shared-filters__container input-width"
          @open-share-filter="openFilterModal"
        />
        <b-form-checkbox
          v-if="selectedType.text === 'Link'"
          v-model="dashboardToShare.enableGlobalFiltersFilter"
          id="filters-checkbox"
          class="config-checkbox"
        >
          Allow users to add, modify and delete additional filters.
        </b-form-checkbox>
        <div v-if="selectedType.text === 'Embed'">
          <div class="share-spacer"></div>
          <p class="config-title">Allowed Origins*</p>
          <b-form-tags
            v-model="dashboardToShare.dashboardSharingEmbed.allowedOrigins"
            placeholder="Enter Origins"
            remove-on-delete
            add-on-change
            :tag-validator="originValidator"
            invalid-tag-text="Invalid origin"
            class="input-width allowed-origins__input"
          ></b-form-tags>
          <MessagePanel
            background-color="#F6F8F9"
            border-color="#F6F8F9"
            icon-name="Info"
            icon-color="#7483A1"
            text="Allowed Origins are URLs that
          will be allowed to make requests from JavaScript Clients to Bisko API
          (typically used with CORS). By default, none of callback URLs will be
          allowed. This field allows you to enter origins that you want to allow.
          <br/>
          <b>Example: https://example.com, http://example.com, *</b>"
            class="input-width allowed-origins__message"
            text-color="#7483A1"
          />
        </div>
      </div>
    </div>
    <modal-filter
      v-if="dashboardToShare"
      ref="shareFilter"
      key="shareFilter"
      filter-type="share"
      :filters="dashboardToShare?.globalFilters"
      :logicalOperator="dashboardToShare?.globalFilterLogicalOperator"
      @add-new-filter="addNewShareFilter"
    />

    <Footer
      :submitted="submitted"
      :disableButton="
        (selectedType.text === 'Link' && !validName) ||
        (selectedType.text === 'Embed' && emptyNameOrOrigin)
      "
      :editMode="!!id || false"
      :cancel-id="`cancel-create-share`"
      :create-id="`add-share`"
      @back="cancel()"
      @create="id ? update() : create()"
    />
  </div>
</template>

<script>
import DatePickerModal from '@/components/DatePickerModal.vue'
import MessagePanel from '@/components/MessagePanel.vue'
import ModalFilter from '@/components/ModalFilter.vue'
import ShareHeader from '@/components/ShareHeader.vue'
import FilterTags from '@/components/global-filters/FilterTags.vue'
import DashboardShare from '@/models/post/DashboardShare'
import store from '@/store'
import generateEmbed from '@/utils/generateEmbed.js'
import generateGuid from '@/utils/guid.js'
import {
  Footer,
  GjIcon,
  LoadingBar,
  MultipleProperties,
  showToast
} from '@nodus/utilities-front'
import { BFormCheckbox, BFormInput } from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import { SHARE_TYPES, columnTypes } from '../../utils/constants'
import validateValue from '../../utils/regexValidations'

export default {
  components: {
    BFormInput,
    MultipleProperties,
    BFormCheckbox,
    DatePickerModal,
    FilterTags,
    Footer,
    ShareHeader,
    LoadingBar,
    ModalFilter,
    GjIcon,
    MessagePanel
  },
  async beforeRouteEnter(to, __, next) {
    const id = to.params.dashboardId

    if (store.getters.getCanModifyShare === null) {
      await store.dispatch('getDashboardSharingList', {
        organizationId: to.params.organizationId,
        id
      })
    }
    if (store.getters.getCanModifyShare) next()
    else next('/error/401')
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, dashboardId, id } = vm.$route.params
    const properties = computed(() => store.getters.getPropertiesList)
    const parameters = computed(() => store.getters.getFilterParameters)
    const globalFilters = JSON.parse(localStorage.getItem(dashboardId))
    const tableId = 'dashboard'
    const includeFilters = ref(false)
    const dateFrom = ref()
    const dateTo = ref()
    const date = ref()
    const submitted = ref(false)
    const hashShareUrl = generateGuid()
    const shareUrl = `${window.location.origin}/preview/bisko/${organizationId}/visualizer/dashboard/shared/${hashShareUrl}`
    let dashboardToShare = ref()
    const loading = ref(true)
    const dashboard = computed(() => store.getters.getDashboard)
    const selectedType = ref(SHARE_TYPES[0])
    const hashEmbedUrl = generateGuid()
    const emptyNameOrOrigin = computed(
      () =>
        dashboardToShare?.value?.dashboardSharingEmbed?.name?.length <= 0 ||
        dashboardToShare?.value?.dashboardSharingEmbed?.allowedOrigins
          ?.length <= 0
    )
    const shareData = ref('dashboardSharingUrl')
    const validName = computed(
      () => dashboardToShare?.value?.dashboardSharingUrl?.name
    )
    const selectedDashboard = computed(() => store.getters.getSelectedDashboard)
    let areFiltersInvalid = ref(false)
    const getTableIds = async () => {
      await store.dispatch('getDashboardById', {
        organizationId,
        id: dashboardId
      })
      const allTableIds = dashboard.value.widgets.map((item) => item.tableId)
      store.commit('SET_DASHBOARD_TABLE_IDS', [...new Set(allTableIds)])
    }

    const chooseType = (item) => {
      selectedType.value = item
      shareData.value =
        item.text === 'Link' ? 'dashboardSharingUrl' : 'dashboardSharingEmbed'
    }

    const getLastWeek = () => {
      const today = new Date()
      today.setDate(today.getDate() - 7)
      const lastWeek = new Date(today)

      const lastWeekMonth = (lastWeek.getMonth() + 1)
        .toString()
        .padStart(2, '0')
      const lastWeekDay = lastWeek.getDate().toString().padStart(2, '0')
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, '0')
      const currentDay = new Date().getDate().toString().padStart(2, '0')

      dateFrom.value = `${lastWeek.getFullYear()}-${lastWeekMonth}-${lastWeekDay}`
      dateTo.value = `${new Date().getFullYear()}-${currentMonth}-${currentDay}`
      dashboardToShare.value.startDate = dateFrom.value
      dashboardToShare.value.endDate = dateTo.value
      return `[${dateFrom.value} to ${dateTo.value}]`
    }

    const setDashboardToShareValue = async () => {
      if (id) {
        const data = await store.dispatch('getDashboardShareById', {
          organizationId,
          id
        })
        dashboardToShare.value = data
        includeFilters.value = dashboardToShare.value.globalFilters.length > 0
        date.value = `${data.startDate} to ${data.endDate}`
        shareData.value = data.dashboardSharingUrl
          ? 'dashboardSharingUrl'
          : 'dashboardSharingEmbed'
        selectedType.value = data.dashboardSharingUrl
          ? SHARE_TYPES.find((item) => item.text === 'Link')
          : SHARE_TYPES.find((item) => item.text === 'Embed')
      } else {
        dashboardToShare.value = new DashboardShare({
          dashboardId,
          globalFilters: globalFilters ? globalFilters.filters : [],
          globalFilterLogicalOperator: globalFilters
            ? globalFilters.logicalOperator
            : null,
          dashboardSharingUrl: {
            name: '',
            shareUrl,
            isShareEnabled: true,
            hashShareUrl
          },
          dashboardSharingEmbed: {
            name: '',
            allowedOrigins: [],
            hashEmbedUrl,
            embedCode: '',
            isEmbedEnabled: true
          }
        })
        date.value = getLastWeek()?.replace(/\[|\]/g, '')
      }
      dashboardToShare.value.propertyIds =
        dashboardToShare.value.propertyIds.map((element) => {
          return properties.value.find((item) => item.id === element)
        })
    }

    onMounted(async () => {
      getTableIds()
      if (!properties.value) {
        await store.dispatch('getProperties', {
          organizationId
        })
      }
      setDashboardToShareValue()
      loading.value = false
    })

    const updateSelectedShareDate = (updatedDate, dateRange) => {
      const dates = updatedDate.split(' to ')
      date.value = updatedDate
      dashboardToShare.value.startDate = dates[0]
      dashboardToShare.value.endDate = dates[1]
      dashboardToShare.value.dashboardSharingPeriodType = dateRange
    }

    const addProperty = (property) => {
      dashboardToShare.value.propertyIds = property.map((item) => item.id)
    }

    const cancel = () => {
      vm.$router.back()
    }

    const createShare = async () => {
      dashboardToShare.value.dashboardSharingEmbed = null

      if (validName.value) {
        if (!includeFilters.value) {
          dashboardToShare.value.globalFilters = []
        }
      }
    }

    const createEmbed = async () => {
      dashboardToShare.value.dashboardSharingUrl = null

      if (!emptyNameOrOrigin.value) {
        if (!includeFilters.value) {
          dashboardToShare.value.globalFilters = []
        }
        const embedCode = generateEmbed(
          hashEmbedUrl,
          dashboardToShare.value,
          organizationId
        )
        dashboardToShare.value.dashboardSharingEmbed.embedCode = embedCode
      }
    }

    const create = async () => {
      submitted.value = true

      if (
        selectedType.value.text ===
        SHARE_TYPES.find((item) => item.text === 'Link').text
      ) {
        createShare()
      } else {
        createEmbed()
      }
      const res = await store.dispatch('createDashboardShare', {
        organizationId,
        data: dashboardToShare.value
      })
      if (res.status === 200) {
        vm.$router.push({
          name: 'dashboard-share-list',
          params: {
            dashboardId,
            organizationId
          }
        })
      } else {
        submitted.value = false
      }
    }

    const updateEmbed = async () => {
      dashboardToShare.value.dashboardSharingUrl = null

      if (!emptyNameOrOrigin.value) {
        dashboardToShare.value.propertyIds =
          dashboardToShare.value.propertyIds.map((item) =>
            item.id ? item.id : item
          )
        if (!includeFilters.value) {
          dashboardToShare.value.globalFilters = []
        }
        const updatedEmbedCode = generateEmbed(
          dashboardToShare.value.dashboardSharingEmbed.hashEmbedUrl,
          dashboardToShare.value,
          organizationId
        )
        dashboardToShare.value.dashboardSharingEmbed.embedCode =
          updatedEmbedCode
      }
    }

    const updateShare = async () => {
      dashboardToShare.value.dashboardSharingEmbed = null

      if (validName.value) {
        dashboardToShare.value.propertyIds =
          dashboardToShare.value.propertyIds.map((item) =>
            item.id ? item.id : item
          )
        if (!includeFilters.value) {
          dashboardToShare.value.globalFilters = []
        }
      }
    }

    const update = async () => {
      submitted.value = true

      if (
        selectedType.value.text ===
        SHARE_TYPES.find((item) => item.text === 'Link').text
      ) {
        updateShare()
      } else {
        updateEmbed()
      }
      const res = await store.dispatch('updateDashboardShare', {
        organizationId,
        data: dashboardToShare.value
      })
      if (res.status === 200) {
        vm.$router.push({
          name: 'dashboard-share-list',
          params: {
            dashboardId,
            organizationId
          }
        })
      } else {
        submitted.value = false
      }
    }

    const openFilterModal = () => {
      vm.$refs?.shareFilter?.openModal()
      setTimeout(() => {
        if (dashboardToShare.value.globalFilters.length >= 1) {
          const filterHolderEl = document.querySelector(
            '.modal-filter__container'
          )
          if (filterHolderEl) {
            const scrollTo = filterHolderEl.scrollHeight
            filterHolderEl.scrollTo({ top: scrollTo, behavior: 'smooth' })
          }
        }
      }, 0)
    }

    const getOperators = (id) => {
      if (!id) return null
      return parameters.value[tableId]?.parameters.find(
        (x) => x.columnId === id
      )?.columnType
    }

    const isFilterValid = (filter) => {
      const operator = getOperators(filter.columnId)
      const validation = validateValue(filter, operator)
      filter.validValue = validation

      return (
        filter.columnId !== null &&
        filter.operator !== null &&
        filter.filterValues.length > 0 &&
        filter.validValue &&
        (operator === columnTypes.KEY_VALUE_PAIR
          ? !!filter.tempFilterKey
          : true)
      )
    }

    const validateFilters = (filters) => {
      let filtersInvalid = false
      filters.forEach((filter) => {
        if (!isFilterValid(filter)) {
          filter.invalid = true
          filtersInvalid = true
        } else {
          filter.invalid = false
        }
      })

      if (filtersInvalid) {
        areFiltersInvalid = true
      } else {
        areFiltersInvalid = false
      }
    }

    const addNewShareFilter = (e, updatedFilters, updatedOperator) => {
      validateFilters(updatedFilters)
      if (areFiltersInvalid) {
        e?.preventDefault()
        showToast('error', `Some filters are invalid!`)
        return
      }
      updatedFilters.map((filter) => {
        if (filter.id === null) {
          filter.id = generateGuid()
        }
      })
      dashboardToShare.value.globalFilters = updatedFilters
      dashboardToShare.value.globalFilterLogicalOperator = updatedOperator
    }
    const originValidator = (origin) => {
      const regex =
        /^https?:\/\/([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?::[0-9]+)?$/
      return regex.test(origin) || origin === '*'
    }
    return {
      properties,
      dashboardToShare,
      tableId,
      includeFilters,
      date,
      updateSelectedShareDate,
      addProperty,
      submitted,
      cancel,
      loading,
      id,
      openFilterModal,
      addNewShareFilter,
      selectedType,
      chooseType,
      originValidator,
      SHARE_TYPES,
      create,
      update,
      emptyNameOrOrigin,
      shareData,
      validName,
      selectedDashboard,
      validateFilters,
      areFiltersInvalid,
      isFilterValid
    }
  }
}
</script>
<style lang="scss">
@import '~@/assets/style/views/create-share.scss';
</style>
