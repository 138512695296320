<template>
  <div>
    <h3 class="share-title">{{ title }}</h3>
    <p class="share-desc">
      {{ desc }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
  },
};
</script>

<style lang="scss">
.share-title {
  color: $color-main-text;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.share-desc {
  color: $color-sec-text;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  max-width: 720px;
}
</style>
