export const areaConfig = {
  chart: {
    width: "100%",
    height: "100%",
    fontFamily: "Inter",
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    curve: "straight",
  },
  tooltip: {
    intersect: false,
    shared: true,
    x: {
      show: false,
      format: "dd/MM/yy",
    },
  },
  legend: {
    position: "top",
    formatter: (val) => val.slice(0, 50) + "...",
  },
  xaxis: {
    type: "datetime",
    categories: [],
    labels: {
      show: true,
      datetimeFormatter: {
        year: "yyyy",
        month: "MMM 'yy",
        day: "dd MMM",
        hour: "dd MMM",
      },
      showDuplicates: false,
      style: {
        colors: "#A3B0C2",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: true,
      offsetY: 20,
      formatter: (val) => {
        const formattedDate = new Date(val);
        return formattedDate.toDateString().slice(4);
      },
    },
    convertedCatToNumeric: false,
  },
  yaxis: {
    labels: {
      style: {
        colors: "#A3B0C2",
      },
    },
    format: "dd/MM/yy",
  },
  dataLabels: {
    enabled: false,
  },
  colors: [],
  fill: {
    colors: [],
  },
};
