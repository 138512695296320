var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "documentation-info", class: _vm.customClass },
    [
      _c("GjIcon", {
        staticClass: "mr-50",
        attrs: { name: "Info", size: "18" },
      }),
      _c("span", [
        _vm._v(_vm._s(_vm.documentationText) + " "),
        _c("a", { attrs: { href: _vm.documentationLink, target: "_blank" } }, [
          _vm._v("documentation"),
        ]),
        _vm._v("."),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }