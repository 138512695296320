export default function generateEmbed(hash, data, organizationId) {
  return `(() => {
    if (!document.getElementById("bv-js")) {
      const bvjs = document.createElement("script");
      bvjs.type = "text/javascript";
      bvjs.async = true;
      bvjs.setAttribute("id", "bv-js");
      bvjs.src = "${process.env.VUE_APP_EMBED_SCRIPT_URL}";
      document.head.append(bvjs);
      bvjs.onload = () => {
        const embedConfig = {
          targetId: "embedContainer",
          hashEmbedUrl: "${hash}",
          organizationId: "${organizationId}",
          allowFullscreen: true,
          autoRefreshMinutes: 0,
          width: "100%",
          height: "600px",
          config: {
            filters: [], // see documentation on custom filters
            logicalOperator: "or",
            startDate: "",
            dateRange: ${data.dashboardSharingPeriodType},
            endDate: "", 
            properties: [], // see documentation on properties
          },
        };
        window.visualizer.BVConfigs.push(embedConfig);
        window.visualizer.initiateEmbed();
      };
    }
  })();`;
}
