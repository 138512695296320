import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/bisko/:organizationId/visualizer",
    redirect: "/bisko/:organizationId/visualizer/dashboard",
  },
  {
    path: "/bisko/:organizationId/visualizer/dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta: {
      navActiveLink: "dashboard",
      pageTitle: "Visualizer",
      breadcrumb: [
        {
          text: "Visualizer",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bisko/:organizationId/visualizer/dashboard/:templateId?/create",
    name: "dashboard-create",
    component: () => import("@/views/Create.vue"),
    meta: {
      navActiveLink: "dashboard",
      pageTitle: "Create Dashboard",
      breadcrumb: [
        {
          text: "Visualizer",
        },
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "Create Dashboard",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bisko/:organizationId/visualizer/dashboard/edit/:id",
    name: "dashboard-edit",
    component: () => import("@/views/Create.vue"),
    meta: {
      navActiveLink: "dashboard",
      pageTitle: "Edit Dashboard",
      breadcrumb: [
        {
          text: "Visualizer",
        },
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "Edit Dashboard",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bisko/:organizationId/visualizer/dashboard/view/:id",
    name: "dashboard-view",
    component: () => import("@/views/View.vue"),
    meta: {
      pageTitle: "View Dashboard",
      breadcrumb: [
        {
          text: "Visualizer",
        },
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "View Dashboard",
          active: true,
        },
      ],
    },
  },
  {
    path: "/preview/bisko/:organizationId/visualizer/dashboard/:type(embed|shared)/:id",
    name: "shared-dashboard-view",
    component: () => import("@/views/Shared.vue"),
  },
  {
    path: "/preview/bisko/:organizationId/visualizer/:pathMatch(.*)*",
    redirect: "/error/404",
  },
  {
    path: "/bisko/:organizationId/visualizer/dashboard/:dashboardId/shares",
    name: "dashboard-share-list",
    component: () => import("@/views/share/ShareListing.vue"),
    meta: {
      pageTitle: "Shared",
      breadcrumb: [
        {
          text: "Visualizer",
        },
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "Shared",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bisko/:organizationId/visualizer/dashboard/:dashboardId/create-share",
    name: "create-share",
    component: () => import("@/views/share/Create.vue"),
    meta: {
      pageTitle: "Create link",
      breadcrumb: [
        {
          text: "Visualizer",
        },
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "Shared",
        },
        {
          text: "Create share",
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[2].to = `/dashboard/${to.params.dashboardId}/shares`;
      next();
    },
  },
  {
    path: "/bisko/:organizationId/visualizer/dashboard/:dashboardId/view/:id",
    name: "share-details",
    component: () => import("@/views/share/View.vue"),
    meta: {
      pageTitle: "View Share",
      breadcrumb: [
        {
          text: "Visualizer",
        },
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "Shared",
        },
        {
          text: "View Share",
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[2].to = `/dashboard/${to.params.dashboardId}/shares`;
      next();
    },
  },
  {
    path: "/bisko/:organizationId/visualizer/dashboard/:dashboardId/edit-share/:id",
    name: "edit-share",
    component: () => import("@/views/share/Create.vue"),
    meta: {
      pageTitle: "Edit share",
      breadcrumb: [
        {
          text: "Visualizer",
        },
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "Shared",
        },
        {
          text: "Edit share",
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[2].to = `/dashboard/${to.params.dashboardId}/shares`;
      next();
    },
  },
  {
    path: "/bisko/:organizationId/visualizer/:pathMatch(.*)*",
    redirect: "/error/404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
