<template>
  <div class="fullscreen__header">
    <div class="fullscreen__header-title">
      {{ headerTitle }}
    </div>
    <button
      v-if="!noFullscreen"
      @click="handleFullscreen"
      type="button"
      class="btn btn-with-icon btn-back"
    >
      <template
        v-if="type === 'fullscreen' || (type === 'shared' && isFullScreen)"
      >
        <GjIcon name="Close" class="icon" size="20" /> Exit Full Screen
      </template>
      <template v-else-if="type === 'shared' && !isFullScreen">
        <GjIcon name="Fullscreen" class="icon" size="16" />
        Enter Full Screen
      </template>
    </button>
  </div>
</template>

<script>
import store from "@/store";
import { GjIcon } from "@nodus/utilities-front";
import { enterFullScreen, exitFullScreen } from "../utils/handleFullScreen";

export default {
  props: {
    headerTitle: {
      type: String,
      required: false,
      default: () => "Fullscreen",
    },
    type: {
      type: String,
      required: false,
      default: () => "fullscreen",
    },
    noFullscreen: Boolean,
  },
  components: {
    GjIcon,
  },
  computed: {
    isDashobardFullScreen: {
      get() {
        return store.getters["getIsDashobardFullScreen"];
      },
      set(value) {
        store.commit("SET_IS_DASHBOARD_FULL_SCREEN", value);
      },
    },
    fullScreenWidget: {
      get() {
        return store.getters["getFullScreenWidget"];
      },
      set(value) {
        store.commit("SET_FULL_SCREEN_WIDGET", value);
      },
    },
  },
  watch: {
    fullScreenWidget: {
      handler(newVal, oldVal) {
        if (!oldVal) {
          this.handleFullscreen();
        }
      },
    },
  },
  data() {
    return {
      isFullScreen: false,
    };
  },
  mounted() {
    document.addEventListener("fullscreenchange", this.fullScreenListener);
    if (this.type === "fullscreen" || this.fullScreenWidget) {
      enterFullScreen(this.type);
    } else if (this.type === "shared") {
      let admin = document.getElementById("nodus-admin");
      let navigation = document.getElementById("nodus-navigation");
      let container = document.querySelector(".dashboard-overview");
      let content = document.querySelector(".content__container");

      admin.style.display = "none";
      navigation.style.display = "none";
      container.style.padding = "0";
      content.style.padding = "0 3rem";
    }
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.fullScreenListener);
  },
  methods: {
    enterDashboardFullScreen() {
      this.isFullScreen = true;
      this.isDashobardFullScreen = true;
      enterFullScreen(this.type);
    },
    closeDashboardFullScreen() {
      this.isDashobardFullScreen = false;
      exitFullScreen(this.type);
      this.$nextTick(() => {
        this.fullScreenWidget = null;
      });
      this.isFullScreen = false;
    },

    handleFullscreen() {
      if (this.type === "fullscreen") {
        this.closeDashboardFullScreen();
      } else if (this.type === "shared") {
        if (this.isFullScreen) {
          this.closeDashboardFullScreen();
        } else {
          this.enterDashboardFullScreen();
        }
      }
    },
    fullScreenListener() {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      )
        this.closeDashboardFullScreen();
    },
  },
};
</script>

<style lang="scss">
.fullscreen__header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  padding: 6px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -3rem;
  margin-right: -3rem;
  border-bottom: 1px solid #e0e5eb;
  background-color: white;

  .fullscreen__header-title,
  .fullscreen__header-exit {
    color: #031c3c;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
