<template>
  <div class="child-component">
    <div class="card-component">
      <div class="card-counter">
        <svg
          height="100%"
          width="100%"
          viewBox="0 0 320 80"
          preserveAspectRatio="xMinYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="max-width: 100%"
        >
          <text
            x="50%"
            y="50%"
            font-size="55"
            fill="#031c3c"
            text-anchor="middle"
            dominant-baseline="middle"
            style="text-anchor: middle"
          >
            {{ data.value }}
          </text>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.card-component {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  align-content: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.card-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: $color-sec-text;
  margin-bottom: 14px;
}

.card-counter {
  font-size: 32px;
  line-height: 42px;
  color: $color-blue-700;
  font-weight: 600;
  height: 100%;
  width: 100%;
}
</style>
