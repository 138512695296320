<template>
  <div v-if="!isLoading" class="shared-dashboards__container">
    <Header
      header-type="view-on-share"
      class="mb-4"
      @change-dashboard-list="changeDashboardId"
    />
    <section class="table__wrapper">
      <SearchInputField id="search-share" :data="dashboardShareData" />
      <div class="table__container">
        <CustomTable
          :data="dashboardShareData"
          type-of-table="dashboard-share"
          :is-pagination-enabled="true"
          :is-loading="loadingTable"
          :empty-state-action="{
            canAdd: canModifyShare,
            action: () => {
              $router.push({
                name: 'create-share',
                params: {
                  dashboardId,
                  organizationId,
                },
              });
            },
          }"
          @update-table="updateTable"
        >
          <template #row-data="{ data }">
            <ShareRow :data="data" type="share" />
          </template>
        </CustomTable>
      </div>
    </section>
    <b-modal
      id="embed-code"
      ref="embed-code"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      centered
      modal-class="embed-code__modal"
    >
      <template #modal-header="{ close }">
        <div>
          <h2>Embed Code</h2>
          <span>{{ shareDetails.name }}</span>
        </div>
        <button @click="close" class="btn">
          <GjIcon name="Close" color="#052D61" />
        </button>
      </template>
      <p class="config-title">Embed Code</p>
      <b-form-textarea
        disabled
        v-model="shareDetails.embedCode"
        rows="10"
        max-rows="10"
        class="embed-code__container"
      />
      <button
        @click="copyEmbed(shareDetails.embedCode)"
        class="btn embed-copy__btn"
      >
        <GjIcon name="Copy" color="#A3B0C2" size="20" />
      </button>
    </b-modal>

    <DeleteModal
      id="link-delete-modal"
      title="Delete Link"
      type="link"
      @delete="removeItem"
    />
  </div>
  <div v-else class="loading__container">
    <LoadingBar />
  </div>
</template>

<script>
import ShareRow from "@/components/ShareRow.vue";
import store from "@/store";
import {
  CustomTable,
  DeleteModal,
  GjIcon,
  LoadingBar,
  SearchInputField,
  handleLastRowDelete,
  showToast,
} from "@nodus/utilities-front";
import { useClipboard } from "@vueuse/core";
import { BFormTextarea } from "bootstrap-vue";
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import Header from "../../components/Header.vue";

export default {
  components: {
    CustomTable,
    ShareRow,
    DeleteModal,
    LoadingBar,
    GjIcon,
    BFormTextarea,
    Header,
    SearchInputField,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { organizationId, dashboardId } = vm.$route.params;
    const updatedId = ref(dashboardId);
    const loadingTable = ref(true);
    const isLoading = ref(true);
    const shareDetails = computed(() => store.getters.getShareDetails);
    const rowToDelete = computed(() => store.getters.getShareToDelete);
    const { copy } = useClipboard();
    const canModifyShare = computed(() => store.getters.getCanModifyShare);
    const dashboardName = computed(() => store.getters.getSharedDashboardName);
    const dashboardShareData = computed(
      () => store.getters.getDashboardShareData
    );

    const updateTable = async () => {
      loadingTable.value = true;
      await store.dispatch("getDashboardSharingList", {
        organizationId,
        id: updatedId.value,
      });
      loadingTable.value = false;
    };

    const changeDashboardId = (id) => {
      updatedId.value = id;
      vm.$router.push({
        name: "dashboard-share-list",
        params: {
          organizationId,
          dashboardId: id,
        },
      });
      updateTable();
    };

    onMounted(async () => {
      loadingTable.value = true;
      await store.dispatch("getDashboardSharingList", {
        organizationId,
        id: dashboardId,
      });
      loadingTable.value = false;
      isLoading.value = false;
    });

    const removeItem = async () => {
      await store.dispatch("deleteDashboardSharing", {
        organizationId,
        obj: rowToDelete.value,
      });
      handleLastRowDelete(
        dashboardShareData.value,
        store,
        "SET_SHARE_TABLE_PAGE"
      );
      updateTable();
    };

    const copyEmbed = (code) => {
      copy(code);
      showToast("success", "Embed code copied!");
    };

    return {
      organizationId,
      dashboardId,
      loadingTable,
      dashboardShareData,
      updateTable,
      shareDetails,
      removeItem,
      copyEmbed,
      canModifyShare,
      dashboardName,
      isLoading,
      changeDashboardId,
    };
  },
};
</script>

<style lang="scss">
.table-header-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
}

.embed-code__modal {
  .config-title {
    color: $color-sec-text;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .btn {
    padding: 0;
    height: unset;
  }

  .modal-header {
    display: flex;
    align-items: flex-start;

    h2 {
      color: $color-main-text;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    span {
      color: $color-main-text;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .embed-code__container {
    color: $color-blue-700;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border: 1px solid $color-gray-100;
    background: $color-bg;
  }

  .embed-copy__btn {
    position: absolute;
    right: 4%;
    top: 21%;
    &:focus {
      box-shadow: none;
    }
  }

  .modal-footer {
    .btn-primary {
      display: none;
    }
  }
}

.loading__container {
  height: 80vh;
  display: flex;
  justify-content: center;
}
</style>
