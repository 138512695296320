<template>
  <b-modal
    ref="modal-lg-create-from-template"
    id="modal-lg-create-from-template"
    modal-class="template-modal"
    size="lg"
    title="Choose a dashboard template"
    ok-title="Create"
    cancel-variant="outline-secondary"
    cancel-title="Cancel"
    centered
    no-close-on-backdrop
    no-close-on-esc
    :ok-disabled="!templateType"
    @ok="createDashboard"
    @cancel="clearSelectedTemplate"
    @close="clearSelectedTemplate"
  >
    <div>
      <div v-if="!templateAvailable" class="no-templates__txt">
        Currently, there are no dashboard templates available.
      </div>
      <div v-else class="templates__container">
        <button
          v-for="template in templates"
          :key="template.id"
          class="template"
          :class="templateType === template?.id ? 'template-selected' : ''"
          @click="setTemplate(template.id)"
        >
          <img
            :src="template.icon || require('@/assets/img/template-img.png')"
            alt="template image"
          />
          <span>{{ template.name }}</span>
          <p>{{ template.description }}</p>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import router from "@/router";
import store from "@/store";
import { BModal } from "bootstrap-vue";
import { computed, getCurrentInstance, ref } from "vue";

export default {
  components: {
    BModal,
  },
  setup() {
    const templateType = ref();
    const vm = getCurrentInstance().proxy;
    const { organizationId } = vm.$route.params;
    const templates = computed(() => store.getters.getDashboardTemplates);
    const templateAvailable = computed(() => templates.value.length > 0);

    const setTemplate = (id) => {
      templateType.value = id;
    };

    const clearSelectedTemplate = () => {
      templateType.value = null;
    };

    const createDashboard = async () => {
      store.commit("SET_SELECTED_DASHBOARD", {
        id: null,
        name: null,
      });
      await store.dispatch("cancelQueryRequests");
      router.push({
        name: "dashboard-create",
        params: {
          organizationId,
          templateId: templateType.value,
        },
      });
    };

    return {
      templates,
      setTemplate,
      createDashboard,
      templateType,
      clearSelectedTemplate,
      templateAvailable,
    };
  },
};
</script>

<style lang="scss">
.template-modal {
  .modal-header {
    &-title {
      color: #052d61;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    .btn {
      height: unset;
      padding: 0;
    }
  }

  .modal-body {
    max-height: 380px;
    overflow-y: auto;
    padding: 32px 24px;
  }

  .templates__container {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fill, 225px);
  }

  .no-templates__txt {
    color: #052d61;
    font-weight: 500;
    text-align: center;
  }

  .template {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background: #fff;
    flex: 1;
    gap: 8px;
    border-radius: 8px;
    border: 0;
    border: 1px solid #e0e5eb;

    &-selected {
      border: 1px solid #1d79f2;
      background: #f6f7f9;
      transition: all 0.1s;
    }

    img {
      margin-bottom: 8px;
      width: 100%;
    }

    span {
      color: #031c3c;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #667c99;
    }
  }
}
</style>
