import Filter from "./Filter";
class DashboardShare {
  constructor(data) {
    this.dashboardId = data.dashboardId;
    this.startDate = data.startDate || null;
    this.endDate = data.endDate || null;
    this.propertyIds = data.propertyIds || [];
    this.enablePropertyFilter = data.enablePropertyFilter || false;
    this.enableDateFilter = data.enableDateFilter || false;
    this.enableGlobalFiltersFilter = data.enableGlobalFiltersFilter || false;
    this.dashboardSharingPeriodType = data.dashboardSharingPeriodType || 8;
    this.globalFilterLogicalOperator = data.globalFilterLogicalOperator || 0;
    this.globalFilters = data.globalFilters
      ? data.globalFilters.map((filter) => new Filter(filter))
      : [];
    this.dashboardSharingEmbed = data.dashboardSharingEmbed || null;
    this.dashboardSharingUrl = data.dashboardSharingUrl || null;
  }
}

export default DashboardShare;
