<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="dashboard-overview">
    <FullScreenHeader
      v-if="isDashobardFullScreen || fullScreenWidget"
      :header-title="
        widgetTypes?.find((item) => item.id === fullScreenWidget?.widgetType)
          ?.name || selectedDashboard?.name
      "
      type="fullscreen"
    />
    <div v-if="!isDashobardFullScreen && !fullScreenWidget">
      <Header
        v-if="selectedDashboard.id !== null"
        header-type="view"
        @change-dashboard="changeDashboard"
        @export-dashboard="exportDashboard"
      />
    </div>
    <div
      v-if="!loadingDashboard && !fullScreenWidget"
      class="d-flex justify-content-between mt-5 mb-3 flex-wrap align-items-start"
      style="gap: 12px"
    >
      <div class="properties-filters__container">
        <MultipleProperties
          v-if="properties"
          id="select-properties"
          :options="properties"
          v-bind="
            propertiesSelected?.length > 0
              ? { selected: propertiesSelected }
              : {}
          "
          :classes="[
            'multiple-properties--one',
            'multiple-properties__min-width',
          ]"
          @update-data="propertyOrDateSelected"
        />
        <b-button @click="addNewGlobalFilter()" class="justify-content-between">
          <GjIcon name="Plus" size="18" />
          <span class="ml-2">Add Filter</span>
        </b-button>
      </div>
      <div class="dashboard-configs-right">
        <div class="date-position__right">
          <span class="calendar__range-label">Show data per:</span>
          <div class="header__wrapper-input calendar__range-input">
            <date-picker-modal
              :start-end-date="date"
              :date-range="dateRange"
              :isOnShare="false"
              @update-selected-date="updateSelectedDate"
            />
          </div>
        </div>
        <b-dropdown class="auto-refresh__dropdown" right>
          <template #button-content>
            <div>
              <GjIcon name="RefreshAlt" size="18" />
              {{ dashboardAutoRefresh.text }}
            </div>
            <GjIcon name="ArrowDown" />
          </template>
          <b-dropdown-item
            v-for="item in autoRefreshOptions"
            :key="item.id"
            @click="changeAutoRefresh(item, false)"
          >
            <GjIcon name="RefreshAlt" size="18" />
            {{ item.text }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <FilterTags
      v-if="
        predefinedFilters?.filters?.length > 0 &&
        !loadingDashboard &&
        !fullScreenWidget
      "
      :filters="predefinedFilters"
      :tableId="tableId"
      :isDisabled="true"
      @update-filters="savePredefinedFilters"
      :filterType="'predefined'"
    />
    <FilterTags
      v-if="
        globalFilters.filters.length > 0 &&
        !loadingDashboard &&
        !fullScreenWidget
      "
      :filters="globalFilters"
      :tableId="tableId"
      @update-filters="saveFilters"
    />
    <DeleteModal
      id="deleteDashboardModal"
      title="Delete Dashboard"
      type="dashboard"
      :itemName="selectedDashboard?.name"
      @delete="deleteDashboard(selectedDashboard)"
    />
    <modal-filter
      ref="globalFilter"
      key="globalFilter"
      :filters="globalFilters.filters"
      :logicalOperator="globalFilters.logicalOperator"
      @save-filters="saveFilters"
    />
    <modal-filter
      ref="predefinedFilter"
      key="predefinedFilter"
      :filters="predefinedFilters.filters"
      :logicalOperator="dashboard.predefinedGlobalFilterLogicalOperator"
      :filter-type="'predefined'"
      :global-disabled="true"
      @save-predefined="savePredefinedFilters"
    />
    <div v-if="loadingDashboard" class="loading-bar__wrapper">
      <LoadingBar />
    </div>
    <GridLayout v-else mode="view" />
  </div>
</template>

<script>
import store from "@/store";
import generateGuid from "@/utils/guid.js";
import {
  DeleteModal,
  GjIcon,
  LoadingBar,
  MultipleProperties,
  showToast,
} from "@nodus/utilities-front";
import { BButton } from "bootstrap-vue";
import _ from "lodash";
import DatePickerModal from "../components/DatePickerModal.vue";
import FullScreenHeader from "../components/FullScreenHeader.vue";
import GridLayout from "../components/GridLayout.vue";
import Header from "../components/Header.vue";
import ModalFilter from "../components/ModalFilter.vue";
import FilterTags from "../components/global-filters/FilterTags.vue";
import { DATE_RANGE_TYPES, columnTypes } from "../utils/constants";
import validateValue from "../utils/regexValidations";

export default {
  components: {
    GridLayout,
    BButton,
    FilterTags,
    ModalFilter,
    MultipleProperties,
    LoadingBar,
    DeleteModal,
    FullScreenHeader,
    DatePickerModal,
    GjIcon,
    Header,
  },
  data() {
    return {
      filters: [],
      areFiltersInvalid: false,
      organizationId: this.$route?.params?.organizationId,
      dashboardId: this.$route?.params?.id,
      loadingDashboard: true,
      date: "",
      dateFrom: "",
      dateTo: "",
      dateRange: DATE_RANGE_TYPES.CUSTOM,
      layoutDelay: 300,
      isViewDestroyed: false,
      widgetFillTimeout: null,
      tableId: "dashboard",
      autoRefreshOptions: [
        {
          minutes: 0,
          text: "Off",
        },
        {
          minutes: 1,
          text: "Every 1 min",
        },
        {
          minutes: 3,
          text: "Every 3 min",
        },
        {
          minutes: 5,
          text: "Every 5 min",
        },
      ],
      predefinedFiltersChanged: false,
    };
  },
  computed: {
    layout: {
      get() {
        return store.getters["getLayoutArray"];
      },
      set(value) {
        store.commit("SET_LAYOUT_ARRAY", value);
      },
    },
    selectedDashboard: {
      get() {
        return store.getters["getSelectedDashboard"];
      },
      set(value) {
        store.commit("SET_SELECTED_DASHBOARD", value);
      },
    },
    properties: {
      get() {
        return store.getters["getPropertiesList"];
      },
      set(value) {
        store.commit("SET_PROPERTIES", value);
      },
    },
    propertiesSelected: {
      get() {
        return store.getters["getPropertiesSelectedList"];
      },
      set(value) {
        store.commit("SET_PROPERTIES_SELECTED", value);
      },
    },
    selectedDashboardName: {
      get() {
        if (this.selectedDashboard?.id) {
          return this.selectedDashboard?.name;
        }
        return "Select a dashboard";
      },
    },
    dashboard: {
      get() {
        return store.getters["getDashboard"];
      },
      set(value) {
        store.commit("SET_DASHBOARD", value);
      },
    },
    datesSelected: {
      get() {
        return store.getters["getSelectedDates"];
      },
      set(value) {
        store.commit("SET_SELECTED_DATES", value);
      },
    },
    globalFilters: {
      get() {
        return store.getters["getGlobalFilters"];
      },
      set(value) {
        store.commit("SET_GLOBAL_FILTERS", value);
      },
    },
    parameters: {
      get() {
        return store.getters["getFilterParameters"];
      },
      set(value) {
        store.commit("SET_FILTER_PARAMETERS", value);
      },
    },
    dashboardTableIds: {
      get() {
        return store.getters["getDashboardTableIds"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_TABLE_IDS", value);
      },
    },
    filteredParameters: {
      get() {
        return store.getters["getFilteredDashboardParameters"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_FILTERED_PARAMS", value);
      },
    },
    isDashobardFullScreen: {
      get() {
        return store.getters["getIsDashobardFullScreen"];
      },
      set(value) {
        store.commit("SET_IS_DASHBOARD_FULL_SCREEN", value);
      },
    },
    fullScreenWidget: {
      get() {
        return store.getters["getFullScreenWidget"];
      },
      set(value) {
        store.commit("SET_FULL_SCREEN_WIDGET", value);
      },
    },
    widgetTypes: {
      get() {
        return store.getters["getWidgetTypes"];
      },
      set(value) {
        store.commit("SET_WIDGET_TYPES", value);
      },
    },
    customRangeDate: {
      get() {
        return store.getters["getCustomRangeDate"];
      },
    },
    predefinedFilters: {
      get() {
        return {
          filters: this.dashboard.predefinedGlobalFilters,
        };
      },
      set(value) {
        this.dashboard.predefinedGlobalFilters = value;
      },
    },
    dashboardAutoRefresh: {
      get() {
        return store.getters["getDashboardAutoRefresh"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_AUTO_REFRESH", value);
      },
    },
    enabledActions: {
      get() {
        return store.getters["getEnabledActions"];
      },
      set(value) {
        store.commit("SET_ACTIONS_ENABLED", value);
      },
    },
    refreshDashboardData: {
      get() {
        return store.getters["getRefreshDashboardData"];
      },
      set(value) {
        store.commit("SET_REFRESH_DASHBOARD_DATA", value);
      },
    },
    dashboardList: {
      get() {
        return store.getters["getDashboardList"];
      },
      set(value) {
        store.commit("SET_DASHBOARDS_DROPDOWN_LIST", value);
      },
    },
  },
  async created() {
    if (this.enabledActions === null) {
      await store.dispatch("getDashboards", {
        organizationId: this.organizationId,
      });
    }
  },
  async mounted() {
    if (this.organizationId && this.dashboardId) {
      this.loadingDashboard = true;
      this.layout = [];
      this.date = this.getLastWeek()?.replace(/\[|\]/g, "");
      this.globalFilters.datesSelected = this.date;
      this.globalFilters.dateRange = this.dateRange;
      await store.dispatch("getWidgets", {
        organizationId: this.organizationId,
      });
      await store.dispatch("cancelQueryRequests");
      await store.dispatch("getProperties", {
        organizationId: this.organizationId,
      });
      await store.dispatch("getDashboardById", {
        organizationId: this.organizationId,
        id: this.dashboardId,
      });
      this.selectedDashboard = {
        id: this.dashboard.id,
        name: this.dashboard.name,
      };
      this.dashboard.widgets = await this.sortLayout(this.dashboard.widgets);
      this.makeMobileLayout();
      this.fillLayoutWithDelay();

      this.getTableIds();
      await store.dispatch("getParameters", {
        organizationId: this.organizationId,
      });
      this.setInitFilters();
      this.predefinedFilters.filters = this.predefinedFilters.filters.filter(
        (filter) => {
          return this.filteredParameters.some(
            (param) => param.columnId === filter.columnId
          );
        }
      );

      store.commit("SET_RANGE_DATE_OPTIONS");
      if (this.dateRange !== DATE_RANGE_TYPES.CUSTOM) {
        let date = this.customRangeDate.find(
          (date) => date.id === this.dateRange
        );
        if (date) {
          this.updateSelectedDate(date.displayDate, date.id, false);
        }
      }
      this.loadingDashboard = false;
    }
  },
  beforeDestroy() {
    this.dashboardTableIds = [];
    this.fullScreenWidget = null;
    this.isViewDestroyed = true;
    clearTimeout(this.widgetFillTimeout);
    this.globalFilters = {
      logicalOperator: 1,
      filters: [],
    };
    if (this.propertiesSelected) {
      this.propertiesSelected.splice(0, this.propertiesSelected.length);
    }
    delete this.parameters[this.tableId];
  },
  destroyed() {
    this.layout = [];
  },
  methods: {
    getTableIds() {
      const allTableIds = this.dashboard.widgets.map((item) => item.tableId);
      this.dashboardTableIds = [...new Set(allTableIds)];
    },
    setInitFilters(changedDashboard = false) {
      const urlParams = new URLSearchParams(window.location.search);
      let filters =
        urlParams.get("filters") || localStorage.getItem(this.dashboardId);
      let autoRefresh =
        urlParams.get("autoRefresh") ||
        localStorage.getItem(`autoRefresh-${this.dashboardId}`);

      if (changedDashboard) {
        filters = localStorage.getItem(this.dashboardId);

        if (localStorage.getItem(`autoRefresh-${this.dashboardId}`)) {
          autoRefresh = localStorage.getItem(`autoRefresh-${this.dashboardId}`);
        } else {
          autoRefresh = this.autoRefreshOptions.find(
            (item) => item.text === "Off"
          );
        }
      }

      if (filters) {
        try {
          this.saveFilters(null, JSON.parse(filters), false);
        } catch {
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.delete("filters");

          localStorage.removeItem(this.dashboardId);
          const updatedURL = `${
            window.location.pathname
          }?${urlParams.toString()}`;
          window.history.replaceState(null, "", updatedURL);
        }
      }
      if (autoRefresh) {
        const shouldParse = typeof autoRefresh === "string";
        const autoRefreshValue = shouldParse
          ? JSON.parse(autoRefresh)
          : autoRefresh;
        this.changeAutoRefresh(autoRefreshValue, true);
      }
    },
    saveFilters(e, globalFilters, removedTag) {
      e?.preventDefault();
      const modalFilters = this.$refs?.globalFilter;
      const updatedFilters =
        globalFilters?.filters || modalFilters?.getUpdatedFilters();
      const updatedOperator =
        globalFilters?.logicalOperator || modalFilters?.getUpdatedOperator();

      if (!globalFilters) {
        this.validateFilters(updatedFilters);
        if (this.areFiltersInvalid) {
          showToast("error", `Some filters are invalid!`);
          return;
        }
      }

      this.globalFilters = {
        filters: updatedFilters,
        logicalOperator: updatedOperator,
        datesSelected:
          globalFilters?.datesSelected || this.globalFilters.datesSelected,
        dateRange: globalFilters?.dateRange || this.globalFilters.dateRange,
        selectedProperties:
          globalFilters?.selectedProperties ||
          this.globalFilters.selectedProperties,
      };

      // eslint-disable-next-line no-unsafe-optional-chaining
      const tempArr = [...this.globalFilters?.filters];
      tempArr.map((item) => {
        this.checkIsTableExisting(item);
      });

      this.globalFilters.filters.map((item) => {
        if (item.id) {
          return;
        }
        item.id = generateGuid();
      });

      if (this.globalFilters.datesSelected !== "") {
        const dates = this.globalFilters?.datesSelected?.split(" to ");
        this.datesSelected = {
          startDate: dates[0],
          endDate: dates[dates.length === 1 ? 0 : 1],
        };
        this.date = this.globalFilters.datesSelected;
        this.dateRange = this.globalFilters.dateRange;

        this.dateFrom = this.datesSelected.startDate;
        this.dateTo = this.datesSelected.endDate;
      }
      if (this.globalFilters?.selectedProperties?.length > 0) {
        this.propertiesSelected = this.properties.filter((item) =>
          this.globalFilters.selectedProperties.includes(item.id)
        );
      }

      const urlParams = new URLSearchParams(window.location.search);

      if (
        this.globalFilters.filters.length > 0 ||
        this.globalFilters.datesSelected !== "" ||
        this.globalFilters.selectedProperties.length > 0
      ) {
        urlParams.set("filters", JSON.stringify(this.globalFilters));
        localStorage.setItem(
          this.dashboardId,
          JSON.stringify(this.globalFilters)
        );
      } else {
        urlParams.delete("filters");
        localStorage.removeItem(this.dashboardId);
      }
      const updatedURL = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, "", updatedURL);

      if (globalFilters == null || removedTag) {
        this.propertyOrDateSelected();
      }
      this.$nextTick(() => {
        this.$bvModal.hide("globalFilter");
      });
    },
    async addNewGlobalFilter() {
      await this.$refs?.globalFilter?.openModal();

      setTimeout(() => {
        if (this.globalFilters.filters.length >= 1) {
          const filterHolderEl = document.querySelector(
            ".modal-filter__container"
          );
          if (filterHolderEl) {
            const scrollTo = filterHolderEl.scrollHeight;
            filterHolderEl.scrollTo({ top: scrollTo, behavior: "smooth" });
          }
        }
      }, 0);
    },
    validateFilters(filters) {
      let filtersInvalid = false;
      filters.forEach((filter) => {
        if (!this.isFilterValid(filter)) {
          filter.invalid = true;
          filtersInvalid = true;
        } else {
          filter.invalid = false;
        }
      });

      if (filtersInvalid) {
        this.areFiltersInvalid = true;
      } else {
        this.areFiltersInvalid = false;
      }
    },
    isFilterValid(filter) {
      const operator = this.getOperators(filter.columnId);
      const validation = validateValue(filter, operator);
      filter.validValue = validation;

      return (
        filter.columnId !== null &&
        filter.operator !== null &&
        filter.filterValues.length > 0 &&
        filter.validValue &&
        (operator === columnTypes.KEY_VALUE_PAIR
          ? !!filter.tempFilterKey
          : true)
      );
    },
    fillLayoutWithDelay() {
      this.dashboard.widgets.forEach(async (element, index) => {
        if (!this.isViewDestroyed)
          await new Promise((resolve) => {
            this.widgetFillTimeout = setTimeout(() => {
              if (!this.isViewDestroyed) this.layout.push(element);
              resolve();
            }, this.layoutDelay * (index + 1));
          });
      });
    },
    makeMobileLayout() {
      if (document.body.offsetWidth < 700) {
        this.dashboard.widgets.forEach((widget, index) => {
          widget.y = index;
          widget.x = 0;
          widget.w = 6;
        });
      }
    },
    async sortLayout(layout) {
      return await layout.sort((a, b) => {
        if (a.y === b.y) {
          return a.x - b.x;
        }
        return a.y - b.y;
      });
    },
    getLastWeek() {
      const today = new Date();
      today.setDate(today.getDate() - 7);
      const lastWeek = new Date(today);

      const lastWeekMonth = (lastWeek.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const lastWeekDay = lastWeek.getDate().toString().padStart(2, "0");
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentDay = new Date().getDate().toString().padStart(2, "0");

      this.dateFrom = `${lastWeek.getFullYear()}-${lastWeekMonth}-${lastWeekDay}`;
      this.dateTo = `${new Date().getFullYear()}-${currentMonth}-${currentDay}`;

      this.datesSelected = {
        startDate: this.dateFrom,
        endDate: this.dateTo,
      };

      return `[${this.dateFrom} to ${this.dateTo}]`;
    },
    toggleCalendar() {
      const { fp } = this.$refs.flatpickr;
      fp.toggle();
    },
    async propertyOrDateSelected(properties) {
      if (properties) this.propertiesSelected = properties;
      await store.dispatch("cancelQueryRequests");

      this.globalFilters.datesSelected = this.date;
      this.globalFilters.dateRange = this.dateRange;
      this.globalFilters.selectedProperties = this.propertiesSelected.map(
        (property) => property.id
      );
      this.saveFilters(null, this.globalFilters, false);
      this.refreshDashboardData = !this.refreshDashboardData;
    },
    async deleteDashboard(dashboard) {
      await store.dispatch("deleteDashboard", {
        organizationId: this.organizationId,
        dashboard,
      });
      await store.dispatch("cancelQueryRequests");

      this.$router.push({
        name: "dashboard",
        params: {
          organizationId: this.organizationId,
        },
      });
    },
    getOperators(id) {
      if (!id) return null;
      return this.parameters[this.tableId]?.parameters.find(
        (x) => x.columnId === id
      )?.columnType;
    },
    checkIsTableExisting(filter) {
      const filterToCheck = this.filteredParameters.find((param) => {
        return param.columnId === filter.columnId;
      });

      if (!filterToCheck) {
        const itemToDelete = this.globalFilters.filters.findIndex(
          (item) => item.id === filter.id
        );
        if (itemToDelete !== -1) {
          this.globalFilters?.filters.splice(itemToDelete, 1);
        }
      }
    },
    updateSelectedDate(date, dateRange, refreshWidgets = true) {
      this.date = date;
      this.dateFrom = date;
      this.dateRange = dateRange;
      const formatDate = date.split(" to ");
      if (formatDate.length > 1) {
        [this.dateFrom, this.dateTo] = formatDate;
      } else {
        this.dateTo = this.dateFrom;
      }
      this.datesSelected = {
        startDate: this.dateFrom,
        endDate: this.dateTo,
      };
      if (refreshWidgets) this.propertyOrDateSelected();
    },
    async exportDashboard() {
      let predefinedGlobalFilters = this.dashboard.predefinedGlobalFilters.map(
        (filter) => {
          return { id: filter.id, isHidden: filter.hidden };
        }
      );
      await store.dispatch("exportDashboard", {
        data: {
          id: this.dashboardId,
          properties: this.propertiesSelected.map((item) => item.id),
          globalFilters: this.globalFilters.filters,
          globalFilterLogicalOperator: this.globalFilters.logicalOperator,
          predefinedGlobalFilters,
          startDate: this.dateFrom,
          endDate: this.dateTo,
        },
        organizationId: this.organizationId,
      });
    },
    changeAutoRefresh(item, initAutoRefresh) {
      this.dashboardAutoRefresh = item;

      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("autoRefresh", JSON.stringify(this.dashboardAutoRefresh));
      localStorage.setItem(
        `autoRefresh-${this.dashboardId}`,
        JSON.stringify(this.dashboardAutoRefresh)
      );

      if (!initAutoRefresh) {
        const updatedURL = `${
          window.location.pathname
        }?${urlParams.toString()}`;
        window.history.replaceState(null, "", updatedURL);
        showToast(
          "success",
          `Auto refresh ${
            item.minutes !== 0
              ? ` interval changed to ${item.minutes} ${
                  item.minutes === 1 ? "minute." : "minutes."
                }`
              : "has been turned off."
          }`
        );
      }
    },
    savePredefinedFilters(e, predefinedGlobalFilters, shouldClose) {
      e?.preventDefault();
      const modalFilters = this.$refs?.predefinedFilter;
      const updatedFilters =
        predefinedGlobalFilters?.filters || modalFilters?.getUpdatedFilters();
      const updatedOperator = predefinedGlobalFilters?.filters
        ? modalFilters?.getUpdatedOperator()
        : this.dashboard.predefinedGlobalFilterLogicalOperator;

      let initialHiddenFilters = this.dashboard.predefinedGlobalFilters.map(
        (filter) => filter.hidden
      );
      let updatedHiddenFilters = updatedFilters.map((filter) => filter.hidden);

      if (
        _.isEqual(initialHiddenFilters, updatedHiddenFilters) &&
        !predefinedGlobalFilters
      ) {
        this.predefinedFiltersChanged = false;
      } else {
        this.predefinedFiltersChanged = true;
      }

      if (!predefinedGlobalFilters) {
        this.validateFilters(updatedFilters);
        if (this.areFiltersInvalid) {
          showToast("error", `Some filters are invalid!`);
          return;
        }
      }

      this.dashboard.predefinedGlobalFilters = updatedFilters;
      this.dashboard.predefinedGlobalFilterLogicalOperator = updatedOperator;

      this.dashboard.predefinedGlobalFilters.map((item) => {
        if (item.id) {
          return;
        }
        item.id = generateGuid();
      });
      if (shouldClose) {
        this.$nextTick(() => {
          this.$bvModal.hide("predefinedFilter");
          this.predefinedFiltersChanged = false;
        });
      }
      if (predefinedGlobalFilters || this.predefinedFiltersChanged) {
        this.propertyOrDateSelected();
      }
    },
    resetFiltersValues() {
      this.propertiesSelected = [];
      this.globalFilters = {
        logicalOperator: 1,
        filters: [],
        datesSelected: "",
        dateRange: DATE_RANGE_TYPES.CUSTOM,
        selectedProperties: [],
      };
      this.date = this.getLastWeek()?.replace(/\[|\]/g, "");
      this.setInitFilters(true);
    },
    async changeDashboard(id) {
      this.dashboardId = id;
      this.layout = [];
      this.resetFiltersValues();
      this.loadingDashboard = true;
      await store.dispatch("getDashboardById", {
        organizationId: this.organizationId,
        id,
      });
      this.loadingDashboard = false;
      this.selectedDashboard = {
        id: this.dashboard.id,
        name: this.dashboard.name,
      };
      this.dashboard.widgets = await this.sortLayout(this.dashboard.widgets);
      this.makeMobileLayout();
      this.fillLayoutWithDelay();
      this.$router.push({
        name: "dashboard-view",
        params: {
          id: this.selectedDashboard.id,
          organizationId: this.organizationId,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/views/dashboard-overview.scss";
</style>
