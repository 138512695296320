<template>
  <div id="app" class="bisko-app__container">
    <div :class="contentContainerClass">
      <app-bread-crumb module="visualizer" />
      <transition name="zoom-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
import { AppBreadCrumb } from "@nodus/utilities-front";

export default {
  components: {
    AppBreadCrumb,
  },
  computed: {
    contentContainerClass() {
      const routeName = this.$route.name;
      return {
        content__container: true,
        dashboards__container:
          routeName === "dashboard" || routeName === "dashboard-share-list",
      };
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/index.scss";
</style>
