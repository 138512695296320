<template>
  <div class="table__state-icon">
    <div
      v-if="getProcessingStatus(data.forecastStatus)"
      :id="`svg__processing-${id}`"
      class="svg__processing"
    >
      <svg width="30" height="30">
        <circle
          id="circle"
          class="circle"
          r="8"
          cx="15"
          cy="15"
          stroke="#1D79F2"
          stroke-width="1.5"
        />
        <circle
          id="circleFill"
          class="circleFill"
          r="4"
          cx="15"
          cy="15"
          stroke="#1D79F2"
          stroke-width="8"
          fill="none"
        />
      </svg>
      <div class="svg__border"></div>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="svg__processing-icon"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.1 1.52086C1.19104 1.52849 1.28146 1.53819 1.37123 1.54989L1.50057 0.558295C1.2056 0.519819 0.904992 0.5 0.6 0.5H0.1V1.80066H1.1V1.52086ZM2.85832 1.9475C3.3377 2.14629 3.78541 2.4066 4.19156 2.7187L4.80088 1.92578C4.32624 1.56104 3.80259 1.2565 3.24137 1.02377L2.85832 1.9475ZM5.2813 3.80844C5.5934 4.21459 5.85371 4.6623 6.0525 5.14169L6.97623 4.75863C6.7435 4.19741 6.43896 3.67376 6.07422 3.19912L5.2813 3.80844ZM1.1 3.40053H0.1V5.00128H1.1V3.40053ZM6.47914 6.9C6.47151 6.80896 6.46181 6.71854 6.45011 6.62877L7.44171 6.49943C7.48018 6.79439 7.5 7.09501 7.5 7.4V7.9H6.19934V6.9H6.47914ZM1.1 6.60033H0.1V7.9H1.39967V6.9H1.10037L1.1 6.60033ZM2.99872 6.9V7.9H4.59947V6.9H2.99872Z"
          fill="#1D79F2"
        />
      </svg>
      <b-tooltip :target="`svg__processing-${id}`">{{
        data.forecastMessage
      }}</b-tooltip>
    </div>

    <svg
      v-if="getDoneStatus(data.forecastStatus)"
      :id="`svg__done-${id}`"
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.54008 3.4209H9.04008C4.87585 3.4209 1.50008 6.79667 1.50008 10.9609C1.50008 15.1251 4.87585 18.5009 9.04008 18.5009C13.2043 18.5009 16.5801 15.1251 16.5801 10.9609V10.4609H9.54008V3.4209ZM9.04008 10.9609L9.0401 11.4609H15.5612C15.3058 14.8392 12.4838 17.5009 9.04008 17.5009C5.42813 17.5009 2.50008 14.5728 2.50008 10.9609C2.50008 7.51718 5.16174 4.69513 8.54008 4.43973V10.9609L9.04008 10.9609ZM9.04008 10.9609L8.54008 10.9609V11.4609H9.0401L9.04008 10.9609Z"
        fill="#36B37E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.3137 2.94419C18.5287 3.11746 18.5626 3.43223 18.3893 3.64724L14.6758 8.25556C14.463 8.51961 14.0675 8.5389 13.83 8.29681L11.6431 6.06726C11.4497 5.87013 11.4527 5.55356 11.6499 5.36019C11.847 5.16682 12.1636 5.16987 12.3569 5.367L14.2028 7.2488L17.6107 3.01978C17.7839 2.80477 18.0987 2.77092 18.3137 2.94419Z"
        fill="#36B37E"
      />
      <b-tooltip :target="`svg__done-${id}`">
        {{ data.forecastMessage }}
      </b-tooltip>
    </svg>

    <svg
      v-if="getFailedStatus(data.forecastStatus)"
      :id="`svg__failed-${id}`"
      width="24"
      height="24"
      viewBox="8 8 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.3101 14.1289H22.5601C16.3138 14.1289 11.2501 19.1926 11.2501 25.4389C11.2501 31.6852 16.3138 36.7489 22.5601 36.7489C28.8065 36.7489 33.8701 31.6852 33.8701 25.4389V24.6889H23.3101V14.1289ZM22.5601 25.4389L22.5601 26.1889H32.3419C31.9588 31.2564 27.7257 35.2489 22.5601 35.2489C17.1422 35.2489 12.7501 30.8568 12.7501 25.4389C12.7501 20.2733 16.7426 16.0403 21.8101 15.6571V25.4389L22.5601 25.4389ZM22.5601 25.4389L21.8101 25.4389V26.1889H22.5601L22.5601 25.4389Z"
        fill="#E34850"
      />
      <path
        d="M35.7742 13.2842C36.0671 12.9914 36.0671 12.5165 35.7742 12.2236C35.4813 11.9307 35.0064 11.9307 34.7135 12.2236L31.4998 15.4374L28.2872 12.2247C27.9943 11.9318 27.5194 11.9318 27.2265 12.2247C26.9336 12.5175 26.9336 12.9924 27.2265 13.2853L30.4392 16.498L27.2266 19.7107C26.9337 20.0036 26.9337 20.4784 27.2266 20.7713C27.5195 21.0642 27.9944 21.0642 28.2873 20.7713L31.4998 17.5587L34.7134 20.7724C35.0063 21.0653 35.4812 21.0653 35.7741 20.7724C36.067 20.4795 36.067 20.0046 35.7741 19.7117L32.5605 16.498L35.7742 13.2842Z"
        fill="#E34850"
      />
      <b-tooltip :target="`svg__failed-${id}`">
        {{ data.forecastMessage }}
      </b-tooltip>
    </svg>
  </div>
</template>

<script>
import { BTooltip } from "bootstrap-vue";
import { forecastStatus } from "@/utils/constants";

export default {
  components: {
    BTooltip,
  },
  props: {
    data: {
      type: [Array, Object],
    },
    id: {
      type: [String, Number],
    },
  },
  setup() {
    const getProcessingStatus = (status) =>
      forecastStatus.PROCESSING === status;
    const getFailedStatus = (status) => forecastStatus.FAILED === status;
    const getDoneStatus = (status) => forecastStatus.SUCCESS === status;

    return {
      getProcessingStatus,
      getFailedStatus,
      getDoneStatus,
    };
  },
};
</script>

<style lang="scss">
.circle {
  fill: white;
  stroke-dasharray: 38.5 50.5;
}

.circleFill {
  stroke-dasharray: 0 31.4;
  animation: animate_circleFill 2.5s linear forwards infinite;
}

@keyframes animate_circleFill {
  to {
    stroke-dasharray: 19 314;
  }
}

.svg__processing {
  position: relative;
  margin-left: -3px;
}

.svg__border {
  border-left: 1.5px solid #1d79f2;
  border-bottom: 1.5px solid #1d79f2;
  position: absolute;
  left: 15px;
  width: 9px;
  height: 9px;
  top: 7px;
}
.svg__processing-icon {
  position: absolute;
  bottom: 17px;
  left: 19px;
}
</style>
