var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", { staticClass: "share-title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("p", { staticClass: "share-desc" }, [
      _vm._v(" " + _vm._s(_vm.desc) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }