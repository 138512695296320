<template>
  <div class="side-bar">
    <div class="widgets__toolbar">
      <h1>Widgets</h1>
      <div class="widgets__container">
        <div v-for="widget in widgetTypes" :key="widget.id">
          <div
            @drag="drag(widget.id)"
            @dragend="dragend"
            draggable="true"
            unselectable="on"
            id="map"
            class="widget__card"
            :class="selectedWidget === widget.id ? 'selected-widget' : ''"
            @click="selectWidget(widget.id)"
          >
            <img :src="widget.image" :alt="`${widget.name} image`" />
            <p>{{ widget.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import generateGuid from "@/utils/guid.js";
import { showToast } from "@nodus/utilities-front";
import Widget from "../../models/Widget";

export default {
  data() {
    return {
      selectedWidget: null,
      gridLayoutRef: this.$parent.$parent.$children.find(
        (item) => item?.$el?.id === "grid-layout"
      ).$refs.gridlayout,
    };
  },
  computed: {
    layout: {
      get() {
        return store.getters["getLayoutArray"];
      },
      set(value) {
        store.commit("SET_LAYOUT_ARRAY", value);
      },
    },
    mouseXY: {
      get() {
        return store.getters["getMouseXY"];
      },
      set(val) {
        store.commit("SET_MOUSE_XY", val);
      },
    },
    DragPos: {
      get() {
        return store.getters["getDragPos"];
      },
      set(value) {
        store.commit("SET_DRAG_POS", value);
      },
    },
    selectedItem: {
      get() {
        return store.getters["getSelectedItem"];
      },
      set(value) {
        store.commit("SET_SELECTED_ITEM", value);
      },
    },
    showSideBar: {
      get() {
        return store.getters["getShowSideBar"];
      },
      set(value) {
        store.commit("SET_SHOW_SIDEBAR", value);
      },
    },
    selectedDashboard: {
      get() {
        return store.getters["getSelectedDashboard"];
      },
      set(value) {
        store.commit("SET_SELECTED_DASHBOARD", value);
      },
    },
    widgetTypes: {
      get() {
        return store.getters["getWidgetTypes"];
      },
      set(value) {
        store.commit("SET_WIDGET_TYPES", value);
      },
    },
  },
  methods: {
    selectWidget(type) {
      this.selectedWidget = type;
    },
    drag: function (widgetType) {
      let parentRect = document
        .getElementById("content")
        .getBoundingClientRect();
      let mouseInGrid = false;
      if (
        this.mouseXY.x > parentRect.left &&
        this.mouseXY.x < parentRect.right &&
        this.mouseXY.y > parentRect.top &&
        this.mouseXY.y < parentRect.bottom
      ) {
        mouseInGrid = true;
      }
      if (
        mouseInGrid === true &&
        this.layout.findIndex((item) => item.i === "drop") === -1
      ) {
        let widget = new Widget({
          positionX: 5,
          positionY: this.layout.length + (this.colNum || 6),
          width: Number(this.DragPos.w),
          height: Number(this.DragPos.h),
          id: "drop",
          widgetType: widgetType,
          data: {},
          options: this.DragPos.options,
          show: false,
        });
        store.commit("PUSH_TO_LAYOUT_ARRAY", widget);
      }
      setTimeout(() => {
        let index = this.layout.findIndex((item) => item.i === "drop");
        if (index !== -1) {
          let el = this.gridLayoutRef.$children[index + 1];

          let new_pos = el.calcXY(
            this.mouseXY.y - parentRect.top - 100,
            this.mouseXY.x - parentRect.left * 1.2
          );
          el.dragging = {
            top: this.mouseXY.y - parentRect.top - 100,
            left: this.mouseXY.x - parentRect.left * 1.2,
          };
          if (mouseInGrid === true) {
            this.gridLayoutRef.dragEvent(
              "dragstart",
              "drop",
              new_pos.x,
              new_pos.y,
              1,
              1
            );
            this.DragPos.i = generateGuid();
            this.DragPos.x = new_pos.x;
            this.DragPos.y = new_pos.y;
            this.DragPos.widgetType = this.layout[index].widgetType;
            this.DragPos.data = this.layout[index].data;
            this.DragPos.show = this.layout[index].show;
          }
          if (mouseInGrid === false) {
            this.gridLayoutRef.dragEvent(
              "dragend",
              "drop",
              new_pos.x,
              new_pos.y,
              1,
              1
            );
            this.layout = this.layout.filter((obj) => obj.i !== "drop");
            store.commit("RESET_DRAG_POS");
          }
        }
      }, 0);
    },
    dragend: function () {
      let parentRect = document
        .getElementById("content")
        .getBoundingClientRect();
      let mouseInGrid = false;
      if (
        this.mouseXY.x > parentRect.left &&
        this.mouseXY.x < parentRect.right &&
        this.mouseXY.y > parentRect.top &&
        this.mouseXY.y < parentRect.bottom
      ) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        this.gridLayoutRef.dragEvent(
          "dragend",
          "drop",
          this.DragPos.x,
          this.DragPos.y,
          1,
          1
        );
        this.layout = this.layout.filter((obj) => obj.i !== "drop");

        this.gridLayoutRef.dragEvent(
          "dragend",
          this.DragPos.i,
          this.DragPos.x,
          this.DragPos.y,
          1,
          1
        );
        try {
          this.gridLayoutRef.$children[
            this.layout.length
          ].$refs.item.style.display = "flex";
        } catch (err) {
          showToast("error", err.message);
        }
        store.commit("PUSH_TO_LAYOUT_ARRAY", this.DragPos);
        this.selectedItem = this.DragPos.i;
        store.commit("RESET_DRAG_POS");
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/widgets-toolbar.scss";
</style>
