import Field from "./Field";
import Filter from "./Filter";

class Widget {
  constructor(data) {
    this.userId = "string";
    this.index = "string";
    this.id = data.id;
    this.title = data.title || "";
    this.titleError = null;
    this.widgetType = data.widgetType;
    this.tempData = data.tempData;
    this.stacked = data.stacked;
    this.groupByDate = data.groupByDate;
    this.groupByFieldKey = data.groupByFieldKey;
    this.loading = true;
    this.data = {};
    this.w = data.width;
    this.h = data.height;
    this.x = data.positionX;
    this.y = data.positionY;
    this.tableId = data.tableId;
    this.maxAggregation = data.maxAggregation || 5;
    this.i = data.id;
    this.groupByField = data.groupByField || null;
    this.logicalOperator = data.logicalOperator;
    this.settings = data.settings;
    this.fields = data.fields
      ? data.fields.map((field) => new Field(field))
      : [];
    this.filters = data.filters
      ? data.filters.map((filter) => new Filter(filter))
      : [];
    this.show = data.show || false;
    this.data = {
      height: 1,
    };
  }
}

export default Widget;
