<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="dashboard-overview h-100">
    <FullScreenHeader
      v-if="!loadingDashboard && isDashboardEnabled"
      :header-title="selectedDashboard.name"
      type="shared"
      :no-fullscreen="noFullscreen"
    />
    <div
      v-if="
        shareType !== 'embed' &&
        !loadingDashboard &&
        !fullScreenWidget &&
        isDashboardEnabled
      "
      class="d-flex justify-content-between mt-5 mb-3 flex-wrap"
      style="gap: 12px"
    >
      <div class="properties-filters__container">
        <MultipleProperties
          v-if="properties"
          id="select-properties"
          :options="properties"
          v-bind="
            propertiesSelected?.length > 0
              ? { selected: propertiesSelected }
              : {}
          "
          :classes="[
            'multiple-properties--one',
            'multiple-properties__min-width',
          ]"
          @update-data="propertyOrDateSelected"
          :isDisabled="!dashboardConfigs.enablePropertyFilter"
        />
        <b-button
          v-if="dashboardConfigs.enableGlobalFiltersFilter"
          @click="addNewGlobalFilter()"
          :disabled="!dashboardConfigs.enableGlobalFiltersFilter"
        >
          <GjIcon name="Plus_fill" size="14" /> Add filter
        </b-button>
      </div>
      <div class="dashboard-configs-right">
        <div class="date-position__right">
          <span class="calendar__range-label">Show data per:</span>
          <div class="header__wrapper-input calendar__range-input">
            <date-picker-modal
              :start-end-date="date"
              :date-range="dateRange"
              :min-date="limitedDateRange().fromDate"
              :max-date="limitedDateRange().toDate"
              :isOnShareModal="false"
              @update-selected-date="updateSelectedDate"
              :isDisabled="!dashboardConfigs.enableDateFilter"
            />
          </div>
        </div>
        <b-dropdown class="auto-refresh__dropdown" right>
          <template #button-content>
            <div>
              <GjIcon name="RefreshAlt" size="18" />
              {{ dashboardAutoRefresh.text }}
            </div>
            <GjIcon name="ArrowDown" />
          </template>
          <b-dropdown-item
            v-for="item in autoRefreshOptions"
            @click="changeAutoRefresh(item, false)"
            :key="item.id"
          >
            <GjIcon name="RefreshAlt" size="18" />
            {{ item.text }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div
      class="filter-tags__wrapper"
      :class="{ 'mt-5': shareType === 'embed' }"
    >
      <FilterTags
        v-if="
          globalFilters?.filters?.length > 0 &&
          !loadingDashboard &&
          !fullScreenWidget &&
          isDashboardEnabled
        "
        :filters="globalFilters"
        :tableId="tableId"
        @update-filters="saveFilters"
        :areDisabled="!dashboardConfigs.enableGlobalFiltersFilter"
      />
    </div>
    <modal-filter
      ref="globalFilter"
      key="globalFilter"
      :filters="globalFilters.filters"
      :logicalOperator="globalFilters.logicalOperator"
      @save-filters="saveFilters"
    />
    <div v-if="loadingDashboard" class="loading-bar__wrapper">
      <LoadingBar />
    </div>
    <div
      class="d-flex flex-column w-100 h-100 justify-content-center align-items-center h2"
      v-else-if="!isDashboardEnabled"
    >
      <img
        width="70"
        :src="require(`@/assets/img/error-icon.svg`)"
        alt="Gjirafa icon"
        class="mb-4"
      />
      You cannot view this dashboard!
    </div>
    <GridLayout
      v-else
      mode="view"
      :type="shareType"
      :no-fullscreen="noFullscreen"
    />
  </div>
</template>

<script>
import store from "@/store";
import generateGuid from "@/utils/guid.js";
import {
  GjIcon,
  LoadingBar,
  MultipleProperties,
  showToast,
} from "@nodus/utilities-front";
import { BButton, BDropdown, BDropdownItem } from "bootstrap-vue";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import DatePickerModal from "../components/DatePickerModal.vue";
import FullScreenHeader from "../components/FullScreenHeader.vue";
import GridLayout from "../components/GridLayout.vue";
import ModalFilter from "../components/ModalFilter.vue";
import FilterTags from "../components/global-filters/FilterTags.vue";
import validateValue from "../utils/regexValidations";
import { DATE_RANGE_TYPES } from "../utils/constants";

export default {
  components: {
    GridLayout,
    BButton,
    FilterTags,
    ModalFilter,
    MultipleProperties,
    LoadingBar,
    FullScreenHeader,
    DatePickerModal,
    GjIcon,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      filters: [],
      areFiltersInvalid: false,
      organizationId: this.$route?.params?.organizationId,
      dashboardId: null,
      loadingDashboard: true,
      date: "",
      dateFrom: "",
      dateTo: "",
      dateRange: DATE_RANGE_TYPES.CUSTOM,
      layoutDelay: 400,
      timeoutId: null,
      tableId: "dashboard",
      isDashboardEnabled: false,
      shareType: this.$route?.params?.type,
      noFullscreen: false,
      autoRefreshOptions: [
        {
          minutes: 0,
          text: "Off",
        },
        {
          minutes: 1,
          text: "Every 1 min",
        },
        {
          minutes: 3,
          text: "Every 3 min",
        },
        {
          minutes: 5,
          text: "Every 5 min",
        },
      ],
    };
  },
  computed: {
    layout: {
      get() {
        return store.getters["getLayoutArray"];
      },
      set(value) {
        store.commit("SET_LAYOUT_ARRAY", value);
      },
    },
    dashboardList: {
      get() {
        return store.getters["getDashboardList"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_LIST", value);
      },
    },
    selectedDashboard: {
      get() {
        return store.getters["getSelectedDashboard"];
      },
      set(value) {
        store.commit("SET_SELECTED_DASHBOARD", value);
      },
    },
    properties: {
      get() {
        return store.getters["getPropertiesList"];
      },
      set(value) {
        store.commit("SET_PROPERTIES", value);
      },
    },
    propertiesSelected: {
      get() {
        return store.getters["getPropertiesSelectedList"];
      },
      set(value) {
        store.commit("SET_PROPERTIES_SELECTED", value);
      },
    },
    selectedDashboardName: {
      get() {
        if (this.selectedDashboard.id) {
          return this.selectedDashboard.name;
        }
        return "Select a dashboard";
      },
    },
    dashboard: {
      get() {
        return store.getters["getDashboard"];
      },
      set(value) {
        store.commit("SET_DASHBOARD", value);
      },
    },
    datesSelected: {
      get() {
        return store.getters["getSelectedDates"];
      },
      set(value) {
        store.commit("SET_SELECTED_DATES", value);
      },
    },
    embedFilters: {
      get() {
        return store.getters["getEmbedFilters"];
      },
      set(value) {
        store.commit("SET_EMBED_FILTERS", value);
      },
    },
    globalFilters: {
      get() {
        return store.getters["getGlobalFilters"];
      },
      set(value) {
        store.commit("SET_GLOBAL_FILTERS", value);
      },
    },
    parameters: {
      get() {
        return store.getters["getFilterParameters"];
      },
      set(value) {
        store.commit("SET_FILTER_PARAMETERS", value);
      },
    },
    dashboardTableIds: {
      get() {
        return store.getters["getDashboardTableIds"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_TABLE_IDS", value);
      },
    },
    filteredParameters: {
      get() {
        return store.getters["getFilteredDashboardParameters"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_FILTERED_PARAMS", value);
      },
    },
    isDashobardFullScreen: {
      get() {
        return store.getters["getIsDashobardFullScreen"];
      },
      set(value) {
        store.commit("SET_IS_DASHBOARD_FULL_SCREEN", value);
      },
    },
    fullScreenWidget: {
      get() {
        return store.getters["getFullScreenWidget"];
      },
      set(value) {
        store.commit("SET_FULL_SCREEN_WIDGET", value);
      },
    },
    widgetTypes: {
      get() {
        return store.getters["getWidgetTypes"];
      },
      set(value) {
        store.commit("SET_WIDGET_TYPES", value);
      },
    },
    dashboardHash: {
      get() {
        return store.getters["getDashboardHash"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_HASH", value);
      },
    },
    dashboardConfigs: {
      get() {
        return store.getters["getDashboardConfigs"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_CONFIGS", value);
      },
    },
    embedConfig: {
      get() {
        return store.getters["getEmbedConfig"];
      },
      set(value) {
        store.commit("SET_EMBED_CONFIG", value);
      },
    },
    dashboardAutoRefresh: {
      get() {
        return store.getters["getDashboardAutoRefresh"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_AUTO_REFRESH", value);
      },
    },
    customRangeDate: {
      get() {
        return store.getters["getCustomRangeDate"];
      },
    },
    refreshDashboardData: {
      get() {
        return store.getters["getRefreshDashboardData"];
      },
      set(value) {
        store.commit("SET_REFRESH_DASHBOARD_DATA", value);
      },
    },
  },
  async mounted() {
    this.dashboardHash = this.$route?.params?.id;

    if (this.organizationId && this.dashboardHash) {
      this.loadingDashboard = true;
      this.layout = [];
      this.date = this.getLastWeek()?.replace(/\[|\]/g, "");
      this.globalFilters.datesSelected = this.date;

      let dashboardError = false;
      await store
        .dispatch("getHashShareUrl", this.organizationId)
        .then((response) => {
          this.dashboardConfigs = response;
        })
        .catch(() => {
          dashboardError = true;
        });

      if (dashboardError) {
        this.isDashboardEnabled = false;
        this.loadingDashboard = false;
        return;
      }
      this.dateRange = this.dashboardConfigs.dashboardSharingPeriodType;
      this.globalFilters.dateRange =
        this.dashboardConfigs.dashboardSharingPeriodType;
      this.date = `${this.dashboardConfigs.startDate} to ${this.dashboardConfigs.endDate}`;
      this.dateFrom = this.dashboardConfigs.startDate;
      this.dateTo = this.dashboardConfigs.endDate;

      if (this.shareType === "shared") {
        this.isDashboardEnabled =
          this.dashboardConfigs.dashboardSharingUrl.isShareEnabled;
      } else {
        const referrerUrl = new URL(document.referrer);
        const { isEmbedEnabled, allowedOrigins } =
          this.dashboardConfigs.dashboardSharingEmbed;
        this.isDashboardEnabled =
          isEmbedEnabled &&
          allowedOrigins.some(
            (origin) => origin === referrerUrl.origin || origin === "*"
          );
      }

      if (!this.isDashboardEnabled) {
        this.loadingDashboard = false;
        return;
      }
      this.listenForEmbedMessages();

      await store.dispatch("getWidgets", {
        organizationId: this.organizationId,
      });
      await store.dispatch("cancelQueryRequests");
      await store.dispatch("getProperties", {
        organizationId: this.organizationId,
      });
      if (this.dashboardConfigs.propertyIds.length) {
        this.properties = this.properties.filter((property) =>
          this.dashboardConfigs.propertyIds.includes(property.id)
        );
        this.propertiesSelected = this.properties;
      }

      await store.dispatch("getDashboardById", {
        organizationId: this.organizationId,
        id: this.dashboardConfigs.dashboardId,
      });
      this.selectedDashboard = {
        id: this.dashboard.id,
        name: this.dashboard.name,
      };
      this.dashboard.widgets = await this.sortLayout(this.dashboard.widgets);
      this.makeMobileLayout();
      this.fillLayoutWithDelay();

      this.getTableIds();
      await store.dispatch("getParameters", {
        organizationId: this.organizationId,
      });
      store.commit("SET_RANGE_DATE_OPTIONS");
      this.setInitFilters();
      this.loadingDashboard = false;
      await this.$nextTick(() => {
        this.updateDatefromRange();
      });
    }
  },
  beforeDestroy() {
    this.dashboardTableIds = [];
    this.fullScreenWidget = null;
    clearTimeout(this.timeoutId);
    this.globalFilters = {
      logicalOperator: 1,
      filters: [],
    };
    if (this.propertiesSelected) {
      this.propertiesSelected.splice(0, this.propertiesSelected.length);
    }
  },
  destroyed() {
    this.layout = [];
  },
  methods: {
    updateDatefromRange() {
      if (this.dateRange !== DATE_RANGE_TYPES.CUSTOM) {
        let date = this.customRangeDate.find(
          (date) => date.id === this.dateRange
        );
        if (date) {
          this.updateSelectedDate(date.displayDate, date.id, false);
        }
      } else {
        this.validateDates();
      }
    },
    limitedDateRange() {
      if (
        this.dashboardConfigs.dashboardSharingPeriodType !==
        DATE_RANGE_TYPES.CUSTOM
      ) {
        return this.customRangeDate.find(
          (date) => date.id === this.dashboardConfigs.dashboardSharingPeriodType
        );
      }
      return {
        fromDate: this.dashboardConfigs.startDate,
        toDate: this.dashboardConfigs.endDate,
      };
    },
    filterDateRangeOptions() {
      this.customRangeDate.forEach((item) => {
        const fromDate = moment(item.fromDate);
        const toDate = moment(item.toDate);

        const parsedMinDate = moment(this.limitedDateRange().fromDate);
        const parsedMaxDate = moment(this.limitedDateRange().toDate);
        if (item.id === DATE_RANGE_TYPES.CUSTOM) {
          if (
            fromDate.isBefore(parsedMinDate) ||
            fromDate.isAfter(parsedMaxDate)
          ) {
            item.fromDate = this.dateFrom;
          }

          if (toDate.isBefore(parsedMinDate) || toDate.isAfter(parsedMaxDate)) {
            item.toDate = this.dateTo;
          }

          item.displayDate = `${item.fromDate} to ${item.toDate}`;
        } else {
          if (
            fromDate.isBefore(parsedMinDate) ||
            toDate.isAfter(parsedMaxDate)
          ) {
            item.disabled = true; // Disable items outside the range
          } else {
            item.disabled = false; // Enable items within the range
          }
        }
      });
    },
    validateDates() {
      if (!this.dateFrom) {
        this.dateFrom = this.dashboardConfigs.startDate;
      }
      if (!this.dateTo) {
        this.dateTo = this.dashboardConfigs.endDate;
      }
      const parsedFromDate = moment(this.dateFrom);
      const parsedToDate = moment(this.dateTo);
      let parsedMinDate = moment(this.limitedDateRange().fromDate);
      let parsedMaxDate = moment(this.limitedDateRange().toDate);
      this.filterDateRangeOptions();
      let dateRange = this.customRangeDate.find(
        (date) => date.id === this.dateRange
      );
      if (dateRange?.disabled) {
        this.dateRange = this.dashboardConfigs.dashboardSharingPeriodType;
      }

      if (
        parsedFromDate.isBefore(parsedMinDate) ||
        parsedFromDate.isAfter(parsedMaxDate)
      ) {
        this.dateFrom = parsedMinDate.format("YYYY-MM-DD");
      }

      if (
        parsedToDate.isAfter(parsedMaxDate) ||
        parsedToDate.isBefore(parsedMinDate)
      ) {
        this.dateTo = parsedMaxDate.format("YYYY-MM-DD");
      }

      this.datesSelected = {
        startDate: this.dateFrom,
        endDate: this.dateTo,
      };
      this.date = `${this.dateFrom} to ${this.dateTo}`;
    },
    makeDashboardFullScreen() {
      this.isDashobardFullScreen = true;
      this.fullScreenWidget = null;
    },
    getTableIds() {
      const allTableIds = this.dashboard.widgets.map((item) => item.tableId);
      this.dashboardTableIds = [...new Set(allTableIds)];
    },
    setInitFilters() {
      const urlParams = new URLSearchParams(window.location.search);
      let filters =
        urlParams.get("filters") || localStorage.getItem(this.dashboardHash);
      let autoRefresh =
        urlParams.get("autoRefresh") ||
        localStorage.getItem(`autoRefresh-${this.dashboardId}`);

      if (this.shareType === "shared") {
        this.setFiltersFromConfigs();
        if (autoRefresh) {
          this.changeAutoRefresh(JSON.parse(autoRefresh), true);
        }
        if (filters) {
          try {
            this.saveFilters(null, JSON.parse(filters), false);
          } catch {
            this.deleteAllFilters();
          }
        }
        if (!this.dashboardConfigs.enableGlobalFiltersFilter) {
          this.globalFilters.filters.length = 0;
          this.saveFilters(null, this.globalFilters, false);
        }
        if (!this.dashboardConfigs.enablePropertyFilter) {
          this.globalFilters.selectedProperties.length = 0;
          this.saveFilters(null, this.globalFilters, false);
        }
        if (!this.dashboardConfigs.enableDateFilter) {
          this.globalFilters.datesSelected = "";
          this.globalFilters.dateRange =
            this.dashboardConfigs.dashboardSharingPeriodType;
          this.saveFilters(null, this.globalFilters, false);
        }
      } else if (this.shareType === "embed") {
        this.saveFilters(null, this.embedConfig, false);
      }
    },
    deleteAllFilters() {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("filters");

      localStorage.removeItem(this.dashboardHash);
      const updatedURL = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, "", updatedURL);
    },
    setFiltersFromConfigs() {
      this.dashboardConfigs.globalFilters =
        this.dashboardConfigs.globalFilters.map((filter) => ({
          ...filter,
          fromConfig: true,
        }));
      this.globalFilters.datesSelected = this.date;
      this.globalFilters.dateRange = this.dateRange;
      this.globalFilters.logicalOperator =
        this.dashboardConfigs.globalFilterLogicalOperator;
      this.globalFilters.selectedProperties = this.dashboardConfigs.propertyIds;
    },
    saveFilters(e, globalFilters, removedTag) {
      e?.preventDefault();

      const modalFilters = this.$refs?.globalFilter;
      const updatedFilters =
        globalFilters?.filters || modalFilters?.getUpdatedFilters();
      const updatedOperator =
        globalFilters?.logicalOperator || modalFilters?.getUpdatedOperator();

      if (!globalFilters) {
        this.validateFilters(updatedFilters);
        if (this.areFiltersInvalid) {
          showToast("error", `Some filters are invalid!`);
          return;
        }
      }

      this.globalFilters = {
        filters: updatedFilters,
        logicalOperator:
          this.shareType === "shared"
            ? updatedOperator
            : this.globalFilters.logicalOperator,
        datesSelected:
          globalFilters?.datesSelected || this.globalFilters.datesSelected,
        dateRange: globalFilters?.dateRange || this.globalFilters.dateRange,
        selectedProperties:
          globalFilters?.selectedProperties ||
          this.globalFilters.selectedProperties,
      };

      // eslint-disable-next-line no-unsafe-optional-chaining
      const tempArr = [...this.globalFilters?.filters];
      tempArr.map((item) => {
        this.checkIsTableExisting(item);
      });

      this.globalFilters.filters.map((item) => {
        if (item.id) {
          return;
        }
        item.id = generateGuid();
      });

      if (this.globalFilters.datesSelected !== "") {
        const dates = this.globalFilters?.datesSelected?.split(" to ");
        this.datesSelected = {
          startDate: dates[0],
          endDate: dates[dates.length === 1 ? 0 : 1],
        };
        this.date = this.globalFilters.datesSelected;
        this.dateRange = this.globalFilters.dateRange;

        this.dateFrom = this.datesSelected.startDate;
        this.dateTo = this.datesSelected.endDate;
      }
      if (this.globalFilters?.selectedProperties?.length > 0) {
        this.propertiesSelected = this.properties.filter((item) =>
          this.globalFilters.selectedProperties.includes(item.id)
        );
      }

      if (this.shareType === "shared") {
        const urlParams = new URLSearchParams(window.location.search);

        if (
          this.globalFilters.filters.length > 0 ||
          this.globalFilters.datesSelected !== "" ||
          this.globalFilters.selectedProperties.length > 0
        ) {
          urlParams.set("filters", JSON.stringify(this.globalFilters));
          localStorage.setItem(
            this.dashboardHash,
            JSON.stringify(this.globalFilters)
          );
        } else {
          urlParams.delete("filters");
          localStorage.removeItem(this.dashboardHash);
        }
        const updatedURL = `${
          window.location.pathname
        }?${urlParams.toString()}`;
        window.history.replaceState(null, "", updatedURL);
      }
      if (this.shareType === "embed") {
        this.validateDates();
      }

      if (globalFilters == null || removedTag) {
        this.propertyOrDateSelected();
      }

      this.$nextTick(() => {
        this.$bvModal.hide("globalFilter");
      });
    },
    validateFilters(filters) {
      let filtersInvalid = false;
      filters.forEach((filter) => {
        if (!this.isFilterValid(filter)) {
          filter.invalid = true;
          filtersInvalid = true;
        } else {
          filter.invalid = false;
        }
      });

      if (filtersInvalid) {
        this.areFiltersInvalid = true;
      } else {
        this.areFiltersInvalid = false;
      }
    },
    isFilterValid(filter) {
      const operator = this.getOperators(filter.columnId);
      const validation = validateValue(filter, operator);
      filter.validValue = validation;

      return (
        filter.columnId !== null &&
        filter.operator !== null &&
        filter.filterValues.length > 0 &&
        filter.validValue
      );
    },
    fillLayoutWithDelay() {
      this.dashboard.widgets.forEach(async (element, index) => {
        await new Promise((resolve) => {
          setTimeout(() => {
            this.layout.push(element);
            resolve();
          }, this.layoutDelay * (index + 1));
        });
      });
    },
    makeMobileLayout() {
      if (document.body.offsetWidth < 700) {
        this.dashboard.widgets.forEach((widget, index) => {
          widget.y = index;
          widget.x = 0;
          widget.w = 6;
        });
      }
    },
    async sortLayout(layout) {
      return await layout.sort((a, b) => {
        if (a.y === b.y) {
          return a.x - b.x;
        }
        return a.y - b.y;
      });
    },
    getLastWeek() {
      const today = new Date();
      today.setDate(today.getDate() - 7);
      const lastWeek = new Date(today);

      const lastWeekMonth = (lastWeek.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const lastWeekDay = lastWeek.getDate().toString().padStart(2, "0");
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentDay = new Date().getDate().toString().padStart(2, "0");

      this.dateFrom = `${lastWeek.getFullYear()}-${lastWeekMonth}-${lastWeekDay}`;
      this.dateTo = `${new Date().getFullYear()}-${currentMonth}-${currentDay}`;

      this.datesSelected = {
        startDate: this.dateFrom,
        endDate: this.dateTo,
      };

      return `[${this.dateFrom} to ${this.dateTo}]`;
    },
    toggleCalendar() {
      const { fp } = this.$refs.flatpickr;
      fp.toggle();
    },
    async propertyOrDateSelected(properties) {
      if (properties) this.propertiesSelected = properties;
      await store.dispatch("cancelQueryRequests");

      this.globalFilters.datesSelected = this.date;
      this.globalFilters.dateRange = this.dateRange;
      this.globalFilters.selectedProperties = this.propertiesSelected.map(
        (property) => property.id
      );
      this.saveFilters(null, this.globalFilters, false);
      this.refreshDashboardData = !this.refreshDashboardData;
    },
    async addNewGlobalFilter() {
      if (!this.dashboardConfigs.enableGlobalFiltersFilter) return;

      await this.$refs?.globalFilter?.openModal();

      setTimeout(() => {
        if (this.globalFilters.filters.length >= 1) {
          const filterHolderEl = document.querySelector(
            ".modal-filter__container"
          );
          if (filterHolderEl) {
            const scrollTo = filterHolderEl.scrollHeight;
            filterHolderEl.scrollTo({ top: scrollTo, behavior: "smooth" });
          }
        }
      }, 0);
    },
    getOperators(id) {
      if (!id) return null;
      return this.parameters[this.tableId]?.parameters.find(
        (x) => x.columnId === id
      )?.columnType;
    },
    checkIsTableExisting(filter) {
      const filterToCheck = this.filteredParameters.find((param) => {
        return param.columnId === filter.columnId;
      });

      if (!filterToCheck) {
        const itemToDelete = this.globalFilters.filters.findIndex(
          (item) => item.id === filter.id
        );
        if (itemToDelete !== -1) {
          this.globalFilters?.filters.splice(itemToDelete, 1);
        }
      }
    },
    updateSelectedDate(date, dateRange, refreshWidgets = true) {
      this.date = date;
      this.dateFrom = date;
      this.dateRange = dateRange;
      const formatDate = date.split(" to ");
      if (formatDate.length > 1) {
        [this.dateFrom, this.dateTo] = formatDate;
      } else {
        this.dateTo = this.dateFrom;
      }
      this.datesSelected = {
        startDate: this.dateFrom,
        endDate: this.dateTo,
      };
      this.validateDates();
      if (refreshWidgets) this.propertyOrDateSelected();
    },
    changeAutoRefresh(item, initAutoRefresh) {
      this.dashboardAutoRefresh = item;
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("autoRefresh", JSON.stringify(this.dashboardAutoRefresh));
      localStorage.setItem(
        `autoRefresh-${this.dashboardId}`,
        JSON.stringify(this.dashboardAutoRefresh)
      );

      if (!initAutoRefresh) {
        const updatedURL = `${
          window.location.pathname
        }?${urlParams.toString()}`;
        window.history.replaceState(null, "", updatedURL);
        showToast(
          "success",
          `Auto refresh ${
            item.minutes !== 0
              ? ` interval changed to ${item.minutes} ${
                  item.minutes === 1 ? "minute." : "minutes."
                }`
              : "has been turned off."
          }`
        );
      }
    },
    listenForEmbedMessages() {
      if (this.shareType === "embed" && !!document.referrer) {
        window.addEventListener("message", (e) => {
          let msg = e.data;
          if (!msg || msg.hashEmbedUrl !== this.dashboardHash) return;
          // eslint-disable-next-line no-case-declarations
          const {
            config: {
              startDate,
              endDate,
              dateRange,
              logicalOperator,
              properties,
              filters,
            },
            allowFullscreen,
            autoRefreshMinutes,
          } = msg.payload;
          const datesSelected = `${startDate || this.dateFrom} to ${
            endDate || this.dateTo
          }`;
          switch (msg.name) {
            case "embedConfig":
              this.embedConfig = {
                datesSelected,
                dateRange,
                selectedProperties: properties,
                logicalOperator,
                filters: [],
              };
              this.embedFilters = filters;
              this.noFullscreen = !allowFullscreen;
              // eslint-disable-next-line no-case-declarations
              this.updateAutoRefresh(autoRefreshMinutes);
              this.updateDatefromRange();
              return;
            case "updateDate":
              this.embedConfig.datesSelected = `${startDate} to ${endDate}`;
              this.saveFilters(null, this.embedConfig, true);
              return;
            case "updateProperties":
              this.embedConfig.selectedProperties = properties;
              this.saveFilters(null, this.embedConfig, true);
              return;
            case "updateFilters":
              this.embedFilters = filters;
              this.saveFilters(null, this.embedConfig, true);
              return;
            case "updateFilterLogicalOpeator":
              this.embedConfig.logicalOperator = logicalOperator;
              this.saveFilters(null, this.embedConfig, true);
              return;
            case "updateAutoRefresh":
              this.updateAutoRefresh(autoRefreshMinutes);
              return;
          }
        });
        window.parent.postMessage(
          { name: "ready", hashEmbedUrl: this.dashboardHash },
          document.referrer
        );
      }
    },
    updateAutoRefresh(autoRefreshMinutes) {
      let refresh = this.autoRefreshOptions.find(
        (item) => item.minutes === autoRefreshMinutes
      );
      if (refresh) {
        this.changeAutoRefresh(refresh, true);
      } else {
        this.changeAutoRefresh(
          { minutes: autoRefreshMinutes, text: "Custom" },
          true
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/views/dashboard-overview.scss";
</style>
