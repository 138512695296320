<template>
  <div>
    <div class="dashboard-overview">
      <div class="table-list__header">
        <PrimaryCreateDropdown
          id="create-dashboard"
          name="New Dashboard"
          :is-loading="loadingDashboards"
          :can-add="enabledActions?.canModify"
          :create-new-handler="
            enabledActions?.canModify ? createNewDashboard : () => {}
          "
          :router-path="enabledActions?.canModify ? 'dashboard-create' : ''"
        />
        <SearchInputField id="search-dashboards" :data="dashboardsData" />
      </div>
      <DeleteModal
        title="Delete Dashboard"
        type="dashboard"
        @delete="deleteDashboard"
      />
      <DashboardTemplateModal />
      <section class="table__wrapper">
        <CustomTable
          :data="dashboardsData"
          type-of-table="dashboards"
          :is-pagination-enabled="true"
          :is-loading="loadingDashboards"
          :empty-state-action="{
            canAdd: enabledActions?.canModify,
            action: createNewDashboard,
          }"
          @update-table="updateTable"
        >
          <template #row-data="{ data }">
            <DashboardsRow
              :data="data"
              @select-dashboard="setSelectedDashboard"
            />
          </template>
        </CustomTable>
      </section>
    </div>
  </div>
</template>

<script>
import DashboardTemplateModal from "@/components/DashboardTemplateModal.vue";
import store from "@/store";
import {
  CustomTable,
  DeleteModal,
  PrimaryCreateDropdown,
  SearchInputField,
  handleLastRowDelete,
} from "@nodus/utilities-front";
import DashboardsRow from "../components/DashboardsRow.vue";

export default {
  components: {
    CustomTable,
    DashboardsRow,
    PrimaryCreateDropdown,
    DeleteModal,
    SearchInputField,
    DashboardTemplateModal,
  },
  computed: {
    dashboardsData: {
      get() {
        return store.getters["getDashboardsData"];
      },
    },
    layout: {
      get() {
        return store.getters["getLayoutArray"];
      },
      set(value) {
        store.commit("SET_LAYOUT_ARRAY", value);
      },
    },
    selectedDashboardd: {
      get() {
        return store.getters["getSelectedDashboard"];
      },
      set(value) {
        store.commit("SET_SELECTED_DASHBOARD", value);
      },
    },
    enabledActions: {
      get() {
        return store.getters["getEnabledActions"];
      },
      set(value) {
        store.commit("SET_ACTIONS_ENABLED", value);
      },
    },
  },
  data() {
    return {
      organizationId: this.$route?.params?.organizationId,
      loadingDashboards: true,
      selectedDashboard: null,
    };
  },
  async mounted() {
    this.selectedDashboard = {};
    await this.updateTable();
    if (this.enabledActions?.canShare) {
      await store.dispatch("getDashboardTemplates", {
        organizationId: this.organizationId,
      });
    }
  },
  methods: {
    async createNewDashboard() {
      this.selectedDashboard = {
        id: null,
        name: null,
      };
      await store.dispatch("cancelQueryRequests");

      this.$router.push({
        name: "dashboard-create",
        params: {
          organizationId: this.organizationId,
        },
      });
    },
    setSelectedDashboard(id, name) {
      this.selectedDashboard = {
        id,
        name,
      };
    },
    async deleteDashboard() {
      await store.dispatch("deleteDashboard", {
        organizationId: this.organizationId,
        dashboard: this.selectedDashboard,
      });
      handleLastRowDelete(this.dashboardsData, store);
      await store.dispatch("cancelQueryRequests");

      this.updateTable();
    },
    async updateTable() {
      this.loadingDashboards = true;
      await store.dispatch("getDashboards", {
        organizationId: this.organizationId,
      });
      this.loadingDashboards = false;
    },
  },
  destroyed() {
    this.layout = [];
    this.selectedDashboardd = {};
  },
};
</script>
<style lang="scss">
@import "~@/assets/style/views/dashboard-overview.scss";
</style>
