<template>
  <span v-if="data.column.field === 'name'">
    <div class="dashboard-table-name">
      <ReadMore :text="data.row.name" :to="viewLocation" />
    </div>
  </span>
  <div v-else-if="data.column.field === 'insertDateTime'">
    <DateColumn :data="data" />
  </div>
  <div v-else-if="data.column.field === 'options'" class="options-container">
    <DropdownActions :items="dropdownActions"> </DropdownActions>
  </div>
  <span v-else>{{ data.formattedRow[data.column.field] }}</span>
</template>

<script>
import store from "@/store";
import {
  DateColumn,
  DropdownActions,
  //utility components
  ReadMore,
  capitalizeFirstLetter,
  //utility functions
  formatDate,
} from "@nodus/utilities-front";
import { computed, getCurrentInstance } from "vue";

export default {
  name: "DashboardsRow",
  emits: ["select-dashboard"],
  components: {
    ReadMore,
    DateColumn,
    DropdownActions,
  },
  props: {
    data: [Object],
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { organizationId } = vm.$route.params;
    const enabledActions = computed(() => store.getters.getEnabledActions);
    const datesSelected = computed(() => store.getters.getSelectedDates);

    const viewLocation = {
      name: "dashboard-view",
      params: {
        id: props.data.row.id,
        organizationId,
      },
    };

    const dropdownActions = [
      {
        id: "dashboard-view",
        icon: "IconparkPreviewOpen",
        text: "View",
        to: viewLocation,
      },
      {
        id: "dashboard-share-list",
        icon: "Share",
        text: "Shared",
        disabled: !enabledActions.value.canViewShared,
        to: {
          name: "dashboard-share-list",
          params: {
            dashboardId: props.data.row.id,
            organizationId,
          },
        },
      },
      {
        id: "dashboard-edit",
        icon: "Edit",
        text: "Edit",
        disabled: !enabledActions.value.canModify,
        to: {
          name: "dashboard-edit",
          params: {
            id: props.data.row.id,
            organizationId,
          },
        },
      },
      {
        id: "dashboard-delete",
        icon: "Delete",
        text: "Delete",
        type: "danger",
        modal: "modal-small-delete",
        disabled: !enabledActions?.value.canModify,
        action: () => {
          vm.$emit("select-dashboard", props.data.row.id, props.data.row.name);
        },
      },
    ];

    return {
      formatDate,
      capitalizeFirstLetter,
      organizationId,
      dropdownActions,
      enabledActions,
      datesSelected,
      viewLocation,
    };
  },
};
</script>
