import { formatTypes } from "./constants";

function formatTimeSpan(milliseconds) {
  const duration = [
    { label: "h", value: 60 * 60 * 1000 },
    { label: "m", value: 60 * 1000 },
    { label: "s", value: 1000 },
  ];
  let result = "";
  let remaining = milliseconds;
  duration.forEach((unit) => {
    const value = Math.floor(remaining / unit.value);
    remaining -= value * unit.value;
    result += `${value.toString().padStart(2, "0")}:`;
  });
  return result.slice(0, -1);
}

function parseNumber(number) {
  if (number !== null) {
    // eslint-disable-next-line no-case-declarations
    let val = parseFloat(number).toFixed(2);
    // eslint-disable-next-line no-case-declarations
    let v = parseFloat(val).toLocaleString("en");
    return v.replace(/\.00$/, "");
  }
  return number;
}

export default function formatValue(value, formatType) {
  switch (formatType) {
    case formatTypes.NUMBER:
      return parseNumber(value);
    case formatTypes.TIME_SPAN:
      return formatTimeSpan(value);
    case formatTypes.PERCENTAGE:
      return `${parseNumber(value)}%`;
    default:
      return value;
  }
}
