<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="child-component widget-table">
    <div class="table-spinner" v-if="!showTable">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <vue-good-table
      v-if="show"
      :id="componentId"
      mode="remote"
      :columns="data.columns"
      :total-rows="data.totalRowCount"
      :rows="data.rows"
      :fixed-header="false"
      :height="`${height * 200}px`"
      :sort-options="{
        enabled: createMode ? false : true,
      }"
      :pagination-options="{
        enabled: createMode ? false : true,
      }"
      :class="!showTable ? 'table-loading' : ''"
      @on-sort-change="(params) => sortChanged(params)"
    >
      <template slot="table-row" slot-scope="data">
        <span v-if="data.column.field === 'PageDomain'">
          <div class="domains-table__container">
            <div class="domains-table__container-img">
              <img :src="getDomainFavicon(data.row.PageDomain)" />
            </div>
            <span>
              <a :href="`https://${data.row.PageDomain}`" target="_blank">{{
                data.row.PageDomain
              }}</a>
            </span>
          </div>
        </span>
        <span
          v-else-if="data.column.field === 'PageUrl'"
          :key="data.row.originalIndex"
        >
          <div class="domains-table__container">
            <span
              v-if="data.row.PageUrl.length > maxRowLength"
              v-b-tooltip.hover="data.row.PageUrl"
              class="page-url__tooltip"
              >{{ data.row.PageUrl.substring(0, maxRowLength) }}...</span
            >
            <span v-else>{{ data.row.PageUrl }}</span>
          </div>
        </span>
        <span v-else>
          {{ data.row[data.column.field] }}
        </span>
      </template>

      <!-- empty state -->
      <template slot="emptystate">
        <!-- 200px is the height of the box, minus tablehead, pagination height and padding-->
        <div
          class="table-empty-state"
          :style="{
            height: height === 1 ? 'unset' : `${height * 200 - 230}px`,
          }"
        >
          <img :src="require('@/assets/img/no-data-in-table.svg')" />
          No data have been added yet...
        </div>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom">
        <div class="d-flex table__pagination justify-content-between flex-wrap">
          <div class="table__pagination-left d-flex align-items-center mb-0">
            <span class="text-nowrap">Rows per page: </span>
            <b-form-select
              id="per-page-dropdown"
              v-model="data.count"
              :options="['5', '10', '50', '100']"
              class="mx-25 per-page__input"
              :disabled="!showTable"
              @input="changePerPage"
            />
          </div>
          <div class="table__pagination-right">
            <div class="specific-page__container">
              <span>Page</span>
              <b-form-input
                id="specific-page"
                type="number"
                min="1"
                :max="totalPages"
                v-model="data.page"
                :disabled="!data.totalRowCount || !showTable"
                :style="`--inputlength: ${pageInputLength}ch`"
                @input="debouncedChangePage"
              />
              <span>of {{ totalPages || 1 }}</span>
            </div>
            <div>
              <b-button
                id="previous-page"
                variant="outline-secondary"
                class="navigate__button"
                :disabled="cantNavigate('previous') || !showTable"
                @click="navigate('previous')"
              >
                <img
                  class="pagination__icon"
                  :src="require('@/assets/img/left-arrow.svg')"
                />
                <span
                  class="pagination__text ml-25"
                  :class="{
                    'pagination__text--disabled': cantNavigate('previous'),
                  }"
                  >Previous</span
                >
              </b-button>
              <b-button
                id="next-page"
                variant="outline-secondary"
                class="navigate__button"
                :disabled="cantNavigate('next') || !showTable"
                @click="navigate('next')"
              >
                <span
                  class="pagination__text mr-25"
                  :class="{
                    'pagination__text--disabled': cantNavigate('next'),
                  }"
                  >Next</span
                >
                <img
                  class="pagination__icon"
                  :src="require('@/assets/img/right-arrow.svg')"
                />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { BButton, BFormSelect, BFormInput } from "bootstrap-vue";
import _ from "lodash";

export default {
  name: "component-1",
  components: {
    VueGoodTable,
    BButton,
    BFormSelect,
    BFormInput,
  },
  props: {
    componentId: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: () => 1,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    showTable: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update-table"],
  data() {
    return {
      show: false,
      columns: [],
      rows: [],
      maxRowLength: 80,
      createMode: false,
      debouncedChangePage: null,
    };
  },
  computed: {
    pageInputLength() {
      return this.data.page
        ? (this.data.page.toString().length || 1) + 4
        : null;
    },
    totalPages() {
      const total = Math.floor(this.data.totalRowCount / this.data.count);
      const remainder = this.data.totalRowCount % this.data.count;

      return remainder === 0 ? total : total + 1;
    },
  },
  created() {
    this.debouncedChangePage = _.debounce(this.changePage, 1000);
  },
  onBeforeMount() {
    // eslint-disable-next-line vue/no-mutating-props
    this.data.page = 1;
    // eslint-disable-next-line vue/no-mutating-props
    this.data.count = 10;
    // eslint-disable-next-line vue/no-mutating-props
    this.data.orderField = "";
    // eslint-disable-next-line vue/no-mutating-props
    this.data.orderType = "";
  },
  methods: {
    getDomainFavicon(pageDomain) {
      return `https://www.google.com/s2/favicons?sz=32&domain=${pageDomain}`;
    },
    sortChanged(params) {
      // eslint-disable-next-line vue/no-mutating-props
      this.data.orderBy = params[0].field;
      // eslint-disable-next-line vue/no-mutating-props
      this.data.sortingOrder = params[0].type === "asc" ? 1 : 2;
      // eslint-disable-next-line vue/no-mutating-props
      this.data.page = 1;
      this.$emit("update-table", {
        page: this.data.page,
        count: this.data.count,
      });
      this.scrollToTop();
    },
    changePerPage() {
      // eslint-disable-next-line vue/no-mutating-props
      this.data.page = 1;
      this.$emit("update-table", {
        page: this.data.page,
        count: this.data.count,
      });
      this.scrollToTop();
    },
    scrollToTop() {
      const tableWidget = document.querySelector(".vgt-responsive");
      if (tableWidget) {
        tableWidget.scrollTop = 0;
      }
    },
    changePage() {
      // eslint-disable-next-line vue/no-mutating-props
      this.data.page = this.handlePageInput();
      this.$emit("update-table", {
        page: this.data.page,
        count: this.data.count,
      });
      this.scrollToTop();
    },
    handlePageInput() {
      let { page } = this.data;
      if (page > this.totalPages) return this.totalPages || 1;
      if (page >= 1 && page <= this.totalPages) return page;
      return 1;
    },
    cantNavigate(direction) {
      if (direction === "next")
        return this.data.page >= this.totalPages || this.totalPages === 0;

      return this.data.page == 1;
    },
    navigate(direction) {
      // eslint-disable-next-line vue/no-mutating-props
      if (direction === "next") this.data.page = parseInt(this.data.page) + 1;
      // eslint-disable-next-line vue/no-mutating-props
      else this.data.page = parseInt(this.data.page) - 1;
      this.$emit("update-table", {
        page: this.data.page,
        count: this.data.count,
      });
      this.scrollToTop();
    },
  },
  mounted() {
    if (
      this.$route.path.includes("edit") ||
      this.$route.path.includes("create")
    ) {
      this.createMode = true;
    } else {
      this.createMode = false;
    }
    this.show = true;
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/table.scss";
</style>
