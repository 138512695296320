var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: _vm.typeOfModal,
      attrs: {
        id: _vm.typeOfModal,
        "modal-class": "modal-filter",
        variant: "primary",
        size: "xl",
        centered: "",
      },
      on: {
        ok: function ($event) {
          return _vm.saveFilters($event, null, false)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ close }) {
            return [
              _c(
                "div",
                { staticClass: "modal-title__container" },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.filterType !== "predefined"
                          ? "Filter by"
                          : "Predefined Filters"
                      )
                    ),
                  ]),
                  _vm.filterType === "predefined"
                    ? _c("DocumentationLink", {
                        attrs: {
                          "documentation-text":
                            "Predefined filters are applied to the entire dashboard. To learn more, follow the steps described in the",
                          "documentation-link": `${_vm.getDocumentationLink()}/dashboards`,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "bg-transparent border-0",
                  attrs: { size: "sm" },
                  on: {
                    click: function ($event) {
                      return close()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require(`@/assets/img/close-icon.svg`),
                      alt: "Close icon",
                    },
                  }),
                ]
              ),
            ]
          },
        },
        {
          key: "modal-footer",
          fn: function ({ ok, close }) {
            return [
              _c(
                "b-button",
                {
                  staticClass: "py-2 px-3",
                  attrs: { size: "sm", variant: "outline-secondary" },
                  on: {
                    click: function ($event) {
                      return close()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "py-2 px-4",
                  attrs: { size: "sm", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return ok()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.filterType === "predefined" && _vm.globalDisabled
                          ? "Update"
                          : "Save"
                      ) +
                      " "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-filter__container",
          attrs: { id: "modal-filters" },
        },
        [
          _c("configurationFilter", {
            attrs: {
              filters: _vm.clonedFilters,
              operator: _vm.operator,
              "table-id": _vm.tableId,
              isModal: true,
              loading: _vm.loading,
              "filter-type": _vm.filterType,
              "global-disabled": _vm.globalDisabled,
            },
            on: {
              "update:operator": (value) => (_vm.operator = value),
              "validate-filters": function ($event) {
                return _vm.$emit("validate-filters", _vm.data)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }