export const columnTypes = {
  INT: 1,
  LONG_INT: 4,
  STRING: 6,
  FIXED_STRING: 7,
  ARRAY_STRING: 8,
  ARRAY_INT: 9,
  KEY_VALUE_PAIR: 10,
  DATE: 11,
  DATE_TIME: 12,
  DECIMAL: 13,
  BOOLEAN: 14,
  MIXED: 16,
  ARRAY_DOUBLE: 17,
  DOUBLE: 18,
};

export const formatTypes = {
  NONE: 1,
  NUMBER: 2,
  TIME_SPAN: 3,
  PERCENTAGE: 4,
};

export const forecastStatus = {
  NOT_ACTIVE: 1,
  PROCESSING: 2,
  SUCCESS: 3,
  FAILED: 4,
};

export const DATE_RANGE_TYPES = {
  TODAY: 1,
  YESTERDAY: 2,
  LAST_7_DAYS: 3,
  THIS_WEEK: 4,
  THIS_MONTH: 5,
  LAST_30_DAYS: 6,
  LAST_90_DAYS: 7,
  CUSTOM: 8,
};

export const SHARE_TYPES = [
  {
    id: 1,
    text: "Link",
    icon: "Hyperlink",
  },
  {
    id: 2,
    text: "Embed",
    icon: "Embed",
  },
];

export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export const API_GATEWAY_URL = process.env.VUE_APP_API_GATEWAY_URL;
