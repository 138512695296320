var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "message-pannel",
      style: { background: _vm.backgroundColor, borderColor: _vm.borderColor },
    },
    [
      _c("GjIcon", {
        attrs: {
          name: _vm.iconName,
          size: _vm.iconSize || "24",
          color: _vm.iconColor || null,
        },
      }),
      _c("p", {
        style: { color: _vm.textColor, fontSize: _vm.textSize },
        domProps: { innerHTML: _vm._s(_vm.text) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }