export default function validateValue(filter, operator) {
  let isValueValid;
  const regexNumberValidator = /^\d+$/;
  const regexDecimalValidator = /^\d*\.?\d*$/;

  switch (operator) {
    case 1:
    case 9:
      isValueValid = filter.filterValues.every((item) => {
        return regexNumberValidator.test(item.value) && item.value < 0x7fffffff;
      });
      break;
    case 4:
      filter.filterValues.map((item) => {
        isValueValid =
          regexNumberValidator.test(item.value) &&
          item.value < 0x7fffffffffffffffn;
      });
      break;
    case 13:
      filter.filterValues.map((item) => {
        isValueValid =
          regexDecimalValidator.test(item.value) &&
          item.value < 0x9ffffffffffffffffffffffffn;
      });
      break;
    case 17:
    case 18:
      filter.filterValues.map((item) => {
        isValueValid =
          regexDecimalValidator.test(item.value) &&
          item.value <= Number.MAX_VALUE &&
          item.value >= Number.MIN_VALUE;
      });
      break;
    default:
      isValueValid = true;
  }

  return isValueValid;
}
