import Filter from "./Filter";
import Widget from "./Widget";

class Dashboard {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.widgets = data?.widgets?.map((widget) => new Widget(widget));
    this.userId = "string";
    this.predefinedGlobalFilterLogicalOperator =
      data.predefinedGlobalFilterLogicalOperator || 1;
    this.predefinedGlobalFilters = data.predefinedGlobalFilters
      ? data.predefinedGlobalFilters.map((filter) => new Filter(filter))
      : [];
  }
}

export default Dashboard;
