export default {
  getWidgetTypes: (state) => {
    return state.widgetTypes;
  },

  getSelectedItem: (state) => {
    return state.selectedItem;
  },
  getShowSideBar: (state) => {
    return state.showSideBar;
  },

  getLayoutArray: (state) => {
    return state.layout;
  },

  getInitialLineData: (state) => {
    return state.lineData;
  },

  getMouseXY: (state) => {
    return state.mouseXY;
  },

  getDragPos: (state) => {
    return state.DragPos;
  },

  getDashboardsData: (state) => {
    return state.dashboardsData;
  },

  getSelectedDashboard: (state) => {
    return state.selectedDashboard;
  },

  getTableList: (state) => {
    return state.tableList;
  },

  getFieldsList: (state) => {
    return state.fieldsList;
  },

  getFieldsWithoutAggregation: (state) => {
    return state.fieldsWithoutAggregations;
  },

  getBasicFieldsList: (state) => {
    return state.basicFieldsList;
  },

  getSelectedFields: (state) => {
    return state.selectedFieldList;
  },

  getFilterParameters: (state) => {
    return state.filterParameters;
  },

  getChartColors: (state) => {
    return state.chartColors;
  },

  getFilterOperators: (state) => {
    return state.filterOperators;
  },

  getPropertiesList: (state) => {
    return state.properties;
  },

  getPropertiesSelectedList: (state) => {
    return state.propertiesSelected;
  },

  getSelectedDates: (state) => {
    return state.selectedDates;
  },

  getEmbedFilters: (state) => {
    return state.embedFilters;
  },

  getDashboard: (state) => {
    return state.dashboard;
  },

  getGlobalFilters: (state) => {
    return state.globalFilters;
  },

  getFilteredDashboardParameters: (state) => {
    return state.filteredDashboardParameters;
  },

  getDashboardTableIds: (state) => {
    return state.dashboardTableIds;
  },

  getIsDashobardFullScreen: (state) => {
    return state.isDashobardFullScreen;
  },

  getFullScreenWidget: (state) => {
    return state.fullScreenWidget;
  },

  getCustomRangeDate: (state) => {
    return state.customRangeDate;
  },

  getDashboardAutoRefresh: (state) => {
    return state.dashboardAutoRefresh;
  },
  getDashboardHash: (state) => {
    return state.dashboardHash;
  },
  getRefreshDashboardData: (state) => {
    return state.refreshDashboardData;
  },

  getShareDetails: (state) => state.shareDetails,

  getShareToDelete: (state) => state.shareToDelete,

  getSharedDashboardName: (state) => state.dashboardName,

  getDashboardTemplates: (state) => state.dashboardTemplates,

  getEnabledActions: (state) => state.actionsEnabled,

  getCanModifyShare: (state) => state.canModifyShare,

  getDashboardConfigs: (state) => {
    return state.dashboardConfigs;
  },
  getEmbedConfig: (state) => {
    return state.embedConfig;
  },

  getAiConfigurations: (state) => state.aiConfigurations,

  getDashboardList: (state) => state.dashboardList,

  getDashboardShareData: (state) => state.dashboardSharingData,
};
