<template>
  <div v-if="!loading" class="dashboard-overview-header">
    <div class="dashboard-title__container">
      <b-button
        type="button"
        :to="{
          name: 'dashboard',
          params: {
            organizationId,
          },
        }"
        class="btn btn-with-icon btn-back"
      >
        <GjIcon name="ArrowLeftAlt" class="icon" size="22" />
      </b-button>
      <div v-if="!loading" class="title__section">
        <span class="dashboard-name__txt">Dashboard name</span>
        <v-select
          v-if="dashboardList"
          v-model="dashboard"
          :options="dashboardList"
          :clearable="false"
          :getOptionLabel="(item) => item.name"
          class="dashboard-list__dropdown"
          :loading="loadingDashboard"
          @input="changeDashboard"
        />
      </div>
    </div>
    <div v-if="headerOnView" class="btn-group-items">
      <div class="header-button__wrapper">
        <b-button
          variant="outline-light"
          :disabled="dashboard?.name === null || !enabledActions?.canModify"
          :to="
            enabledActions?.canModify
              ? {
                  name: 'dashboard-edit',
                  params: {
                    organizationId,
                    id: dashboard.id,
                  },
                }
              : {}
          "
        >
          <GjIcon name="Edit" class="icon" size="18" />
          Edit
        </b-button>
      </div>
      <div class="header-button__wrapper">
        <b-button
          variant="outline-danger"
          class="btn btn-danger-hover"
          :disabled="dashboard?.name === null || !enabledActions?.canModify"
          v-b-modal="enabledActions?.canModify ? 'deleteDashboardModal' : ''"
        >
          <GjIcon name="Delete" class="icon" size="18" />
          Delete
        </b-button>
      </div>
      <div class="header-button__wrapper">
        <b-button
          variant="outline-light"
          :disabled="dashboard?.name === null"
          @click="makeDashboardFullScreen"
        >
          <GjIcon name="Fullscreen" class="icon" size="18" />
          Full Screen
        </b-button>
      </div>
      <DropdownActions
        class="extra-actions"
        :id="'dashboard-exta-actions'"
        :items="dropdownActions"
      />
    </div>
    <div v-else class="btn-group-items">
      <div class="header-button__wrapper">
        <b-button
          variant="outline-light"
          :disabled="!canModifyShare"
          :to="{
            name: 'create-share',
            params: { dashboardId: dashboard?.id, organizationId },
          }"
        >
          <GjIcon name="Plus_fill" class="icon" size="18" />
          Create
        </b-button>
      </div>
      <div class="header-button__wrapper view-dashboard__btn">
        <b-button
          variant="outline-light"
          :disabled="dashboard?.id === null"
          :to="{
            name: 'dashboard-view',
            params: { id: dashboard?.id, organizationId },
          }"
        >
          <GjIcon name="Grid" class="icon" size="18" />
          View Dashboard
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { DropdownActions, GjIcon } from '@nodus/utilities-front'
import { BButton } from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import { VueSelect } from 'vue-select'

export default {
  components: {
    DropdownActions,
    'v-select': VueSelect,
    GjIcon,
    BButton,
  },
  props: {
    headerType: {
      type: String,
      required: true,
    },
  },
  async beforeRouteEnter(to, __, next) {
    if (store.getters.getEnabledActions === null) {
      await store.dispatch('getDashboards', {
        organizationId: to.params.organizationId,
      })
    }
    if (store.getters.getEnabledActions?.canViewShared) next()
    else next('/error/401')
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { organizationId, dashboardId } = vm.$route.params
    const id = computed(() => vm.$route.params.id)
    const dashboardList = computed(() => store.getters.getDashboardList)
    const enabledActions = computed(() => store.getters.getEnabledActions)
    const headerOnView = computed(() => props.headerType === 'view')
    const canModifyShare = computed(() => store.getters.getCanModifyShare)
    const loadingDashboard = ref(false)
    const dashboard = ref(null)
    const loading = ref(true)
    const dropdownActions = ref(null)

    onMounted(async () => {
      const idToLoop = headerOnView.value ? id.value : dashboardId
      await store.dispatch('getDashboardDropdownList', { organizationId })
      const dashboardObj = dashboardList?.value?.find(
        (item) => item.id === idToLoop
      )
      dashboard.value = dashboardObj

      dropdownActions.value = [
        {
          id: 'share-dashboard',
          icon: 'Share',
          text: 'Shared',
          disabled: !enabledActions?.value?.canShare,
          to: {
            name: 'dashboard-share-list',
            params: {
              dashboardId: id,
              organizationId,
            },
          },
        },
        {
          id: 'export-dashboard',
          icon: 'ArrowExportPopout',
          text: 'Export',
          action: () => vm.$emit('export-dashboard'),
        },
      ]
      loading.value = false
    })

    const changeDashboard = (dashboard) => {
      loadingDashboard.value = true
      if (headerOnView.value) {
        vm.$emit('change-dashboard', dashboard.id)
      } else {
        vm.$emit('change-dashboard-list', dashboard.id)
      }
      loadingDashboard.value = false
    }

    const makeDashboardFullScreen = () => {
      store.commit('SET_FULL_SCREEN_WIDGET', null)
      store.commit('SET_IS_DASHBOARD_FULL_SCREEN', true)
    }

    return {
      organizationId,
      dashboardList,
      enabledActions,
      changeDashboard,
      makeDashboardFullScreen,
      dropdownActions,
      headerOnView,
      canModifyShare,
      dashboard,
      loadingDashboard,
      loading,
    }
  },
}
</script>
<style lang="scss">
@import '~@/assets/style/components/header.scss';
@import '~@/assets/style/views/dashboard-overview.scss';
</style>
