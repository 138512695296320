<template>
  <div
    class="message-pannel"
    :style="{ background: backgroundColor, borderColor: borderColor }"
  >
    <GjIcon
      :name="iconName"
      :size="iconSize || '24'"
      :color="iconColor || null"
    />
    <p v-html="text" :style="{ color: textColor, fontSize: textSize }"></p>
  </div>
</template>

<script>
import { GjIcon } from "@nodus/utilities-front";

export default {
  props: {
    backgroundColor: {
      type: String,
    },
    borderColor: {
      type: String,
    },
    iconName: {
      type: String,
    },
    iconColor: {
      type: String,
    },
    iconSize: {
      type: String,
    },
    text: {
      type: String,
    },
    textColor: {
      type: String,
    },
    textSize: {
      type: String,
      default: "14px",
    },
  },
  components: {
    GjIcon,
  },
};
</script>

<style lang="scss">
.message-pannel {
  width: 100%;
  padding: 12px;
  display: flex;
  gap: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;

  p {
    color: $color-main-text;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 0;

    b {
      font-weight: 500;
    }
  }
}
</style>
