var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "p-0 w-100 date-picker-component",
      attrs: {
        id: _vm.isOnShare ? "date-picker-share" : "date-picker-view",
        cols: "12",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "date-picker-input__holder",
          class: _vm.isDisabled ? "date-picker__disabled" : null,
          on: { click: _vm.openDatePickerModal },
        },
        [
          _c("b-input", {
            staticClass:
              "w-100 justify-content-between text-sm text-main border-0",
            attrs: {
              "read-only": "",
              value: _vm.formattedDate,
              disabled: _vm.isDisabled,
            },
          }),
          _c("img", {
            attrs: {
              src: require(`@/assets/img/date-picker-icon.svg`),
              alt: "Close icon",
            },
          }),
        ],
        1
      ),
      _vm.toggleDate
        ? _c(
            "div",
            { ref: "date-picker-modal", staticClass: "date-picker-modal" },
            [
              !_vm.isOnShare
                ? _c(
                    "div",
                    { staticClass: "date-picker__header" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-date-range text-nowrap align-items-center d-flex mb-05 text-primary text-secondary text-sm justify-content-between",
                        },
                        [
                          _vm._v(" Custom Date Range "),
                          _c("b-form-input", {
                            staticClass: "date-picker-input",
                            attrs: {
                              id: "custom-date-range-input",
                              readonly: "",
                              disabled: "",
                              placeholder: "dd/mm/YY - dd/mm/YY",
                            },
                            model: {
                              value: _vm.startDate,
                              callback: function ($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate",
                            },
                          }),
                          _vm._v(" to "),
                          _c("b-form-input", {
                            staticClass: "date-picker-input",
                            attrs: {
                              id: "custom-date-range-input-2",
                              readonly: "",
                              disabled: "",
                              placeholder: "dd/mm/YY - dd/mm/YY",
                            },
                            model: {
                              value: _vm.endDate,
                              callback: function ($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "bg-transparent border-0 pr-0",
                          attrs: { size: "sm" },
                          on: {
                            click: function ($event) {
                              return _vm.onCancelClick()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require(`@/assets/img/close-icon.svg`),
                              alt: "Close icon",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "date-picker__container d-flex" },
                [
                  _c("flat-pickr", {
                    ref: "datePicker",
                    staticClass:
                      "form-control flat-picker bg-transparent shadow-none date-picker-calendar",
                    attrs: { id: "date-picker-calendar", config: _vm.config },
                    on: { "on-change": _vm.onCalendarChange },
                    model: {
                      value: _vm.dateSelected,
                      callback: function ($$v) {
                        _vm.dateSelected = $$v
                      },
                      expression: "dateSelected",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "border-left mt-2 mb-2 pl-1 text-nowrap" },
                    _vm._l(_vm.customRangeDate, function (date) {
                      return _c(
                        "div",
                        {
                          key: date.id,
                          staticClass: "custom-range__holder",
                          class: [
                            _vm.selectCustomRangeDate == date.id
                              ? "highlightCustomDate"
                              : null,
                            date.disabled ? "range-disabled" : null,
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.selectedRangeDate(date)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "my-1 p-05 customRangePick" },
                            [_vm._v(" " + _vm._s(date.text) + " ")]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "date-picker__footer" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "m-05 mr-1 btn-height",
                      attrs: { variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.onCancelClick()
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "align-middle d-lg-inline-block" },
                        [_vm._v(" Cancel ")]
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-05 mr-1 btn-height",
                      attrs: { variant: "primary", "data-open": "" },
                      on: {
                        click: function ($event) {
                          return _vm.onApplyClick()
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "align-middle d-lg-inline-block" },
                        [_vm._v("Apply")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }