<template>
  <LoadingBar v-if="loading" class="loading-bar__wrapper" />
  <div v-else class="dashboards__container view-share-details create-wrapper">
    <div>
      <div class="share__header">
        <ShareHeader
          :title="typeIsShare ? 'Share Dashboard' : 'Embed Dashboard'"
          desc="These settings allow users to interact with the dashboard within these limits, while the shared dashboard's functionality for administrators remains unrestricted."
        />
        <button :disabled="!canModifyShare" @click="editShare" class="btn">
          <GjIcon name="Edit" size="18" color="#2c3e50" /> Edit
        </button>
      </div>
      <div class="share-spacer"></div>
      <div>
        <p class="config-title">{{ typeIsShare ? 'Link' : 'Embed Code' }}</p>
        <p v-if="typeIsShare" class="details-tag">
          <span>{{ data.shareUrl }}</span>
          <button @click="copyLink(data.shareUrl)" class="btn">
            <GjIcon name="Copy" color="#A3B0C2" size="18" />
          </button>
        </p>
        <div v-else class="embed-details__container input-width">
          <b-form-textarea
            disabled
            v-model="data.embedCode"
            no-resize
            rows="13"
            max-rows="13"
            class="embed-code__container"
          />
          <button @click="copyLink(data.embedCode)" class="btn embed-copy__btn">
            <GjIcon name="Copy" color="#A3B0C2" size="20" />
          </button>
        </div>
        <p class="config-title">Title</p>
        <span class="share-title__text">{{ data.name }}</span>
        <div class="share-spacer"></div>
        <p class="config-title">Properties</p>
        <div class="input-width tag__container">
          <span
            v-for="property in shareDetails.propertyIds"
            :key="property"
            class="detailed-tag"
            >{{ properties?.find((item) => item.id === property)?.name }}</span
          >
        </div>
        <MessagePanel
          background-color="#F6F8F9"
          border-color="#F6F8F9"
          icon-name="Info"
          icon-color="#7483A1"
          icon-size="18"
          :text="`User is <b style='color:#455A82;'>${
            shareDetails.enablePropertyFilter ? 'allowed' : 'not allowed'
          }</b> to select/unselect the shared properties.`"
          text-color="#7483A1"
          class="info__container"
        />
        <p class="config-title">Share data between</p>
        <span class="date__container">
          <GjIcon name="Calendar" color="#667C99" size="14" />
          {{ formatDate(shareDetails.startDate) }} -
          {{ formatDate(shareDetails.endDate) }}
        </span>
        <MessagePanel
          background-color="#F6F8F9"
          border-color="#F6F8F9"
          icon-name="Info"
          icon-color="#7483A1"
          icon-size="18"
          :text="`User is <b style='color:#455A82;'>${
            shareDetails.enableDateFilter ? 'allowed' : 'not allowed'
          }</b> to select different dates within the selected date range.`"
          text-color="#7483A1"
          class="info__container"
        />

        <p class="config-title">Filters included</p>
        <FilterTags
          v-if="shareDetails?.globalFilters?.length > 0"
          :filters="shareDetails.globalFilters"
          :tableId="tableId"
          :is-on-share="true"
          :are-disabled="true"
          :can-edit-tags="false"
          class="shared-filters__container"
        />
        <MessagePanel
          v-else
          background-color="#F6F8F9"
          border-color="#F6F8F9"
          icon-name="Info"
          icon-color="#7483A1"
          icon-size="18"
          text="No filters were included."
          text-color="#7483A1"
          class="info__container"
        />
        <div v-if="!typeIsShare">
          <div class="share-spacer"></div>
          <p class="config-title">Allowed Origins</p>
          <div
            v-if="data?.allowedOrigins?.length > 0"
            class="input-width tag__container"
          >
            <span
              v-for="(origin, index) in data.allowedOrigins"
              :key="index"
              class="detailed-tag"
              >{{ origin }}</span
            >
            <MessagePanel
              background-color="#F6F8F9"
              border-color="#F6F8F9"
              icon-name="Info"
              icon-color="#7483A1"
              text="Allowed Origins are URLs that
      will be allowed to make requests from JavaScript Clients to Bisko API
      (typically used with CORS). By default, none of callback URLs will be
      allowed. This field allows you to enter origins that you want to allow."
              class="input-width allowed-origins__message"
              text-color="#7483A1"
            />
          </div>
          <MessagePanel
            v-else
            background-color="#F6F8F9"
            border-color="#F6F8F9"
            icon-name="Info"
            icon-color="#7483A1"
            icon-size="18"
            text="No origins were allowed."
            text-color="#7483A1"
            class="info__container input-width tag__container"
          />
        </div>
      </div>
    </div>
    <Footer
      :viewMode="true"
      :cancel-id="`cancel-create-share`"
      :create-id="`add-share`"
      @back="cancel()"
    />
  </div>
</template>

<script>
import MessagePanel from '@/components/MessagePanel.vue'
import ShareHeader from '@/components/ShareHeader.vue'
import FilterTags from '@/components/global-filters/FilterTags.vue'
import store from '@/store'
import {
  Footer,
  GjIcon,
  LoadingBar,
  formatDate,
  showToast,
} from '@nodus/utilities-front'
import { useClipboard } from '@vueuse/core'
import { BFormTextarea } from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  components: {
    LoadingBar,
    GjIcon,
    ShareHeader,
    MessagePanel,
    FilterTags,
    Footer,
    BFormTextarea,
  },
  async beforeRouteEnter(to, __, next) {
    if (store.getters.getEnabledActions === null) {
      await store.dispatch('getDashboards', {
        organizationId: to.params.organizationId,
      })
    }
    if (store.getters.getEnabledActions?.canViewShared) next()
    else next('/error/401')
  },
  setup() {
    const vm = getCurrentInstance().proxy
    let { organizationId, dashboardId, id, isShare } = vm.$route.params
    const shareDetails = computed(() => store.getters.getShareDetails)
    const loading = ref(true)
    const { copy } = useClipboard()
    const data = ref()
    const properties = computed(() => store.getters.getPropertiesList)
    const tableId = 'dashboard'
    const typeIsShare = ref(isShare)
    const canModifyShare = computed(() => store.getters.getCanModifyShare)

    onMounted(async () => {
      await store.dispatch('getDashboardShareById', { organizationId, id })
      data.value =
        shareDetails.value.dashboardSharingUrl ||
        shareDetails.value.dashboardSharingEmbed
      store.commit('SET_DASHBOARD_TABLE_IDS', shareDetails.value.tableIds)
      if (canModifyShare.value === null) {
        await store.dispatch('getDashboardSharingList', {
          organizationId,
          id: dashboardId,
        })
      }

      if (!properties.value) {
        await store.dispatch('getProperties', { organizationId })
      }
      if (!isShare) {
        typeIsShare.value =
          data.value === shareDetails.value.dashboardSharingUrl
      }
      loading.value = false
    })

    const copyLink = (url) => {
      copy(url)
      showToast('success', 'Link copied!')
    }

    const cancel = () => {
      vm.$router.back()
    }

    const editShare = () => {
      if (canModifyShare.value) {
        vm.$router.push({
          name: 'edit-share',
          params: {
            organizationId,
            dashboardId,
            id: data.value.dashboardSharingId,
          },
        })
      }
    }

    return {
      organizationId,
      id,
      shareDetails,
      loading,
      copyLink,
      data,
      properties,
      formatDate,
      tableId,
      dashboardId,
      cancel,
      typeIsShare,
      canModifyShare,
      editShare,
    }
  },
}
</script>

<style lang="scss">
@import '~@/assets/style/views/create-share.scss';
@import '~@/assets/style/views/share-details.scss';
</style>
