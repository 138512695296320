var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("div", { staticClass: "dashboard-overview-header" }, [
        _c(
          "div",
          { staticClass: "dashboard-title__container" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn btn-with-icon btn-back",
                attrs: {
                  type: "button",
                  to: {
                    name: "dashboard",
                    params: {
                      organizationId: _vm.organizationId,
                    },
                  },
                },
              },
              [
                _c("GjIcon", {
                  staticClass: "icon",
                  attrs: { name: "ArrowLeftAlt", size: "22" },
                }),
              ],
              1
            ),
            !_vm.loading
              ? _c(
                  "div",
                  { staticClass: "title__section" },
                  [
                    _c("span", { staticClass: "dashboard-name__txt" }, [
                      _vm._v("Dashboard name"),
                    ]),
                    _vm.dashboardList
                      ? _c("v-select", {
                          staticClass: "dashboard-list__dropdown",
                          attrs: {
                            options: _vm.dashboardList,
                            clearable: false,
                            getOptionLabel: (item) => item.name,
                            loading: _vm.loadingDashboard,
                          },
                          on: { input: _vm.changeDashboard },
                          model: {
                            value: _vm.dashboard,
                            callback: function ($$v) {
                              _vm.dashboard = $$v
                            },
                            expression: "dashboard",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.headerOnView
          ? _c(
              "div",
              { staticClass: "btn-group-items" },
              [
                _c(
                  "div",
                  { staticClass: "header-button__wrapper" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "outline-light",
                          disabled:
                            _vm.dashboard?.name === null ||
                            !_vm.enabledActions?.canModify,
                          to: _vm.enabledActions?.canModify
                            ? {
                                name: "dashboard-edit",
                                params: {
                                  organizationId: _vm.organizationId,
                                  id: _vm.dashboard.id,
                                },
                              }
                            : {},
                        },
                      },
                      [
                        _c("GjIcon", {
                          staticClass: "icon",
                          attrs: { name: "Edit", size: "18" },
                        }),
                        _vm._v(" Edit "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "header-button__wrapper" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal",
                            value: _vm.enabledActions?.canModify
                              ? "deleteDashboardModal"
                              : "",
                            expression:
                              "enabledActions?.canModify ? 'deleteDashboardModal' : ''",
                          },
                        ],
                        staticClass: "btn btn-danger-hover",
                        attrs: {
                          variant: "outline-danger",
                          disabled:
                            _vm.dashboard?.name === null ||
                            !_vm.enabledActions?.canModify,
                        },
                      },
                      [
                        _c("GjIcon", {
                          staticClass: "icon",
                          attrs: { name: "Delete", size: "18" },
                        }),
                        _vm._v(" Delete "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "header-button__wrapper" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "outline-light",
                          disabled: _vm.dashboard?.name === null,
                        },
                        on: { click: _vm.makeDashboardFullScreen },
                      },
                      [
                        _c("GjIcon", {
                          staticClass: "icon",
                          attrs: { name: "Fullscreen", size: "18" },
                        }),
                        _vm._v(" Full Screen "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("DropdownActions", {
                  staticClass: "extra-actions",
                  attrs: {
                    id: "dashboard-exta-actions",
                    items: _vm.dropdownActions,
                  },
                }),
              ],
              1
            )
          : _c("div", { staticClass: "btn-group-items" }, [
              _c(
                "div",
                { staticClass: "header-button__wrapper" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-light",
                        disabled: !_vm.canModifyShare,
                        to: {
                          name: "create-share",
                          params: {
                            dashboardId: _vm.dashboard?.id,
                            organizationId: _vm.organizationId,
                          },
                        },
                      },
                    },
                    [
                      _c("GjIcon", {
                        staticClass: "icon",
                        attrs: { name: "Plus_fill", size: "18" },
                      }),
                      _vm._v(" Create "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "header-button__wrapper view-dashboard__btn" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-light",
                        disabled: _vm.dashboard?.id === null,
                        to: {
                          name: "dashboard-view",
                          params: {
                            id: _vm.dashboard?.id,
                            organizationId: _vm.organizationId,
                          },
                        },
                      },
                    },
                    [
                      _c("GjIcon", {
                        staticClass: "icon",
                        attrs: { name: "Grid", size: "18" },
                      }),
                      _vm._v(" View Dashboard "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }