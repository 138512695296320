<template>
  <div class="documentation-info" :class="customClass">
    <GjIcon name="Info" class="mr-50" size="18" />
    <span
      >{{ documentationText }}
      <a :href="documentationLink" target="_blank">documentation</a>.</span
    >
  </div>
</template>

<script>
import { GjIcon } from "@nodus/utilities-front";
export default {
  components: {
    GjIcon,
  },
  props: {
    documentationText: {
      type: String,
      default: "",
    },
    documentationLink: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.documentation-info {
  font-size: 13px;
  line-height: 18px;
  color: #7483a1;
  display: flex;
  text-align: left;
  margin-bottom: 16px;

  a {
    color: #052d61;
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      color: #052d61;
    }
  }

  svg {
    min-width: 16px;
  }
}

.create-dashboard-documentation {
  margin-bottom: 24px;
  align-items: center;
}
</style>
