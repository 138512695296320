import store from "@/store";

class Field {
  constructor(data, isNew) {
    this.id = isNew ? null : data.id;
    this.columnId = isNew ? data.id : data.columnId;
    this.label = data.label;
    this.name = data.name;
    this.aggregationType = data.aggregationType;
    this.color = data.color || this.setRandomColor();
    this.forecast = data.forecast;
    this.forecastDateRange = data.forecastDateRange;
  }

  setRandomColor() {
    return store.state.visualizerModule.chartColors[
      Math.floor(
        Math.random() * store.state.visualizerModule.chartColors.length
      )
    ];
  }
}

export default Field;
