var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.widget
    ? _c(
        "div",
        { staticClass: "side-bar" },
        [
          _c(
            "div",
            { staticClass: "side-bar__holder", attrs: { role: "tablist" } },
            [
              _c("div", { staticClass: "title__header" }, [
                _c("h1", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.widgetTypes.find(
                          (item) => item.id === _vm.widget.widgetType
                        ).name
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "close-config-btn",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.closeConfiguration()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "back-arrow__icon",
                      attrs: {
                        src: require(`@/assets/img/widget-menu-icon.svg`),
                      },
                    }),
                  ]
                ),
              ]),
              _c(
                "b-tabs",
                { staticClass: "widget__tabs" },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { value: "configuration", active: "" },
                      on: {
                        click: function ($event) {
                          return _vm.handleActiveTab(0)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "back-arrow__icon",
                                  attrs: {
                                    src: require(`@/assets/img/settings-icon.svg`),
                                    title:
                                      _vm.activeTab !== _vm.CONFIGURATION_VALUE
                                        ? "Configuration"
                                        : "",
                                  },
                                }),
                                _vm.activeTab === _vm.CONFIGURATION_VALUE
                                  ? _c("span", [_vm._v("Configuration")])
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3620392293
                      ),
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "configurations__holder" },
                        [
                          _c("DocumentationLink", {
                            attrs: {
                              "documentation-text":
                                "To configure a widget, follow the steps described in the",
                              "documentation-link": `${_vm.getDocumentationLink()}/widgets`,
                            },
                          }),
                          _c("p", { staticClass: "config__title" }, [
                            _vm._v("Title"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.widget.title,
                                expression: "widget.title",
                              },
                            ],
                            staticClass: "config__input",
                            attrs: {
                              type: "text",
                              placeholder: "Enter title here...",
                            },
                            domProps: { value: _vm.widget.title },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.widget,
                                    "title",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.clearWidgetTitleError()
                                },
                              ],
                            },
                          }),
                          !!_vm.widget.titleError
                            ? _c(
                                "p",
                                { staticClass: "aggregation-error__text" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.widget.titleError) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "configuration__items" },
                            [
                              _c("div", { staticClass: "spacer" }),
                              _vm.checkWidgetType(
                                _vm.widgetTypes,
                                _vm.widget,
                                "Bar Chart"
                              )
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "extra-bar-configs__container",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "bar-stacked__config" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "config__title" },
                                            [_vm._v("Stacked bar")]
                                          ),
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              switch: "",
                                              size: "md",
                                              id: "checkbox-1",
                                              name: "checkbox-1",
                                            },
                                            model: {
                                              value: _vm.widget.stacked,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.widget,
                                                  "stacked",
                                                  $$v
                                                )
                                              },
                                              expression: "widget.stacked",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "bar-stacked__config" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "config__title" },
                                            [_vm._v("Group by date")]
                                          ),
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              switch: "",
                                              size: "md",
                                              id: "checkbox-2",
                                              name: "checkbox-2",
                                            },
                                            model: {
                                              value: _vm.widget.groupByDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.widget,
                                                  "groupByDate",
                                                  $$v
                                                )
                                              },
                                              expression: "widget.groupByDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("p", { staticClass: "config__title" }, [
                                _vm._v("Select Table"),
                              ]),
                              _vm.tableOptions
                                ? _c("v-select", {
                                    ref: "tableSelect",
                                    attrs: {
                                      clearable: false,
                                      options: _vm.checkWidgetType(
                                        _vm.widgetTypes,
                                        _vm.widget,
                                        "Map Chart"
                                      )
                                        ? _vm.tableOptions.filter(
                                            (item) =>
                                              item.hasGeoLocationCountry ===
                                              true
                                          )
                                        : _vm.tableOptions,
                                      getOptionLabel: (x) => x.name,
                                      placeholder: "Select",
                                      label: "name",
                                    },
                                    on: { input: _vm.tableSelected },
                                    model: {
                                      value: _vm.selectedTable,
                                      callback: function ($$v) {
                                        _vm.selectedTable = $$v
                                      },
                                      expression: "selectedTable",
                                    },
                                  })
                                : _vm._e(),
                              _c("div", { staticClass: "spacer" }),
                              _vm.selectedTable
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "config__title" },
                                        [_vm._v("Select field(s)")]
                                      ),
                                      _vm.fieldsWithoutAggregations
                                        ? _c(
                                            "Multiselect",
                                            {
                                              ref: "multi-select-fields",
                                              staticClass:
                                                "fields__multiselect fields__multiselect-only",
                                              attrs: {
                                                options:
                                                  _vm.fieldsWithoutAggregations,
                                                multiple: true,
                                                "close-on-select": false,
                                                "clear-on-select": false,
                                                "preserve-search": false,
                                                selectLabel: "",
                                                selectGroupLabel: "",
                                                deselectGroupLabel: "",
                                                deselectLabel: "",
                                                selectedLabel: "",
                                                placeholder:
                                                  "Search for fields...",
                                                label: "name",
                                                "track-by": "columnId",
                                              },
                                              on: {
                                                select: _vm.selectField,
                                                remove: _vm.deleteField,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "option",
                                                    fn: function (props) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-between",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    props.option
                                                                      .name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                            (
                                                              _vm.selectedFieldList
                                                                ? _vm.selectedFieldList.find(
                                                                    (field) =>
                                                                      field?.columnId ===
                                                                      props
                                                                        .option
                                                                        .columnId
                                                                  )
                                                                : false
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "mr-50",
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      attrs: {
                                                                        src: require(`@/assets/img/check-icon.svg`),
                                                                        alt: "Check icon",
                                                                      },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2876434513
                                              ),
                                              model: {
                                                value: _vm.selectedFieldList,
                                                callback: function ($$v) {
                                                  _vm.selectedFieldList = $$v
                                                },
                                                expression: "selectedFieldList",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "selection" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "fields__placeholder",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require(`@/assets/img/plus-icon.svg`),
                                                          alt: "Plus icon",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        " Select field(s) "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _c("div", { staticClass: "spacer" }),
                                      _vm.selectedFieldList?.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "aggregation-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "aggregation__text-container",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "config__title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Select aggregation for each"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      class:
                                                        _vm.widget.fields
                                                          .length >
                                                        _vm.widget
                                                          .maxAggregation
                                                          ? "fields-error__text"
                                                          : "fields-info__text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.widget.fields
                                                              .length
                                                          ) +
                                                          "/" +
                                                          _vm._s(
                                                            _vm.widget
                                                              .maxAggregation
                                                          ) +
                                                          " selected "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.selectedFieldList,
                                                function (field) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: field.id,
                                                      staticClass:
                                                        "aggregation-item",
                                                    },
                                                    [
                                                      _vm.fieldsList[
                                                        _vm.widget.tableId
                                                      ]
                                                        ? _c(
                                                            "Multiselect",
                                                            {
                                                              staticClass:
                                                                "fields__multiselect",
                                                              attrs: {
                                                                options:
                                                                  _vm.fieldsList[
                                                                    _vm.widget
                                                                      .tableId
                                                                  ].find(
                                                                    (item) =>
                                                                      item.id ===
                                                                      field.columnId
                                                                  )
                                                                    ?.aggregationFunctions,
                                                                label: "name",
                                                                multiple: true,
                                                                placeholder: "",
                                                                "close-on-select": false,
                                                                "clear-on-select": false,
                                                                "preserve-search": true,
                                                                toggle: true,
                                                                searchable: false,
                                                                selectLabel: "",
                                                                selectGroupLabel:
                                                                  "",
                                                                deselectGroupLabel:
                                                                  "",
                                                                deselectLabel:
                                                                  "",
                                                                selectedLabel:
                                                                  "",
                                                                "track-by":
                                                                  "label",
                                                              },
                                                              on: {
                                                                select:
                                                                  _vm.addAggregation,
                                                                remove:
                                                                  _vm.removeAggregation,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "option",
                                                                      fn: function (
                                                                        props
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex justify-content-between",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        props
                                                                                          .option
                                                                                          .name
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              (
                                                                                _vm
                                                                                  .widget
                                                                                  .fields
                                                                                  ? _vm.widget.fields.some(
                                                                                      (
                                                                                        field
                                                                                      ) =>
                                                                                        _vm.readyFunctions?.includes(
                                                                                          field.name
                                                                                        )
                                                                                          ? field?.columnId ===
                                                                                            props
                                                                                              .option
                                                                                              .columnId
                                                                                          : field?.columnId ===
                                                                                              props
                                                                                                .option
                                                                                                .columnId &&
                                                                                            field.aggregationType ===
                                                                                              props
                                                                                                .option
                                                                                                .aggregationType
                                                                                    )
                                                                                  : false
                                                                              )
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-50",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              src: require(`@/assets/img/check-icon.svg`),
                                                                                              alt: "Check icon",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                              model: {
                                                                value:
                                                                  _vm.widget
                                                                    .fields,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.widget,
                                                                      "fields",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "widget.fields",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "selection",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "aggregation__placeholder",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.fieldsList[
                                                                                  _vm
                                                                                    .widget
                                                                                    .tableId
                                                                                ].find(
                                                                                  (
                                                                                    item
                                                                                  ) =>
                                                                                    item.id ===
                                                                                    field.columnId
                                                                                )
                                                                                  .groupName
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "aggregation__length",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " (" +
                                                                                  _vm._s(
                                                                                    _vm.widget.fields.filter(
                                                                                      (
                                                                                        item
                                                                                      ) =>
                                                                                        item.columnId ===
                                                                                        field.columnId
                                                                                    )
                                                                                      .length ===
                                                                                      1
                                                                                      ? _vm.widget.fields.find(
                                                                                          (
                                                                                            item
                                                                                          ) =>
                                                                                            item.columnId ===
                                                                                            field.columnId
                                                                                        )
                                                                                          ?.name
                                                                                      : _vm.widget.fields.filter(
                                                                                          (
                                                                                            item
                                                                                          ) =>
                                                                                            item.columnId ===
                                                                                            field.columnId
                                                                                        )
                                                                                          .length
                                                                                  ) +
                                                                                  ") "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "delete-filter btn-delete",
                                                                          attrs:
                                                                            {
                                                                              title:
                                                                                "Delete",
                                                                              type: "button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.isFieldUsedForForecast(
                                                                                  field
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "GjIcon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Delete",
                                                                                  size: "14",
                                                                                  color:
                                                                                    "#E34850",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              _vm.widget.fields.length >
                                              _vm.widget.maxAggregation
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "aggregation-error__text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Aggregation function limit (" +
                                                          _vm._s(
                                                            _vm.widget
                                                              .maxAggregation
                                                          ) +
                                                          ") has been reached. "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm.showGroupBy(_vm.widget.widgetType) &&
                                      _vm.basicFieldsList[_vm.widget.tableId]
                                        ? _c(
                                            "div",
                                            [
                                              _vm.fieldsWithoutAggregations
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "config__title",
                                                    },
                                                    [_vm._v(" Group by ")]
                                                  )
                                                : _vm._e(),
                                              _vm.parameters
                                                ? _c("v-select", {
                                                    attrs: {
                                                      clearable: true,
                                                      options:
                                                        _vm.basicFieldsList[
                                                          _vm.widget.tableId
                                                        ],
                                                      getOptionLabel: (x) =>
                                                        x.name,
                                                      label: "name",
                                                      placeholder: "Select",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.widget.groupByField,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.widget,
                                                          "groupByField",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "widget.groupByField",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.widget.groupByField &&
                                              _vm.widget.groupByField.type ===
                                                _vm.columnTypes.KEY_VALUE_PAIR
                                                ? [
                                                    _c("div", {
                                                      staticClass: "spacer",
                                                    }),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "config__title",
                                                      },
                                                      [_vm._v("Group by key *")]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.widget
                                                              .groupByFieldKey,
                                                          expression:
                                                            "widget.groupByFieldKey",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "config__input",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Enter group by key",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.widget
                                                            .groupByFieldKey,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.widget,
                                                            "groupByFieldKey",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.widget.widgetType ===
                  _vm.widgetTypes.find((item) => item.name === "Line Chart").id
                    ? _c(
                        "b-tab",
                        {
                          attrs: {
                            value: "forecast",
                            disabled:
                              !_vm.aiConfigurations.forecastingIsEnabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleActiveTab(1)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      staticClass: "back-arrow__icon",
                                      attrs: {
                                        src: require(`@/assets/img/forecast.svg`),
                                        title:
                                          _vm.activeTab !== _vm.FORECAST_VALUE
                                            ? "Forecasting"
                                            : "",
                                      },
                                    }),
                                    _vm.activeTab === _vm.FORECAST_VALUE
                                      ? _c("span", [_vm._v("Forecasting")])
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2879101245
                          ),
                        },
                        [
                          _c("div", { staticClass: "configurations__holder" }, [
                            _vm.widget?.fields?.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _c("p", { staticClass: "config__title" }, [
                                      _vm._v("Select Field"),
                                    ]),
                                    _c("v-select", {
                                      attrs: {
                                        clearable: true,
                                        options: _vm.widget.fields,
                                        getOptionLabel: (x) => x?.label,
                                        placeholder: "Select",
                                        label: "name",
                                      },
                                      on: { input: _vm.applyForecast },
                                      model: {
                                        value: _vm.selectedForecast,
                                        callback: function ($$v) {
                                          _vm.selectedForecast = $$v
                                        },
                                        expression: "selectedForecast",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "tabs-empty-state__container",
                                  },
                                  [
                                    _c("h6", [
                                      _vm._v(
                                        "No configurations for this widget"
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        " Select the table and fields for this widget to configure the data you would like to display. "
                                      ),
                                    ]),
                                  ]
                                ),
                            _c("div", { staticClass: "spacer" }),
                            _vm.selectedForecast
                              ? _c(
                                  "div",
                                  [
                                    _c("p", { staticClass: "config__title" }, [
                                      _vm._v("Select date range"),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      { staticClass: "forecast-range" },
                                      [
                                        _c("b-form-radio-group", {
                                          attrs: {
                                            options: _vm.forecastRanges,
                                          },
                                          on: {
                                            change: _vm.changeForecastDateRange,
                                          },
                                          model: {
                                            value:
                                              _vm.selectedForecast
                                                .forecastDateRange,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.selectedForecast,
                                                "forecastDateRange",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedForecast.forecastDateRange",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-tab",
                    {
                      attrs: { value: "filter" },
                      on: {
                        click: function ($event) {
                          return _vm.handleActiveTab(2)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "back-arrow__icon",
                                  attrs: {
                                    src: require(`@/assets/img/filter-icon.svg`),
                                    title:
                                      _vm.activeTab !== _vm.FILTER_VALUE
                                        ? "Filter by"
                                        : "",
                                  },
                                }),
                                _vm.activeTab === _vm.FILTER_VALUE
                                  ? _c("span", [_vm._v("Filter by")])
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1346669408
                      ),
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "configurations__holder" },
                        [
                          _vm.widget.filters.length !== 0 && _vm.widget.tableId
                            ? _c("ConfigurationFilter", {
                                attrs: {
                                  filters: _vm.widget.filters,
                                  "has-forecast":
                                    _vm.widget.settings.forecastStatus &&
                                    _vm.widget.settings.forecastStatus !== 1,
                                  globalDisabled: false,
                                  operator: _vm.widget.logicalOperator,
                                  "table-id": _vm.widget.tableId,
                                },
                                on: {
                                  "update:operator": (value) =>
                                    (_vm.widget.logicalOperator = value),
                                },
                              })
                            : _vm.widget.tableId
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "tabs-empty-state__container no-outline",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn",
                                      attrs: { type: "button" },
                                      on: { click: _vm.addFilter },
                                    },
                                    [
                                      _c("GjIcon", {
                                        attrs: {
                                          name: "Plus_fill",
                                          size: "14",
                                        },
                                      }),
                                      _vm._v(" Create new "),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "tabs-empty-state__container" },
                                [
                                  _c("h6", [
                                    _vm._v("No configurations for this widget"),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " Select the table and fields for this widget to configure the data you would like to display. "
                                    ),
                                  ]),
                                ]
                              ),
                        ],
                        1
                      ),
                    ]
                  ),
                  !_vm.checkWidgetType(_vm.widgetTypes, _vm.widget, "Card")
                    ? _c(
                        "b-tab",
                        {
                          staticClass: "configurations__holder",
                          attrs: { value: "display" },
                          on: {
                            click: function ($event) {
                              return _vm.handleActiveTab(3)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      staticClass: "back-arrow__icon",
                                      attrs: {
                                        src: require(`@/assets/img/display-icon.svg`),
                                        title:
                                          _vm.activeTab !== _vm.DISPLAY_VALUE
                                            ? "Display"
                                            : "",
                                      },
                                    }),
                                    _vm.activeTab === _vm.DISPLAY_VALUE
                                      ? _c("span", [_vm._v("Display")])
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2268841082
                          ),
                        },
                        [
                          _vm.widget?.fields.length > 0
                            ? _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.widget?.fields,
                                    function (field, index) {
                                      return _c("color-picker", {
                                        key: index,
                                        attrs: {
                                          color: field?.color,
                                          title: field?.label,
                                          type: _vm.widget.widgetType,
                                          name: field?.name,
                                          showColor:
                                            !_vm.checkWidgetType(
                                              _vm.widgetTypes,
                                              _vm.widget,
                                              "Table"
                                            ) &&
                                            !_vm.checkWidgetType(
                                              _vm.widgetTypes,
                                              _vm.widget,
                                              "Map Chart"
                                            )
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          "update:label": (value) =>
                                            _vm.updateLabel(
                                              value,
                                              field,
                                              _vm.widget
                                            ),
                                          "update:color": (value) => {
                                            _vm.updateColor(value, field)
                                          },
                                        },
                                      })
                                    }
                                  ),
                                  _vm.checkWidgetType(
                                    _vm.widgetTypes,
                                    _vm.widget,
                                    "Map Chart"
                                  )
                                    ? _c(
                                        "div",
                                        [
                                          _c("color-picker", {
                                            attrs: {
                                              color:
                                                _vm.widget.settings.maxColor,
                                              title: "Max Color",
                                              type: _vm.widget.widgetType,
                                              showColor: true,
                                              name: "Max Color",
                                            },
                                            on: {
                                              "update:color": (colorValue) => {
                                                _vm.updateColor(
                                                  colorValue,
                                                  "maxColor"
                                                )
                                              },
                                            },
                                          }),
                                          _c("color-picker", {
                                            attrs: {
                                              color:
                                                _vm.widget.settings.minColor,
                                              title: "Min Color",
                                              type: _vm.widget.widgetType,
                                              showColor: true,
                                              name: "Min Color",
                                            },
                                            on: {
                                              "update:color": (colorValue) => {
                                                _vm.updateColor(
                                                  colorValue,
                                                  "minColor"
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                { staticClass: "tabs-empty-state__container" },
                                [
                                  _c("h6", [
                                    _vm._v("No configurations for this widget"),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " Select the table and fields for this widget to configure the data you would like to display. "
                                    ),
                                  ]),
                                ]
                              ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn__container" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "outline-primary",
                    disabled:
                      _vm.widget.fields.length === 0 ||
                      _vm.widget.fields.length > _vm.widget.maxAggregation ||
                      (_vm.widget.groupByField &&
                        _vm.widget.groupByField.type ===
                          _vm.columnTypes.KEY_VALUE_PAIR &&
                        !_vm.widget.groupByFieldKey),
                  },
                  on: { click: _vm.visualizeData },
                },
                [_vm._v("Visualize Data")]
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "forecast-warning",
              attrs: {
                id: "forecast-warning",
                "modal-class": "forecast-warning__modal",
                "ok-title": "Remove anyway",
                "ok-variant": "danger",
                centered: "",
              },
              on: {
                ok: function ($event) {
                  return _vm.deleteField(null)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-header",
                    fn: function ({ close }) {
                      return [
                        _c("span", [_vm._v("Remove Field")]),
                        _c(
                          "button",
                          {
                            staticClass: "bg-transparent border-0",
                            attrs: { size: "sm" },
                            on: {
                              click: function ($event) {
                                return close()
                              },
                            },
                          },
                          [_c("GjIcon", { attrs: { name: "Close" } })],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                4220618380
              ),
            },
            [
              _c("div", { staticClass: "forecast-warning__contanier" }, [
                _c("p", [
                  _vm._v(" Forecasting relies on the "),
                  _c("span", [_vm._v(_vm._s(_vm.fieldToDelete.label))]),
                  _vm._v(
                    " field, and deselecting it will disable the forecasting process. "
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }