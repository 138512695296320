class Filter {
  constructor(data) {
    this.id = data.id;
    this.columnId = data.columnId;
    this.operator = data.operator;
    this.filterValues = data.filterValues.map(
      (value) => new FilterValue(value)
    );
    this.tempFilterValues = this.filterValues.map((x) => x.value);
    this.invalid = false;
    this.validValue = true;
    this.customLabel = data.customLabel;
    this.hidden = false;
  }
}

class FilterValue {
  constructor(data) {
    this.key = data.key;
    this.value = data.value;
  }
}

export default Filter;
