<template>
  <div class="filter-container" v-if="tableId">
    <MessagePanel
      v-if="!isModal && hasForecast"
      backgroundColor="linear-gradient(0deg, #FDF6EC, #FDF6EC),
    linear-gradient(0deg, #FADBB6, #FADBB6)"
      borderColor="#FADBB6"
      iconName="Warning"
      iconColor="#EF8C13"
      text=" Adding, removing or modifying existing filters will recalculate
        forecasting."
    />
    <div class="main-filter">
      <p class="main-filter-title">With</p>
      <v-select
        class="parameter-dropdown"
        v-model="lOperator"
        :options="operatorSelect"
        :clearable="false"
        :getOptionLabel="(x) => x.text"
        placeholder="Select"
        :disabled="globalDisabled"
      />
      <p class="main-filter-title">of the following parameters</p>
    </div>
    <div
      v-for="(filter, index) in filters"
      v-bind:key="index"
      :id="filter.id"
      class="filter-holder"
    >
      <p
        class="operator-title"
        :style="{ visibility: index > 0 ? 'visible' : 'hidden' }"
      >
        {{ operator === 1 ? "AND" : "OR " }}
      </p>
      <div
        class="filter-holder__wrapper"
        :class="filter.invalid && !isModal ? 'filter-is-invalid' : null"
      >
        <div class="filter-holder__inputs">
          <div class="parameter-dropdown">
            <v-select
              v-if="parameters[tableId]"
              :key="filter.id"
              v-model="filter.columnId"
              :selectable="(option) => !option.isParent"
              :options="
                isModal
                  ? activeFilterSearch === index
                    ? filteredParameters
                    : duplicatedParametersArr
                  : parameters[tableId].parameters
              "
              :reduce="(option) => option.columnId"
              :clearable="false"
              :loading="loading"
              :disabled="loading || globalDisabled"
              :filterable="isModal ? false : true"
              label="parameterName"
              placeholder="Parameters"
              :class="
                filter.invalid && filter.columnId === null && isModal
                  ? 'filter-is-invalid'
                  : null
              "
              @input="clearFilter(filter)"
              @search="isModal ? searchParameters($event, index) : {}"
            >
              <template #spinner="{ loading }">
                <LoadingBar v-if="loading" classList="small mr-1" />
              </template>
            </v-select>
            <span
              v-if="filter.invalid && filter.columnId === null && isModal"
              class="invalid-text"
              >Selected parameter doesn't exist!
            </span>
          </div>
          <div
            v-if="getOperators(filter.columnId) === columnTypes.KEY_VALUE_PAIR"
          >
            <b-form-input
              v-model="filter.tempFilterKey"
              placeholder="Key"
              @input="clearFilterInvalid(filter)"
              @change="updateFilterValuesKey(filter)"
              class="filter-key__input simple-filter__input"
              :class="
                filter.invalid && !filter.tempFilterKey
                  ? 'filter-is-invalid'
                  : null
              "
              :disabled="globalDisabled"
            >
            </b-form-input>
            <span
              v-if="filter.invalid && !filter.tempFilterKey && isModal"
              class="invalid-text"
              >Key is required!
            </span>
          </div>
          <div class="parameter-dropdown">
            <v-select
              v-if="operators"
              :key="filter.id + '-operator'"
              v-model="filter.operator"
              @input="
                clearFilterInvalid(
                  filter,
                  getOperators(filter.columnId) === columnTypes.DATE ||
                    getOperators(filter.columnId) === columnTypes.DATE_TIME
                )
              "
              :options="operators[getOperators(filter.columnId)]"
              :reduce="(option) => option.key"
              :clearable="false"
              :loading="loading"
              :disabled="loading || globalDisabled"
              placeholder="Select"
              label="value"
              :class="
                filter.invalid &&
                (filter.columnId === null || filter.operator === null) &&
                isModal
                  ? 'filter-is-invalid'
                  : null
              "
            >
              <template #spinner="{ loading }">
                <LoadingBar v-if="loading" classList="small mr-1" />
              </template>
            </v-select>
            <span
              v-if="
                filter.invalid &&
                (filter.columnId === null || filter.operator === null) &&
                isModal
              "
              class="invalid-text"
              >Operator is required!</span
            >
          </div>

          <div
            v-if="getOperators(filter.columnId) === columnTypes.BOOLEAN"
            class="parameter-value-container"
          >
            <v-select
              v-model="filter.tempFilterValues"
              :options="parametersBooleanValuesConst"
              :clearable="false"
              :searchable="false"
              @input="handleFilterValues(filter, true)"
              label="value"
              placeholder="Select"
              :class="
                filter.invalid && !filter.tempFilterValues.key && isModal
                  ? 'filter-is-invalid'
                  : null
              "
              :disabled="globalDisabled"
            />
            <span
              v-if="filter.invalid && !filter.tempFilterValues.key && isModal"
              class="invalid-text"
              >Parameter value is required! <br />
              Boolean parameter accepts only true or false!</span
            >
          </div>
          <div
            v-else-if="
              getOperators(filter.columnId) == columnTypes.DATE ||
              getOperators(filter.columnId) == columnTypes.DATE_TIME
            "
            class="parameter-value-container"
          >
            <flat-pickr
              v-if="filter.operator !== null"
              :key="filter.id + 'date-picker'"
              id="select-datepicker"
              ref="flatpickr"
              v-model="filter.tempFilterDate"
              class="form-control date"
              :class="
                filter.invalid && !filter.tempFilterDate && isModal
                  ? 'filter-is-invalid'
                  : null
              "
              :disabled="globalDisabled"
              :config="{
                ...calendarOptions,
                mode: filter.operator === 14 ? 'range' : 'single',
                altFormat: 'F j, Y H:i',
                onChange: () => {
                  if (filter.filterValues.length > 0 && filter.tempFilterDate) {
                    filter.filterValues.map((item) => {
                      item.value = filter.tempFilterDate;
                    });
                  } else if (filter.tempFilterDate) {
                    filter.filterValues.push({
                      key: '',
                      value: filter.tempFilterDate,
                    });
                  } else {
                    filter.filterValues.pop();
                  }
                  clearFilterInvalid(filter);
                },
                enableTime:
                  getOperators(filter.columnId) == columnTypes.DATE_TIME,
                dateFormat: `Y-m-d${
                  getOperators(filter.columnId) == columnTypes.DATE_TIME
                    ? 'tH:i'
                    : ''
                }`,
                maxDate: new Date().getTime() + 1,
                enableTime: true,
                altInput: true,
              }"
            />
            <span
              v-if="
                filter.invalid &&
                !filter.tempFilterDate &&
                isModal &&
                filter.operator !== null
              "
              class="invalid-text"
              >Parameter value is required! <br />
              Parameter must have valid date value!</span
            >
          </div>
          <div v-else class="parameter-value-container">
            <b-form-tags
              v-model="filter.tempFilterValues"
              placeholder="Value"
              remove-on-delete
              add-on-change
              @input="handleFilterValues(filter)"
              required
              class="form__input-params form__input-tags"
              :disabled="globalDisabled"
              :class="
                filter.invalid &&
                (filter.tempFilterValues.length <= 0 || !filter.validValue) &&
                isModal
                  ? 'filter-is-invalid'
                  : null
              "
            />
            <span
              v-if="
                filter.invalid &&
                (filter.tempFilterValues.length <= 0 || !filter.validValue) &&
                isModal
              "
              class="invalid-text"
              >{{
                !filter.validValue
                  ? "Parameter values should be valid!"
                  : "Parameter values are required!"
              }}</span
            >
            <span v-else class="parameter-description">
              * Enter tags by pressing enter or clicking outside the input field
            </span>
          </div>
        </div>
        <div v-if="isModal">
          <div class="filter-holder__label">
            <label>Custom Label</label>
            <label>Optional</label>
          </div>
          <b-input
            class="simple-filter__input h-auto"
            placeholder="Custom Label"
            v-model="filter.customLabel"
            :disabled="globalDisabled"
          />
        </div>
      </div>

      <button
        title="Delete"
        type="button"
        @click="handleFilter(index)"
        :class="
          filterType === 'global' || !globalDisabled
            ? 'delete-filter btn-delete'
            : 'btn btn-predefined'
        "
      >
        <GjIcon name="DeleteTrash" size="16" v-if="!globalDisabled" />
        <GjIcon
          v-else
          :color="!filter.hidden ? '#052D61' : '#667C99'"
          size="20"
          :name="
            !filter.hidden ? 'EyeVisibilityPassword' : 'IconparkPreviewClose'
          "
        />
      </button>
    </div>
    <button
      v-if="!globalDisabled"
      class="new-button"
      :disabled="isNewDisabled()"
      @click="addFilter"
    >
      <GjIcon name="Plus_fill" size="14" />
      New
    </button>
    <div v-else class="pb-2"></div>
  </div>
</template>

<script>
import store from "@/store";
import { LoadingBar, GjIcon } from "@nodus/utilities-front";
import { BFormInput } from "bootstrap-vue";
import "flatpickr/dist/flatpickr.css";
import flatPickr from "vue-flatpickr-component";
import { VueSelect } from "vue-select";
import Filter from "../../../models/Filter";
import { columnTypes } from "../../../utils/constants";
import _ from "lodash";
import validateValue from "../../../utils/regexValidations";
import MessagePanel from "@/components/MessagePanel";

export default {
  name: "BiskoVisualizerFrontFilter",
  props: {
    filters: {
      type: Array,
      required: true,
    },
    operator: {
      type: Number,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    isModal: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasForecast: {
      type: Boolean,
      default: false,
    },
    filterType: {
      type: String,
      required: false,
      default: "global",
    },
    globalDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    "v-select": VueSelect,
    flatPickr,
    BFormInput,
    LoadingBar,
    GjIcon,
    MessagePanel,
  },
  computed: {
    lOperator: {
      get() {
        return this.operatorSelect.find((val) => val.value === this.operator);
      },
      set(value) {
        this.$emit("update:operator", value.value);
      },
    },
    parameters: {
      get() {
        return store.getters["getFilterParameters"];
      },
      set(value) {
        store.commit("SET_FILTER_PARAMETERS", value);
      },
    },
    operators: {
      get() {
        return store.getters["getFilterOperators"];
      },
    },
    filteredParameters: {
      get() {
        return store.getters["getFilteredDashboardParameters"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_FILTERED_PARAMS", value);
      },
    },
    dashboardTableIds: {
      get() {
        return store.getters["getDashboardTableIds"];
      },
      set(value) {
        store.commit("SET_DASHBOARD_TABLE_IDS", value);
      },
    },
  },
  data() {
    return {
      columnTypes,
      organizationId: this.$route?.params?.organizationId,
      parametersBooleanValuesConst: [
        { key: "", value: "true" },
        { key: "", value: "false" },
      ],
      operatorSelect: [
        { text: "All", value: 1 },
        { text: "Any", value: 2 },
      ],
      selectedParameter: null,
      date: "",
      dateFrom: "",
      dateTo: "",
      calendarOptions: {
        onClose: (selectedDates, dateStr, instance) => {
          this.dateFrom = dateStr;
          const formatDate = dateStr.split(" to ");
          if (formatDate.length > 1) {
            [this.dateFrom, this.dateTo] = formatDate;
            instance.setDate([this.dateFrom, this.dateTo], true);
          } else {
            this.dateTo = this.dateFrom;
            instance.setDate([this.dateTo], true);
          }
        },
      },
      duplicatedParametersArr: [],
      activeFilterSearch: -1,
    };
  },
  methods: {
    addFilter() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.push(
        new Filter({ id: null, columnId: null, filterValues: [] })
      );
    },
    handleFilter(index) {
      if (this.filterType === "global" || !this.globalDisabled) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filters.splice(index, 1);
        if (this.filters.length === 0) {
          let toolbar = document.querySelector(".toolbar-wrapper");
          if (toolbar) {
            toolbar.classList.remove("filter-opened");
          }
        }
      } else {
        if (this.filters[index]) {
          // eslint-disable-next-line vue/no-mutating-props
          this.filters[index].hidden = !this.filters[index].hidden;
        }
      }
    },
    getOperators(id) {
      if (!id) return null;

      return this.parameters[this.tableId]?.parameters.find(
        (x) => x.columnId === id
      )?.columnType;
    },
    handleFilterValues(filter, isBoolean = false) {
      let arr = [];
      if (filter.tempFilterValues.length > 0) {
        filter.tempFilterValues.forEach((value) => {
          arr.push({ key: filter.tempFilterKey || "", value });
        });
      } else if (isBoolean) {
        arr.push(filter.tempFilterValues);
      }

      filter.filterValues = arr;
      this.clearFilterInvalid(filter);
    },
    updateFilterValuesKey(filter) {
      filter.filterValues.forEach((value) => {
        value.key = filter.tempFilterKey;
      });
    },
    clearFilterInvalid(filter, isDate) {
      if (isDate) {
        filter.tempFilterDate = "";
      }
      filter.invalid = false;
    },
    clearFilter(filter) {
      filter.invalid = false;
      filter.filterValues = [];
      filter.tempFilterValues = [];
      filter.operator = null;
    },
    isNewDisabled() {
      let disabled = false;
      this.filters.forEach((filter) => {
        if (!this.isFilterValid(filter)) {
          disabled = true;
        }
      });
      return disabled;
    },
    isFilterValid(filter) {
      const operator = this.getOperators(filter.columnId);
      const validation = validateValue(filter, operator);
      filter.validValue = validation;

      return (
        filter?.columnId !== null &&
        filter?.operator !== null &&
        filter?.filterValues.length !== 0 &&
        filter.validValue
      );
    },
    searchParameters: _.debounce(async function (searchWord, filterIndex) {
      this.activeFilterSearch = filterIndex;
      await store.dispatch("getParameters", {
        organizationId: this.organizationId,
        search: searchWord,
      });
    }, 300),
  },
  mounted() {
    this.filters.map((item) => {
      item.tempFilterValues = item.filterValues.map((i) => i.value);
      item.tempFilterKey = item.filterValues[0]?.key;
    });
    this.filters.map((item) => {
      item.tempFilterDate = item.tempFilterValues.find((item) => item);
    });
    this.duplicatedParametersArr = _.cloneDeep(this.filteredParameters);
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/filter.scss";
</style>
