var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filter-status__container" }, [
    _c("div", { staticClass: "dropdown__header" }, [
      _c("span", [
        _vm._v(
          "Filters " +
            _vm._s(
              _vm.filters.filter((item) => item.isApplied === true).length > 0
                ? `(${_vm.filters.length})`
                : ""
            ) +
            " "
        ),
      ]),
    ]),
    !_vm.hasAppliedFilters
      ? _c("div", { staticClass: "no-filters__state" }, [
          _c("span", { staticClass: "no-filters__state-title" }, [
            _vm._v("No filters have been added yet."),
          ]),
          _c("span", { staticClass: "no-filters__state-desc" }, [
            _vm._v(
              "The filters you configured were not applied in this widget."
            ),
          ]),
        ])
      : _c(
          "div",
          { staticClass: "filter-details__container" },
          [
            _vm._l(_vm.appliedFilters, function (filter, index) {
              return _c(
                "div",
                { key: index, staticClass: "filter-status-tag" },
                [
                  _c("GjIcon", {
                    attrs: { name: "Check", size: "18", color: "#36B37E" },
                  }),
                  _c("TagText", {
                    attrs: { filter: filter, tableId: _vm.tableId },
                  }),
                ],
                1
              )
            }),
            _vm.isShowMoreActive
              ? _c(
                  "div",
                  { staticClass: "not-applied-filters__container" },
                  _vm._l(_vm.notAppliedFilters, function (filter, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "filter-status-tag" },
                      [
                        _c("GjIcon", {
                          attrs: {
                            name: "Close",
                            size: "18",
                            color: "#E34850",
                          },
                        }),
                        _c("TagText", {
                          attrs: { filter: filter, tableId: _vm.tableId },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.showSeeMoreButton
              ? _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.toggleNotAppliedFilters()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isShowMoreActive ? "View Less" : "View More"
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }