var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tableId
    ? _c(
        "div",
        { staticClass: "filter-container" },
        [
          !_vm.isModal && _vm.hasForecast
            ? _c("MessagePanel", {
                attrs: {
                  backgroundColor:
                    "linear-gradient(0deg, #FDF6EC, #FDF6EC),\n  linear-gradient(0deg, #FADBB6, #FADBB6)",
                  borderColor: "#FADBB6",
                  iconName: "Warning",
                  iconColor: "#EF8C13",
                  text: " Adding, removing or modifying existing filters will recalculate\n      forecasting.",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "main-filter" },
            [
              _c("p", { staticClass: "main-filter-title" }, [_vm._v("With")]),
              _c("v-select", {
                staticClass: "parameter-dropdown",
                attrs: {
                  options: _vm.operatorSelect,
                  clearable: false,
                  getOptionLabel: (x) => x.text,
                  placeholder: "Select",
                  disabled: _vm.globalDisabled,
                },
                model: {
                  value: _vm.lOperator,
                  callback: function ($$v) {
                    _vm.lOperator = $$v
                  },
                  expression: "lOperator",
                },
              }),
              _c("p", { staticClass: "main-filter-title" }, [
                _vm._v("of the following parameters"),
              ]),
            ],
            1
          ),
          _vm._l(_vm.filters, function (filter, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "filter-holder",
                attrs: { id: filter.id },
              },
              [
                _c(
                  "p",
                  {
                    staticClass: "operator-title",
                    style: { visibility: index > 0 ? "visible" : "hidden" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.operator === 1 ? "AND" : "OR ") + " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "filter-holder__wrapper",
                    class:
                      filter.invalid && !_vm.isModal
                        ? "filter-is-invalid"
                        : null,
                  },
                  [
                    _c("div", { staticClass: "filter-holder__inputs" }, [
                      _c(
                        "div",
                        { staticClass: "parameter-dropdown" },
                        [
                          _vm.parameters[_vm.tableId]
                            ? _c("v-select", {
                                key: filter.id,
                                class:
                                  filter.invalid &&
                                  filter.columnId === null &&
                                  _vm.isModal
                                    ? "filter-is-invalid"
                                    : null,
                                attrs: {
                                  selectable: (option) => !option.isParent,
                                  options: _vm.isModal
                                    ? _vm.activeFilterSearch === index
                                      ? _vm.filteredParameters
                                      : _vm.duplicatedParametersArr
                                    : _vm.parameters[_vm.tableId].parameters,
                                  reduce: (option) => option.columnId,
                                  clearable: false,
                                  loading: _vm.loading,
                                  disabled: _vm.loading || _vm.globalDisabled,
                                  filterable: _vm.isModal ? false : true,
                                  label: "parameterName",
                                  placeholder: "Parameters",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.clearFilter(filter)
                                  },
                                  search: function ($event) {
                                    _vm.isModal
                                      ? _vm.searchParameters($event, index)
                                      : {}
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "spinner",
                                      fn: function ({ loading }) {
                                        return [
                                          loading
                                            ? _c("LoadingBar", {
                                                attrs: {
                                                  classList: "small mr-1",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: filter.columnId,
                                  callback: function ($$v) {
                                    _vm.$set(filter, "columnId", $$v)
                                  },
                                  expression: "filter.columnId",
                                },
                              })
                            : _vm._e(),
                          filter.invalid &&
                          filter.columnId === null &&
                          _vm.isModal
                            ? _c("span", { staticClass: "invalid-text" }, [
                                _vm._v("Selected parameter doesn't exist! "),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.getOperators(filter.columnId) ===
                      _vm.columnTypes.KEY_VALUE_PAIR
                        ? _c(
                            "div",
                            [
                              _c("b-form-input", {
                                staticClass:
                                  "filter-key__input simple-filter__input",
                                class:
                                  filter.invalid && !filter.tempFilterKey
                                    ? "filter-is-invalid"
                                    : null,
                                attrs: {
                                  placeholder: "Key",
                                  disabled: _vm.globalDisabled,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.clearFilterInvalid(filter)
                                  },
                                  change: function ($event) {
                                    return _vm.updateFilterValuesKey(filter)
                                  },
                                },
                                model: {
                                  value: filter.tempFilterKey,
                                  callback: function ($$v) {
                                    _vm.$set(filter, "tempFilterKey", $$v)
                                  },
                                  expression: "filter.tempFilterKey",
                                },
                              }),
                              filter.invalid &&
                              !filter.tempFilterKey &&
                              _vm.isModal
                                ? _c("span", { staticClass: "invalid-text" }, [
                                    _vm._v("Key is required! "),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "parameter-dropdown" },
                        [
                          _vm.operators
                            ? _c("v-select", {
                                key: filter.id + "-operator",
                                class:
                                  filter.invalid &&
                                  (filter.columnId === null ||
                                    filter.operator === null) &&
                                  _vm.isModal
                                    ? "filter-is-invalid"
                                    : null,
                                attrs: {
                                  options:
                                    _vm.operators[
                                      _vm.getOperators(filter.columnId)
                                    ],
                                  reduce: (option) => option.key,
                                  clearable: false,
                                  loading: _vm.loading,
                                  disabled: _vm.loading || _vm.globalDisabled,
                                  placeholder: "Select",
                                  label: "value",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.clearFilterInvalid(
                                      filter,
                                      _vm.getOperators(filter.columnId) ===
                                        _vm.columnTypes.DATE ||
                                        _vm.getOperators(filter.columnId) ===
                                          _vm.columnTypes.DATE_TIME
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "spinner",
                                      fn: function ({ loading }) {
                                        return [
                                          loading
                                            ? _c("LoadingBar", {
                                                attrs: {
                                                  classList: "small mr-1",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: filter.operator,
                                  callback: function ($$v) {
                                    _vm.$set(filter, "operator", $$v)
                                  },
                                  expression: "filter.operator",
                                },
                              })
                            : _vm._e(),
                          filter.invalid &&
                          (filter.columnId === null ||
                            filter.operator === null) &&
                          _vm.isModal
                            ? _c("span", { staticClass: "invalid-text" }, [
                                _vm._v("Operator is required!"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.getOperators(filter.columnId) ===
                      _vm.columnTypes.BOOLEAN
                        ? _c(
                            "div",
                            { staticClass: "parameter-value-container" },
                            [
                              _c("v-select", {
                                class:
                                  filter.invalid &&
                                  !filter.tempFilterValues.key &&
                                  _vm.isModal
                                    ? "filter-is-invalid"
                                    : null,
                                attrs: {
                                  options: _vm.parametersBooleanValuesConst,
                                  clearable: false,
                                  searchable: false,
                                  label: "value",
                                  placeholder: "Select",
                                  disabled: _vm.globalDisabled,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.handleFilterValues(filter, true)
                                  },
                                },
                                model: {
                                  value: filter.tempFilterValues,
                                  callback: function ($$v) {
                                    _vm.$set(filter, "tempFilterValues", $$v)
                                  },
                                  expression: "filter.tempFilterValues",
                                },
                              }),
                              filter.invalid &&
                              !filter.tempFilterValues.key &&
                              _vm.isModal
                                ? _c("span", { staticClass: "invalid-text" }, [
                                    _vm._v("Parameter value is required! "),
                                    _c("br"),
                                    _vm._v(
                                      " Boolean parameter accepts only true or false!"
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm.getOperators(filter.columnId) ==
                            _vm.columnTypes.DATE ||
                          _vm.getOperators(filter.columnId) ==
                            _vm.columnTypes.DATE_TIME
                        ? _c(
                            "div",
                            { staticClass: "parameter-value-container" },
                            [
                              filter.operator !== null
                                ? _c("flat-pickr", {
                                    key: filter.id + "date-picker",
                                    ref: "flatpickr",
                                    refInFor: true,
                                    staticClass: "form-control date",
                                    class:
                                      filter.invalid &&
                                      !filter.tempFilterDate &&
                                      _vm.isModal
                                        ? "filter-is-invalid"
                                        : null,
                                    attrs: {
                                      id: "select-datepicker",
                                      disabled: _vm.globalDisabled,
                                      config: {
                                        ..._vm.calendarOptions,
                                        mode:
                                          filter.operator === 14
                                            ? "range"
                                            : "single",
                                        altFormat: "F j, Y H:i",
                                        onChange: () => {
                                          if (
                                            filter.filterValues.length > 0 &&
                                            filter.tempFilterDate
                                          ) {
                                            filter.filterValues.map((item) => {
                                              item.value = filter.tempFilterDate
                                            })
                                          } else if (filter.tempFilterDate) {
                                            filter.filterValues.push({
                                              key: "",
                                              value: filter.tempFilterDate,
                                            })
                                          } else {
                                            filter.filterValues.pop()
                                          }
                                          _vm.clearFilterInvalid(filter)
                                        },
                                        enableTime:
                                          _vm.getOperators(filter.columnId) ==
                                          _vm.columnTypes.DATE_TIME,
                                        dateFormat: `Y-m-d${
                                          _vm.getOperators(filter.columnId) ==
                                          _vm.columnTypes.DATE_TIME
                                            ? "tH:i"
                                            : ""
                                        }`,
                                        maxDate: new Date().getTime() + 1,
                                        enableTime: true,
                                        altInput: true,
                                      },
                                    },
                                    model: {
                                      value: filter.tempFilterDate,
                                      callback: function ($$v) {
                                        _vm.$set(filter, "tempFilterDate", $$v)
                                      },
                                      expression: "filter.tempFilterDate",
                                    },
                                  })
                                : _vm._e(),
                              filter.invalid &&
                              !filter.tempFilterDate &&
                              _vm.isModal &&
                              filter.operator !== null
                                ? _c("span", { staticClass: "invalid-text" }, [
                                    _vm._v("Parameter value is required! "),
                                    _c("br"),
                                    _vm._v(
                                      " Parameter must have valid date value!"
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "parameter-value-container" },
                            [
                              _c("b-form-tags", {
                                staticClass:
                                  "form__input-params form__input-tags",
                                class:
                                  filter.invalid &&
                                  (filter.tempFilterValues.length <= 0 ||
                                    !filter.validValue) &&
                                  _vm.isModal
                                    ? "filter-is-invalid"
                                    : null,
                                attrs: {
                                  placeholder: "Value",
                                  "remove-on-delete": "",
                                  "add-on-change": "",
                                  required: "",
                                  disabled: _vm.globalDisabled,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.handleFilterValues(filter)
                                  },
                                },
                                model: {
                                  value: filter.tempFilterValues,
                                  callback: function ($$v) {
                                    _vm.$set(filter, "tempFilterValues", $$v)
                                  },
                                  expression: "filter.tempFilterValues",
                                },
                              }),
                              filter.invalid &&
                              (filter.tempFilterValues.length <= 0 ||
                                !filter.validValue) &&
                              _vm.isModal
                                ? _c("span", { staticClass: "invalid-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        !filter.validValue
                                          ? "Parameter values should be valid!"
                                          : "Parameter values are required!"
                                      )
                                    ),
                                  ])
                                : _c(
                                    "span",
                                    { staticClass: "parameter-description" },
                                    [
                                      _vm._v(
                                        " * Enter tags by pressing enter or clicking outside the input field "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                    ]),
                    _vm.isModal
                      ? _c(
                          "div",
                          [
                            _vm._m(0, true),
                            _c("b-input", {
                              staticClass: "simple-filter__input h-auto",
                              attrs: {
                                placeholder: "Custom Label",
                                disabled: _vm.globalDisabled,
                              },
                              model: {
                                value: filter.customLabel,
                                callback: function ($$v) {
                                  _vm.$set(filter, "customLabel", $$v)
                                },
                                expression: "filter.customLabel",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "button",
                  {
                    class:
                      _vm.filterType === "global" || !_vm.globalDisabled
                        ? "delete-filter btn-delete"
                        : "btn btn-predefined",
                    attrs: { title: "Delete", type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.handleFilter(index)
                      },
                    },
                  },
                  [
                    !_vm.globalDisabled
                      ? _c("GjIcon", {
                          attrs: { name: "DeleteTrash", size: "16" },
                        })
                      : _c("GjIcon", {
                          attrs: {
                            color: !filter.hidden ? "#052D61" : "#667C99",
                            size: "20",
                            name: !filter.hidden
                              ? "EyeVisibilityPassword"
                              : "IconparkPreviewClose",
                          },
                        }),
                  ],
                  1
                ),
              ]
            )
          }),
          !_vm.globalDisabled
            ? _c(
                "button",
                {
                  staticClass: "new-button",
                  attrs: { disabled: _vm.isNewDisabled() },
                  on: { click: _vm.addFilter },
                },
                [
                  _c("GjIcon", { attrs: { name: "Plus_fill", size: "14" } }),
                  _vm._v(" New "),
                ],
                1
              )
            : _c("div", { staticClass: "pb-2" }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter-holder__label" }, [
      _c("label", [_vm._v("Custom Label")]),
      _c("label", [_vm._v("Optional")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }