var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "color-picker" },
    [
      _c("div", { staticClass: "color-picker__holder" }, [
        _c(
          "div",
          { staticClass: "color-input__container" },
          [
            _c("b-form-input", {
              staticClass: "color-picker__title",
              class: _vm.showColor ? "input-with-color" : "",
              attrs: {
                placeholder: _vm.name,
                disabled: _vm.disableInput,
                state: _vm.disableInput ? null : _vm.validLabel,
              },
              model: {
                value: _vm.label,
                callback: function ($$v) {
                  _vm.label = $$v
                },
                expression: "label",
              },
            }),
            _c(
              "b-form-invalid-feedback",
              { attrs: { id: "input-live-feedback" } },
              [
                _vm._v(
                  " Invalid label format. Some special characters are not allowed! "
                ),
              ]
            ),
            _c("div", { staticClass: "color-picker__wrapper" }, [
              _vm.showColor
                ? _c("button", {
                    staticClass: "color-picker__color-box",
                    style: { backgroundColor: _vm.value },
                    attrs: { role: "button" },
                    on: { click: _vm.togglePicker },
                  })
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _c("chrome-picker", {
        class: _vm.showPicker ? "color-picker__show" : "",
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }