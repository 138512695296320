<template>
  <div
    v-if="filter.customLabel"
    :id="`filter-${filter.id}`"
    @click="onTagItemClick(filter.id)"
  >
    {{ filter.customLabel }}
    <b-tooltip
      v-if="filter.customLabel.length > maxFilterLength && showTooltip"
      :target="`filter-${filter.id}`"
      triggers="hover"
      placement="top"
    >
      {{ filter.customLabel }}
    </b-tooltip>
  </div>
  <div v-else :id="`filter-${filter.id}`" @click="onTagItemClick(filter.id)">
    {{
      parameters[tableId]?.parameters?.find(
        (item) => item.columnId === filter?.columnId
      )?.parameterName
    }}
    <span class="filter-operator">{{ operatorIdToText }} </span>
    <span>
      {{ filterValues }}
    </span>
    <b-tooltip
      v-if="showTooltip"
      :target="`filter-${filter.id}`"
      triggers="hover"
      placement="top"
    >
      {{
        parameters[tableId]?.parameters?.find(
          (item) => item.columnId === filter?.columnId
        )?.parameterName
      }}
      {{ operatorIdToText }} {{ filterValues }}
    </b-tooltip>
  </div>
</template>

<script>
import store from "@/store";
import { BTooltip } from "bootstrap-vue";

export default {
  components: {
    BTooltip,
  },
  props: {
    filter: {
      type: [Array, Object],
    },
    tableId: {
      type: String,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    parameters: {
      get() {
        return store.getters["getFilterParameters"];
      },
      set(value) {
        store.commit("SET_FILTER_PARAMETERS", value);
      },
    },
    operators: {
      get() {
        return store.getters["getFilterOperators"];
      },
    },
    operatorIdToText() {
      return this.operators[
        this.parameters[this.tableId]?.parameters?.find(
          (x) => x.columnId === this.filter.columnId
        )?.columnType
      ]
        ?.find((item) => item.key === this.filter.operator)
        .value.toLowerCase();
    },
    filterValues() {
      if (this.filter.filterValues.value) {
        return this.filter.filterValues.value;
      }
      const filterValuesText = this.filter?.filterValues?.map(
        (item) => item.value
      );
      return filterValuesText.join(", ");
    },
  },
  data() {
    return {
      maxFilterLength: 30,
    };
  },
  methods: {
    onTagItemClick(filterId) {
      this.$emit("open-filter-modal", filterId);
    },
  },
};
</script>
