<template>
  <div class="color-picker">
    <div class="color-picker__holder">
      <div class="color-input__container">
        <b-form-input
          v-model="label"
          class="color-picker__title"
          :placeholder="name"
          :disabled="disableInput"
          :state="disableInput ? null : validLabel"
          :class="showColor ? 'input-with-color' : ''"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          Invalid label format. Some special characters are not allowed!
        </b-form-invalid-feedback>
        <div class="color-picker__wrapper">
          <button
            v-if="showColor"
            role="button"
            @click="togglePicker"
            class="color-picker__color-box"
            :style="{ backgroundColor: value }"
          ></button>
        </div>
      </div>
    </div>
    <chrome-picker
      :class="showPicker ? 'color-picker__show' : ''"
      v-model="value"
    />
  </div>
</template>

<script>
import { Chrome } from "vue-color";
import { BFormInput, BFormInvalidFeedback } from "bootstrap-vue";
import store from "../store";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "#FFFFFF",
    },
    type: {
      type: Number,
      required: true,
    },
    showColor: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    "chrome-picker": Chrome,
    BFormInput,
    BFormInvalidFeedback,
  },
  data() {
    return {
      showPicker: false,
    };
  },
  computed: {
    disableInput() {
      return (
        this.type ===
          this.widgetTypes.find((item) => item.name === "Map Chart").id &&
        (this.name === "Min Color" || this.name === "Max Color")
      );
    },
    validLabel() {
      const regex = /^[^?\\'"`;:]*$/;
      if (regex.test(this.label)) {
        return null;
      }
      return false;
    },
    widgetTypes: {
      get() {
        return store.getters["getWidgetTypes"];
      },
      set(value) {
        store.commit("SET_WIDGET_TYPES", value);
      },
    },
    value: {
      get() {
        return this.color;
      },
      set(value) {
        this.$emit("update:color", value.hex);
      },
    },
    label: {
      get() {
        return this.title;
      },
      set(value) {
        this.$emit("update:label", value);
      },
    },
  },
  methods: {
    togglePicker() {
      this.showPicker = !this.showPicker;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/color-picker.scss";
</style>
