var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "toolbar-wrapper",
      class: !_vm.showSideBar ? "toolbar-hidden" : "",
    },
    [
      _c(
        "button",
        {
          staticClass: "pull-tab",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.toggleSideBar()
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require(`@/assets/img/pull-tab.svg`),
              alt: "Widget image",
            },
          }),
        ]
      ),
      !_vm.selectedItem
        ? _c("widgets-toolbar")
        : _vm.selectedItem
        ? _c("configuration-toolbar")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }