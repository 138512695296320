var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bisko-app__container", attrs: { id: "app" } },
    [
      _c(
        "div",
        { class: _vm.contentContainerClass },
        [
          _c("app-bread-crumb", { attrs: { module: "visualizer" } }),
          _c(
            "transition",
            { attrs: { name: "zoom-fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }