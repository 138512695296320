export function exitFullScreen(type) {
  const container = document.querySelector(".dashboard-overview");
  const content = document.querySelector(".content__container");
  const admin = document.getElementById("nodus-admin");
  const navigation = document.getElementById("nodus-navigation");
  const grid = document.querySelector(".dashboard-grid-layout");

  if (type === "fullscreen") {
    admin.style.display = "";
    navigation.style.display = "";
    content.style.padding = "";
    container.style.padding = "";
  }

  try {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  } catch (error) {
    console.error("An error occurred while exiting fullscreen:", error);
  }

  if (grid) {
    grid.style.width = "99%";
    setTimeout(() => {
      grid.style.width = "";
    }, 10);
  }
}
export function enterFullScreen() {
  const container = document.querySelector(".dashboard-overview");
  const content = document.querySelector(".content__container");
  const fullscreenHeader = document.querySelector(".fullscreen__header");
  const admin = document.getElementById("nodus-admin");
  const navigation = document.getElementById("nodus-navigation");

  content.style.padding = "0";
  admin.style.display = "none";
  navigation.style.display = "none";
  container.style.padding = "0 3rem 3rem 3rem";

  if (document.documentElement?.mozRequestFullScreen) {
    fullscreenHeader.style.margin = "0";
    container.style.padding = "0";
    document.documentElement.mozRequestFullScreen();
  } else if (document.documentElement?.requestFullscreen) {
    document.documentElement.requestFullscreen();
  } else if (document.documentElement?.webkitRequestFullscreen) {
    document.documentElement.webkitRequestFullscreen();
  } else if (document.documentElement?.msRequestFullscreen) {
    document.documentElement.msRequestFullscreen();
  }
}
