var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-overview-grid", attrs: { id: "grid-layout" } },
    [
      _c(
        "grid-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.fullScreenWidget,
              expression: "!fullScreenWidget",
            },
          ],
          ref: "gridlayout",
          class: _vm.mode !== "view" ? "grid-builder" : "dashboard-grid-layout",
          attrs: {
            layout: _vm.layout,
            "col-num": 6,
            "row-height": 200,
            "is-draggable": _vm.mode !== "view" ? true : false,
            "is-resizable": _vm.mode !== "view" ? true : false,
            "vertical-compact": true,
            "use-css-transforms": true,
          },
          on: {
            "update:layout": function ($event) {
              _vm.layout = $event
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.clearSelectedItem.apply(null, arguments)
            },
          },
        },
        _vm._l(_vm.layout, function (item) {
          return _c(
            "grid-item",
            {
              key: item.i,
              staticClass: "v-grid-item",
              class: [
                _vm.selectedItem === item.i && _vm.mode !== "view"
                  ? "selected-item"
                  : null,
                !item.show ? "show-placeholder" : "",
              ],
              attrs: {
                id: item.i,
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.h,
                i: item.i,
                minW: 1,
                minH: 1,
                maxW: 6,
              },
              on: {
                resized: function ($event) {
                  return _vm.resizedEvent(item.h, item.i)
                },
              },
            },
            [
              _vm.mode !== "view"
                ? _c("span", {
                    staticClass: "overlay__container",
                    on: {
                      click: function ($event) {
                        _vm.mode !== "view" ? _vm.editWidget(item.i) : null
                      },
                    },
                  })
                : _vm._e(),
              _vm.mode !== "view"
                ? _c(
                    "div",
                    {
                      staticClass: "widget__actions",
                      class: _vm.selectedItem === item.i ? "selected" : null,
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "edit__btn",
                          attrs: { type: "button", title: "Edit" },
                          on: {
                            click: function ($event) {
                              return _vm.editWidget(item.i)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "edit__btn",
                            attrs: {
                              src: require("@/assets/img/edit-icon.svg"),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.duplicateModal",
                              modifiers: { duplicateModal: true },
                            },
                          ],
                          attrs: { type: "button", title: "Duplicate" },
                          on: {
                            click: function ($event) {
                              return _vm.setItemToDuplicate(item)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/duplicate-icon.svg"),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.widgetModal",
                              modifiers: { widgetModal: true },
                            },
                          ],
                          attrs: { type: "button", title: "Delete" },
                          on: {
                            click: function ($event) {
                              return _vm.setItemToDelete(item.i)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/delete-icon.svg"),
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "filter-title__container" }, [
                _c(
                  "div",
                  { staticClass: "title-with-forecast" },
                  [
                    _c(
                      "span",
                      _vm._b(
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              modifiers: { hover: true, top: true },
                            },
                          ],
                          staticClass: "widget__title",
                        },
                        "span",
                        item.title.length >= 20 ? { title: item.title } : null,
                        false
                      ),
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    ),
                    _vm.mode === "view" &&
                    _vm.checkWidgetType(_vm.widgetTypes, item, "Line Chart") &&
                    item.settings.forecastStatus !==
                      _vm.forecastStatus.NOT_ACTIVE
                      ? _c("ProcessingForecast", {
                          attrs: { data: item.settings, id: item.id },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "filter-fullscreen__container" }, [
                  _vm.mode === "view" &&
                  _vm.type !== "embed" &&
                  item?.data?.globalFilters &&
                  item?.data?.globalFilters?.length > 0 &&
                  !item.loading
                    ? _c("div", { staticClass: "applied-filters" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn filter-btn rounded-pill p-2",
                            attrs: { id: `filter-btn-${item.id}` },
                            on: {
                              mouseenter: function ($event) {
                                return _vm.triggerStatusDropdown(item, true)
                              },
                              mouseleave: function ($event) {
                                return _vm.triggerStatusDropdown(null, false)
                              },
                            },
                          },
                          [
                            _c("GjIcon", {
                              attrs: { name: "IconparkFilter", size: "20" },
                            }),
                            _vm.openDropdown &&
                            _vm.filtersModalData?.id === item.id
                              ? _c(
                                  "div",
                                  { staticClass: "filter-dropdown__container" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "filter-status-dropdown" },
                                      [
                                        _c("FilterStatusContainer", {
                                          key: _vm.filtersModalData.id,
                                          attrs: {
                                            filters:
                                              _vm.filtersModalData.data
                                                .globalFilters,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        item?.data?.globalFilters?.find(
                          (filter) => filter.isApplied === true
                        )
                          ? _c("div", { staticClass: "red-circle" })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.mode === "view" &&
                  !_vm.noFullscreen &&
                  !_vm.isDashobardFullScreen &&
                  !_vm.fullScreenWidget &&
                  !item.loading
                    ? _c(
                        "button",
                        {
                          staticClass: "btn filter-btn rounded-pill",
                          attrs: { id: `fullscreen-btn-${item.id}` },
                          on: {
                            click: function ($event) {
                              return _vm.makeWidgetFullScreen(item)
                            },
                          },
                        },
                        [
                          _c("GjIcon", {
                            attrs: { name: "Fullscreen", size: "20" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              !item.show && _vm.mode !== "view"
                ? _c("img", {
                    staticClass: "widget-placeholder",
                    attrs: {
                      src: _vm.widgetTypes.find(
                        (widget) => widget.id === item.widgetType
                      ).image,
                      alt: "Widget image",
                    },
                  })
                : _c("Widgets", {
                    key: item.id,
                    style: {
                      paddingTop:
                        item.title !== "" && item.title !== null
                          ? "30px"
                          : null,
                    },
                    attrs: {
                      mode: _vm.mode,
                      widget: item,
                      widgetType: item.widgetType,
                      componentId: `${item.widgetType}-${item.i}`,
                      options: item.options,
                    },
                  }),
            ],
            1
          )
        }),
        1
      ),
      _vm.fullScreenWidget
        ? _c("FullScreenWidget", {
            attrs: { item: _vm.fullScreenWidget, type: _vm.type },
          })
        : _vm._e(),
      _c("DeleteModal", {
        attrs: { id: "widgetModal", title: "Delete Widget", type: "widget" },
        on: { delete: _vm.removeItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }