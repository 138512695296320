import singleSpaVue from "single-spa-vue";
import Vue from "vue";
import "./set-public-path";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import { i18n } from "@nodus/utilities-front";

import VueGoodTablePlugin from "vue-good-table";
// import the styles
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.use(VueGoodTablePlugin);

import "@/libs/vue-select";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: "#nodus-app",
    render: (h) => h(App),
    router,
    store,
    i18n,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = (props) =>
  vueLifecycles.mount(props).then((instance) => {
    window.requestAnimationFrame(() =>
      instance.$el.classList.add("bisko-app__visualizer")
    );
  });
export const unmount = vueLifecycles.unmount;
