<template>
  <div
    class="vue-grid-item v-grid-item full-screen__widget"
    :class="[!item.show ? 'show-placeholder' : '']"
    :id="`fullscreen-${item.i}`"
  >
    <div class="filter-title__container">
      <span v-if="item.title" class="widget__title">
        {{ item.title }}
      </span>
      <div
        v-if="
          type !== 'embed' &&
          item?.data?.globalFilters &&
          item?.data?.globalFilters?.length > 0 &&
          !item.loading
        "
        class="applied-filters"
      >
        <button
          :id="`filter-btn-${item.id}`"
          class="btn filter-btn rounded-pill"
          @mouseenter="triggerStatusDropdown(item, true)"
          @mouseleave="triggerStatusDropdown(null, false)"
        >
          <GjIcon name="IconparkFilter" size="20" />
          <div
            class="filter-dropdown__container"
            v-if="openDropdown && filtersModalData?.id === item.id"
          >
            <div class="filter-status-dropdown">
              <FilterStatusContainer
                :filters="filtersModalData.data.globalFilters"
                :key="filtersModalData.id"
              />
            </div>
          </div>
        </button>
        <div
          v-if="
            item?.data?.globalFilters?.find(
              (filter) => filter.isApplied === true
            )
          "
          class="red-circle"
        ></div>
      </div>
    </div>
    <Widgets
      :mode="'fullscreen'"
      :key="item.id"
      :widget="item"
      :widgetType="item.widgetType"
      :componentId="`fullscreen-${item.widgetType}-${item.i}`"
      :options="item.options"
      :style="{
        paddingTop: item.title !== '' && item.title !== null ? '30px' : null,
      }"
    />
  </div>
</template>

<script>
import FilterStatusContainer from "./global-filters/FilterStatusContainer.vue";
import Widgets from "./Widgets.vue";
import { GjIcon } from "@nodus/utilities-front";

export default {
  name: "BiskoVisualizerFrontFullScreenWidget",
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: String,
  },
  components: {
    FilterStatusContainer,
    Widgets,
    GjIcon,
  },
  data() {
    return {
      openDropdown: false,
      filterDropdownWidth: 356,
      filtersModalData: null,
    };
  },
  methods: {
    triggerStatusDropdown(item, state) {
      this.filtersModalData = item;
      this.openDropdown = state;

      let dropdownEl;
      if (state) {
        let e = window.event;
        let posX = e.clientX;
        let maxWidth = document.body.clientWidth;
        let openLeft = posX + this.filterDropdownWidth > maxWidth;
        setTimeout(() => {
          dropdownEl = document.querySelector(".filter-dropdown__container");
          if (openLeft) {
            dropdownEl?.classList.add("open-left");
          } else {
            dropdownEl?.classList.add("open-right");
          }
        }, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.full-screen__widget {
  height: 84vh;
  margin-top: 5vh;
}
</style>
