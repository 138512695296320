<template>
  <div class="child-component">
    <vue-apex-charts
      v-if="!loading && data"
      :type="getChartType()"
      height="100%"
      width="100%"
      :options="chartOptions"
      :series="chartSeries"
      :class="`apex-chart ${chartType}`"
    />
  </div>
</template>

<script>
import _ from "lodash";
import VueApexCharts from "vue-apexcharts";
import formatValue from "../utils/formatValue";
import { generateRandomHexColorsArray } from "../utils/randomColorsArray";
import { chartConfig } from "./chart-configs";
import { customTooltip } from "./chart-configs/pie";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    componentId: {
      type: String,
      required: true,
    },
    componentType: {
      type: String,
      required: true,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    fields: {
      type: Array,
      required: false,
    },
    isGroupBy: {
      type: Boolean,
      default: false,
      required: false,
    },
    settings: {
      type: Object,
      required: false,
    },
  },
  computed: {
    chartType() {
      return this.componentType;
    },
    colorPalette: {
      get() {
        return this.fields.map((item) => item.color);
      },
    },
    chartData() {
      return this.data.series;
    },
  },
  watch: {
    colorPalette: {
      handler() {
        if (this.getChartType() !== "pie") {
          this.chartOptions = {
            colors: this.colorPalette,
            fill: {
              colors: this.colorPalette,
            },
          };
        } else {
          this.chartOptions = {
            theme: {
              mode: "light",
              monochrome: {
                enabled: true,
                color: this.colorPalette[0],
                shadeTo: "light",
                shadeIntensity: 0.8,
              },
            },
          };
        }
      },
    },
  },
  data() {
    return {
      chartOptions: null,
      chartSeries: null,
      loading: true,
      randomColorsArray: null,
    };
  },
  methods: {
    getChartType() {
      switch (this.chartType) {
        case "line-chart":
          return "area";
        case "pie-chart":
          return "pie";
        case "bar-chart":
          return "bar";
        default:
          return "area";
      }
    },
    setChartOptions() {
      this.chartOptions.yaxis.labels.formatter = (value) =>
        formatValue(value, this.data?.formatType);
      if (this.getChartType() === "area") {
        this.chartOptions.xaxis.categories = this.data.categories;
        this.chartOptions.colors = this.randomColorsArray || this.colorPalette;
        this.chartOptions.fill.colors =
          this.randomColorsArray || this.colorPalette;
        if (this.chartData.find((item) => item.forecast === true)) {
          this.colorPalette.push("#3879F6");
          this.chartOptions.chart.animations = {
            enabled: false,
          };
        }
      } else if (this.getChartType() === "pie") {
        this.chartOptions.labels = this.data.labels;
        this.chartOptions.theme.monochrome.color = this.colorPalette[0];
        this.chartOptions.tooltip.custom = (data) => {
          const serie = data.series[data.seriesIndex];
          const label = data.w.config?.labels[data.seriesIndex];

          return customTooltip(
            serie,
            label,
            this.fields[0].label,
            this.data.formatType
          );
        };
      } else if (this.getChartType() === "bar") {
        this.chartOptions.chart.stacked = this.stacked;
        this.chartOptions.colors = this.randomColorsArray || this.colorPalette;
        this.chartOptions.fill.colors =
          this.randomColorsArray || this.colorPalette;
      }

      if (this.getChartType() !== "pie") {
        if (
          (this.getChartType() === "area" || this.getChartType() === "bar") &&
          this.isGroupBy
        )
          return;
        this.chartOptions = {
          ...this.chartOptions,
          colors: this.colorPalette,
          fill: {
            colors: this.colorPalette,
          },
        };
      }
    },
  },
  async mounted() {
    this.chartSeries = this.chartData;

    if (
      this.isGroupBy &&
      (this.getChartType() === "area" || this.getChartType() === "bar")
    ) {
      this.randomColorsArray = generateRandomHexColorsArray(
        this.chartSeries.length,
        70,
        this.colorPalette
      );
    }

    this.chartOptions = _.cloneDeep(chartConfig[this.getChartType()]);
    this.setChartOptions();
    setTimeout(() => {
      this.loading = false;
    }, 10);
  },
};
</script>
<style lang="scss">
@import "~@/assets/style/components/chart.scss";
</style>
