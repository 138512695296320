var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-overview h-100" },
    [
      !_vm.loadingDashboard && _vm.isDashboardEnabled
        ? _c("FullScreenHeader", {
            attrs: {
              "header-title": _vm.selectedDashboard.name,
              type: "shared",
              "no-fullscreen": _vm.noFullscreen,
            },
          })
        : _vm._e(),
      _vm.shareType !== "embed" &&
      !_vm.loadingDashboard &&
      !_vm.fullScreenWidget &&
      _vm.isDashboardEnabled
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-content-between mt-5 mb-3 flex-wrap",
              staticStyle: { gap: "12px" },
            },
            [
              _c(
                "div",
                { staticClass: "properties-filters__container" },
                [
                  _vm.properties
                    ? _c(
                        "MultipleProperties",
                        _vm._b(
                          {
                            attrs: {
                              id: "select-properties",
                              options: _vm.properties,
                              classes: [
                                "multiple-properties--one",
                                "multiple-properties__min-width",
                              ],
                              isDisabled:
                                !_vm.dashboardConfigs.enablePropertyFilter,
                            },
                            on: { "update-data": _vm.propertyOrDateSelected },
                          },
                          "MultipleProperties",
                          _vm.propertiesSelected?.length > 0
                            ? { selected: _vm.propertiesSelected }
                            : {},
                          false
                        )
                      )
                    : _vm._e(),
                  _vm.dashboardConfigs.enableGlobalFiltersFilter
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled:
                              !_vm.dashboardConfigs.enableGlobalFiltersFilter,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addNewGlobalFilter()
                            },
                          },
                        },
                        [
                          _c("GjIcon", {
                            attrs: { name: "Plus_fill", size: "14" },
                          }),
                          _vm._v(" Add filter "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "dashboard-configs-right" },
                [
                  _c("div", { staticClass: "date-position__right" }, [
                    _c("span", { staticClass: "calendar__range-label" }, [
                      _vm._v("Show data per:"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "header__wrapper-input calendar__range-input",
                      },
                      [
                        _c("date-picker-modal", {
                          attrs: {
                            "start-end-date": _vm.date,
                            "date-range": _vm.dateRange,
                            "min-date": _vm.limitedDateRange().fromDate,
                            "max-date": _vm.limitedDateRange().toDate,
                            isOnShareModal: false,
                            isDisabled: !_vm.dashboardConfigs.enableDateFilter,
                          },
                          on: {
                            "update-selected-date": _vm.updateSelectedDate,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "auto-refresh__dropdown",
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("GjIcon", {
                                      attrs: { name: "RefreshAlt", size: "18" },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.dashboardAutoRefresh.text) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                                _c("GjIcon", { attrs: { name: "ArrowDown" } }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1087342787
                      ),
                    },
                    _vm._l(_vm.autoRefreshOptions, function (item) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: item.id,
                          on: {
                            click: function ($event) {
                              return _vm.changeAutoRefresh(item, false)
                            },
                          },
                        },
                        [
                          _c("GjIcon", {
                            attrs: { name: "RefreshAlt", size: "18" },
                          }),
                          _vm._v(" " + _vm._s(item.text) + " "),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "filter-tags__wrapper",
          class: { "mt-5": _vm.shareType === "embed" },
        },
        [
          _vm.globalFilters?.filters?.length > 0 &&
          !_vm.loadingDashboard &&
          !_vm.fullScreenWidget &&
          _vm.isDashboardEnabled
            ? _c("FilterTags", {
                attrs: {
                  filters: _vm.globalFilters,
                  tableId: _vm.tableId,
                  areDisabled: !_vm.dashboardConfigs.enableGlobalFiltersFilter,
                },
                on: { "update-filters": _vm.saveFilters },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("modal-filter", {
        key: "globalFilter",
        ref: "globalFilter",
        attrs: {
          filters: _vm.globalFilters.filters,
          logicalOperator: _vm.globalFilters.logicalOperator,
        },
        on: { "save-filters": _vm.saveFilters },
      }),
      _vm.loadingDashboard
        ? _c(
            "div",
            { staticClass: "loading-bar__wrapper" },
            [_c("LoadingBar")],
            1
          )
        : !_vm.isDashboardEnabled
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column w-100 h-100 justify-content-center align-items-center h2",
            },
            [
              _c("img", {
                staticClass: "mb-4",
                attrs: {
                  width: "70",
                  src: require(`@/assets/img/error-icon.svg`),
                  alt: "Gjirafa icon",
                },
              }),
              _vm._v(" You cannot view this dashboard! "),
            ]
          )
        : _c("GridLayout", {
            attrs: {
              mode: "view",
              type: _vm.shareType,
              "no-fullscreen": _vm.noFullscreen,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }