var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fullscreen__header" }, [
    _c("div", { staticClass: "fullscreen__header-title" }, [
      _vm._v(" " + _vm._s(_vm.headerTitle) + " "),
    ]),
    !_vm.noFullscreen
      ? _c(
          "button",
          {
            staticClass: "btn btn-with-icon btn-back",
            attrs: { type: "button" },
            on: { click: _vm.handleFullscreen },
          },
          [
            _vm.type === "fullscreen" ||
            (_vm.type === "shared" && _vm.isFullScreen)
              ? [
                  _c("GjIcon", {
                    staticClass: "icon",
                    attrs: { name: "Close", size: "20" },
                  }),
                  _vm._v(" Exit Full Screen "),
                ]
              : _vm.type === "shared" && !_vm.isFullScreen
              ? [
                  _c("GjIcon", {
                    staticClass: "icon",
                    attrs: { name: "Fullscreen", size: "16" },
                  }),
                  _vm._v(" Enter Full Screen "),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }