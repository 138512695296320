var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.column.field === "name"
    ? _c("span", [
        _c(
          "div",
          { staticClass: "dashboard-link" },
          [
            _c("ReadMore", {
              attrs: { text: _vm.data.row.name, to: _vm.viewLocation },
            }),
          ],
          1
        ),
      ])
    : _vm.data.column.field === "insertDateTime"
    ? _c("div", [_c("DateColumn", { attrs: { data: _vm.data } })], 1)
    : _vm.data.column.field === "type"
    ? _c("div", [
        _c("span", { staticClass: "share-type__txt" }, [
          _vm._v(_vm._s(_vm.data.row.dashboardSharingType)),
        ]),
      ])
    : _vm.data.column.field === "options"
    ? _c(
        "div",
        { staticClass: "options-container" },
        [
          _c("b-form-checkbox", {
            key: _vm.data.row.id,
            attrs: { switch: "", size: "md", disabled: !_vm.canModifyShare },
            on: {
              change: function ($event) {
                _vm.canModifyShare ? _vm.changeShareStatus(_vm.data.row.id) : {}
              },
            },
            model: {
              value: _vm.data.row.isEnabled,
              callback: function ($$v) {
                _vm.$set(_vm.data.row, "isEnabled", $$v)
              },
              expression: "data.row.isEnabled",
            },
          }),
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.isShareRow ? "" : "embed-code",
                  expression: "isShareRow ? '' : 'embed-code'",
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.bottomleft",
                  value: _vm.isShareRow ? "Copy Link" : "Get Embed",
                  expression: "isShareRow ? 'Copy Link' : 'Get Embed'",
                  modifiers: { hover: true, bottomleft: true },
                },
              ],
              staticClass: "btn",
              on: {
                click: function ($event) {
                  _vm.isShareRow
                    ? _vm.copyLink(_vm.data.row)
                    : _vm.setRowDetails(_vm.data.row)
                },
              },
            },
            [
              _c("GjIcon", {
                attrs: {
                  name: _vm.isShareRow ? "Copy" : "IconparkCode",
                  size: "18",
                },
              }),
            ],
            1
          ),
          _c("DropdownActions", { attrs: { items: _vm.dropdownActions } }),
        ],
        1
      )
    : _c("span", [_vm._v(_vm._s(_vm.data.formattedRow[_vm.data.column.field]))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }