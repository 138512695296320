<template>
  <div class="child-component">
    <div :id="componentId" class="map"></div>
  </div>
</template>

<script>
import store from "@/store";
import formatValue from "@/utils/formatValue";
import svgMap from "svgmap";
import "svgmap/dist/svgMap.min.css";

export default {
  props: {
    componentId: {
      type: String,
      required: false,
    },
    settings: {
      type: Object,
      required: false,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      map: null,
      interval: null,
    };
  },
  computed: {
    colors: {
      get() {
        return this.settings;
      },
    },
    fullScreenWidget: {
      get() {
        return store.getters["getFullScreenWidget"];
      },
      set(value) {
        store.commit("SET_FULL_SCREEN_WIDGET", value);
      },
    },
  },
  watch: {
    settings: {
      handler() {
        if (this.map) {
          this.map = new svgMap({
            targetElementID: this.componentId,
            initialZoom: 1,
            initialPan: { x: 0, y: 0 },
            zoomScaleSensitivity: 1,
            colorMax: this.colors.maxColor,
            colorMin: this.colors.minColor,
            colorNoData: "#e9eef7",
            data: {
              ...this.data,
            },
          });
        }
      },
    },
  },
  async mounted() {
    setTimeout(() => {
      this.renderMap();
    }, 0);
  },
  methods: {
    renderMap() {
      const tryRenderMap = () => {
        clearInterval(this.interval);
        try {
          this.map = new svgMap({
            targetElementID: this.componentId,
            colorMax: this.colors.maxColor,
            colorMin: this.colors.minColor,
            thousandSeparator: ",",
            initialZoom: 1,
            initialPan: { x: 0, y: 0 },
            colorNoData: "#e9eef7",
            hideFlag: true,
            mouseWheelZoomEnabled: false,
            onGetTooltip: (div, countryID) => {
              setTimeout(() => {
                const tooltip = div;

                const tds = tooltip.querySelectorAll(
                  ".svgMap-tooltip-content td"
                );

                const texts = Array.from(tds).filter((td) => {
                  return (
                    !td.querySelector("span") && td.textContent.trim() !== ""
                  );
                });

                texts.forEach((text) => {
                  text.textContent = text.textContent + ":";
                });
                const spans = tooltip.querySelectorAll(
                  ".svgMap-tooltip-content span"
                );

                let country = document.getElementById(
                  `${this.componentId}-map-country-${countryID}`
                );
                let style = getComputedStyle(country);
                let color = style.getPropertyValue("fill");

                spans.forEach((span) => {
                  span.innerText = formatValue(
                    span.innerText.replaceAll(",", ""),
                    this.data?.formatType
                  );
                });

                const tdElement = texts[0];

                if (tdElement)
                  tdElement.innerHTML =
                    `<span class="apexcharts-tooltip-marker" style="background-color: ${color} !important;
                background-color: rgb(255, 0, 0);
                position: absolute;
                top: 55px;
                left: 10px;
                display: block;"></span>` + tdElement.innerHTML;

                const updatedTooltipHtml = tooltip.outerHTML;
                const updatedTooltipElement = document.createElement("div");
                updatedTooltipElement.innerHTML = updatedTooltipHtml;
                const updatedTooltip = updatedTooltipElement.firstChild;

                return updatedTooltip;
              }, 0);
            },
            data: {
              ...this.data,
            },
          });

          clearInterval(this.interval);
          return;
        } catch (error) {
          if (this.fullScreenWidget) {
            this.map = null;
            let map = document.getElementById(this.componentId);
            map.innerHTML = "";
            this.interval = setInterval(tryRenderMap, 3000);
          }
        }
      };

      tryRenderMap();
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/map.scss";
</style>
