<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    :id="isOnShare ? 'date-picker-share' : 'date-picker-view'"
    cols="12"
    class="p-0 w-100 date-picker-component"
  >
    <div
      class="date-picker-input__holder"
      :class="isDisabled ? 'date-picker__disabled' : null"
      @click="openDatePickerModal"
    >
      <b-input
        read-only
        class="w-100 justify-content-between text-sm text-main border-0"
        :value="formattedDate"
        :disabled="isDisabled"
      >
      </b-input>
      <img
        :src="require(`@/assets/img/date-picker-icon.svg`)"
        alt="Close icon"
      />
    </div>
    <div v-if="toggleDate" ref="date-picker-modal" class="date-picker-modal">
      <div v-if="!isOnShare" class="date-picker__header">
        <div
          class="custom-date-range text-nowrap align-items-center d-flex mb-05 text-primary text-secondary text-sm justify-content-between"
        >
          Custom Date Range
          <b-form-input
            id="custom-date-range-input"
            v-model="startDate"
            readonly
            disabled
            class="date-picker-input"
            placeholder="dd/mm/YY - dd/mm/YY"
          />
          to
          <b-form-input
            id="custom-date-range-input-2"
            v-model="endDate"
            readonly
            disabled
            class="date-picker-input"
            placeholder="dd/mm/YY - dd/mm/YY"
          />
        </div>
        <b-button
          size="sm"
          @click="onCancelClick()"
          class="bg-transparent border-0 pr-0"
        >
          <img :src="require(`@/assets/img/close-icon.svg`)" alt="Close icon" />
        </b-button>
      </div>
      <div class="date-picker__container d-flex">
        <flat-pickr
          id="date-picker-calendar"
          ref="datePicker"
          v-model="dateSelected"
          :config="config"
          class="form-control flat-picker bg-transparent shadow-none date-picker-calendar"
          @on-change="onCalendarChange"
        />
        <div class="border-left mt-2 mb-2 pl-1 text-nowrap">
          <div
            v-for="date in customRangeDate"
            :key="date.id"
            :class="[
              selectCustomRangeDate == date.id ? 'highlightCustomDate' : null,
              date.disabled ? 'range-disabled' : null,
            ]"
            class="custom-range__holder"
            @click="selectedRangeDate(date)"
          >
            <div class="my-1 p-05 customRangePick">
              {{ date.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="date-picker__footer">
        <b-button
          variant="outline-secondary"
          class="m-05 mr-1 btn-height"
          @click="onCancelClick()"
        >
          <span class="align-middle d-lg-inline-block"> Cancel </span>
        </b-button>

        <b-button
          variant="primary"
          class="m-05 mr-1 btn-height"
          data-open
          @click="onApplyClick()"
        >
          <span class="align-middle d-lg-inline-block">Apply</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import { DATE_RANGE_TYPES } from "../utils/constants";
export default {
  components: {
    flatPickr,
  },
  props: {
    startEndDate: {
      type: String,
      required: true,
    },
    dateRange: {
      type: Number,
      required: false,
      default: () => DATE_RANGE_TYPES.CUSTOM,
    },
    isOnShare: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    minDate: {
      type: String,
      required: false,
      default: null,
    },
    maxDate: {
      type: String,
      required: false,
      default: "today",
    },
    inputPosition: {
      type: String,
      required: false,
      default: "top",
    },
  },
  data() {
    return {
      selectCustomRangeDate: 3,
      endDate: moment().format("yyyy-MM-DD"),
      startDate: moment().subtract(1, "months").format("yyyy-MM-DD"),
      compareStartDate: null,
      compareEndDate: null,
      dateSelected: _.cloneDeep(this.startEndDate),
      toggleDate: false,
      config: {
        minDate: this.minDate,
        maxDate: this.maxDate,
        animate: false,
        disable: [
          {
            from: new Date(),
            to: "2925-05-01",
          },
        ],
        closeOnSelect: false,
        inline: true,
        wrap: false,
        mode: "range",
        locale: {
          firstDayOfWeek: 1,
        },
        altInput: true,
        altFormat: "M j, Y",
      },
    };
  },
  computed: {
    customRangeDate: {
      get() {
        return store.getters["getCustomRangeDate"];
      },
      set(value) {
        store.commit("SET_RANGE_DATE_OPTIONS_FILTERED", value);
      },
    },
    formattedDate: {
      get() {
        const [fromDate, toDate] = this.startEndDate.split(" to ");

        const formattedFromDate = moment(fromDate).format("DD MMM, YYYY");
        const formattedToDate = moment(toDate).format("DD MMM, YYYY");

        return `${formattedFromDate} - ${formattedToDate}`;
      },
    },
  },
  mounted() {
    document.body.addEventListener("click", this.clickOutsideCalendar);
  },
  created() {
    this.createDateRangeOptions();
  },
  destroyed() {
    document.body.removeEventListener("click", this.clickOutsideCalendar);
  },
  methods: {
    openDatePickerModal() {
      if (this.isDisabled) return;

      this.dateSelected = _.cloneDeep(this.startEndDate);
      this.startDate = this.dateSelected.split(" to ")[0];
      this.endDate = this.dateSelected.split(" to ")[1];
      this.selectCustomRangeDate = this.dateRange;
      this.toggleDate = true;
      this.$nextTick(() => {
        this.adjustModalPosition();
      });
    },
    clickOutsideCalendar(event) {
      if (
        document
          .getElementById(
            `${this.isOnShare ? "date-picker-share" : "date-picker-view"}`
          )
          .contains(event.target)
      ) {
        return;
      }
      this.onCancelClick();
    },
    createDateRangeOptions() {
      store.commit("SET_RANGE_DATE_OPTIONS");
    },
    selectedRangeDate(date) {
      if (date.disabled) return;
      this.selectCustomRangeDate = date.id;
      this.startDate = date.fromDate;
      this.endDate = date.toDate;

      const sd = moment(date.fromDate).format("yyyy-MM-DD");
      const ed = moment(date.toDate).format("yyyy-MM-DD");

      const formattedDate = `${sd} to ${ed}`;

      this.dateSelected = formattedDate;
    },
    async onApplyClick() {
      if (this.startDate && this.endDate) {
        const dateValue = `${moment(this.startDate).format(
          "yyyy-MM-DD"
        )} to ${moment(this.endDate).format("yyyy-MM-DD")}`;
        this.$emit(
          "update-selected-date",
          dateValue,
          this.selectCustomRangeDate
        );
        this.toggleDate = false;
      }
    },
    onCancelClick() {
      this.toggleDate = false;
    },
    onCalendarChange(selectedDates) {
      this.startDate = moment(selectedDates[0]).format("yyyy-MM-DD");
      this.endDate = moment(selectedDates[0]).format("yyyy-MM-DD");

      if (selectedDates[1]) {
        this.endDate = moment(selectedDates[1]).format("yyyy-MM-DD");
      } else {
        this.selectCustomRangeDate = DATE_RANGE_TYPES.CUSTOM;
      }
    },
    adjustModalPosition() {
      const modalElement = document.querySelector(".date-picker-modal");
      const inputElement = document.querySelector(".date-picker-input__holder");

      if (modalElement && inputElement) {
        const modalRect = modalElement.getBoundingClientRect();
        const inputRect = inputElement.getBoundingClientRect();

        const spaceBelowInput = window.innerHeight - inputRect.bottom;

        let sizeToScale = 1;
        if (window.innerHeight <= 465) {
          sizeToScale = 0.635;
        } else if (window.innerHeight <= 530) {
          sizeToScale = 0.75;
        } else if (window.innerHeight <= 620) {
          sizeToScale = 0.85;
        }

        if (spaceBelowInput < modalRect.height) {
          modalElement.style.transform = `scale(${sizeToScale})`;
          modalElement.style.transformOrigin = "top right";

          let scrollAmount = "";
          if (this.inputPosition === "top") scrollAmount = inputRect.top - 80;
          else scrollAmount = inputRect.bottom - window.innerHeight;

          window.scrollBy({
            top: scrollAmount,
            behavior: "smooth",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/components/date-picker.scss";
</style>
