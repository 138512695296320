import Widget from "../../models/Widget";
import { DATE_RANGE_TYPES } from "../../utils/constants";

export default {
  widgetTypes: {},
  sideBarOpen: false,
  selectedItem: null,
  showSideBar: true,
  layout: [],
  mouseXY: { x: null, y: null },
  isDashobardFullScreen: false,
  fullScreenWidget: false,
  dashboardsData: {
    rows: [],
    columns: [
      { label: "Dashboard Name", field: "name", width: "39%" },
      {
        label: "Number of widgets",
        field: "numberOfWidgets",
        width: "20%",
      },
      { label: "Date", field: "insertDateTime", width: "38%" },
      {
        field: "options",
        label: "Actions",
        sortable: false,
        thClass: "not-sortable-headers",
        width: "3%",
      },
    ],
    page: 1,
    perPage: 10,
    totalRecords: 1,
    orderType: "",
    orderField: "",
    searchTerm: "",
  },
  globalFilters: {
    logicalOperator: 1,
    filters: [],
    datesSelected: "",
    dateRange: DATE_RANGE_TYPES.CUSTOM,
    selectedProperties: [],
  },
  embedFilters: [],
  dashboard: {},
  tableList: [],
  selectedDashboard: {
    id: null,
    name: null,
  },
  dashboardConfigs: null,
  embedConfig: null,
  chartColors: [
    "#2D337A",
    "#0B1945",
    "#324067",
    "#656AB0",
    "#EFB394",
    "#EB9D76",
  ],
  DragPos: new Widget({
    id: null,
    title: null,
    widgetType: 0,
    width: 1,
    height: 1,
    positionX: 5,
    positionY: 0,
    tableId: null,
    index: "drop",
    groupByField: null,
    logicalOperator: 1,
    fields: [],
    filters: [],
    settings: {
      id: null,
      minColor: "#324067",
      maxColor: "#0B1945",
    },
  }),
  fieldsWithoutAggregations: [],
  fieldsList: [],
  basicFieldsList: [],
  selectedFieldList: [],
  filterParameters: {},
  filterOperators: [],
  properties: null,
  propertiesSelected: [],
  selectedDates: {},
  filteredDashboardParameters: [],
  dashboardTableIds: [],
  customRangeDate: [],
  dashboardTemplates: [],
  dashboardAutoRefresh: {
    minutes: 0,
    text: "Off",
  },
  refreshDashboardData: false,
  dashboardHash: null,
  dashboardSharingData: {
    rows: [],
    columns: [
      { label: "Name", field: "name", width: "30%" },
      { label: "Type", field: "type", width: "30%" },
      { label: "Date", field: "insertDateTime", width: "30%" },
      {
        field: "options",
        label: "",
        sortable: false,
        thClass: "not-sortable-headers",
        width: "10%",
      },
    ],
    page: 1,
    perPage: 10,
    totalRecords: 1,
    orderType: "",
    orderField: "",
    searchTerm: "",
  },
  shareToDelete: [],
  shareDetails: [],
  dashboardName: "",
  actionsEnabled: null,
  canModifyShare: null,
  aiConfigurations: null,
  dashboardList: null,
};
