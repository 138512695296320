import { formatRequestQuery } from '@nodus/utilities-front'
import axiosIns from '../../libs/axios'
import Dashboard from '../../models/Dashboard'
import Record from '../../models/Record'
import { API_GATEWAY_URL } from '../../utils/constants'

import axios from 'axios'

let cancelTokens = []

export default {
  async getDashboardById({ commit, state }, { organizationId, id }) {
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboards/${id}`,
        {
          headers: {
            'hash-share-url': state.dashboardHash
          }
        }
      )
      .then((response) => {
        commit('SET_DASHBOARD', new Dashboard(response.data.data))
      })
  },

  async getDashboards({ commit, state }, { organizationId }) {
    const { page, perPage, searchTerm, orderField, orderType } =
      state.dashboardsData
    const queryParams = formatRequestQuery(
      searchTerm,
      page,
      perPage,
      orderField,
      orderType
    )

    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboards?${queryParams}`
      )
      .then((response) => {
        commit('SET_ACTIONS_ENABLED', {
          canModify: response.data.data.canModify,
          canShare: response.data.data.canShare,
          canViewShared: response.data.data.canViewShared
        })
        commit('SET_DASHBOARD_LIST', response.data.data.dashboards)
        commit('SET_TOTAL_RECORDS', response.data.data.totalCount)
      })
  },

  async saveDashboard(_, { organizationId, data }) {
    let res = await axiosIns
      .post(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboards`,
        data
      )
      .then((response) => {
        return response?.data?.data?.id
      })
      .catch(() => {
        return null
      })

    return res
  },

  async deleteDashboard({ commit }, { organizationId, dashboard }) {
    await axiosIns
      .delete(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboards/${dashboard.id}`
      )
      .then(() => {
        commit('SET_SELECTED_DASHBOARD', {
          id: null,
          name: null
        })
      })
  },

  async getTables({ commit }, { organizationId }) {
    await axiosIns
      .get(`${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/tables`)
      .then((response) => {
        let tables = response?.data?.data?.filterTables || []
        commit(
          'SET_TABLE_LIST',
          tables.map((x) => new Record(x))
        )
      })
  },

  async getFields({ commit }, { organizationId, id, widgetType }) {
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/widgets/fields?tableId=${id}&widgetType=${widgetType}`
      )
      .then((response) => {
        let res = response.data.data.aggregationFunctionsGroup

        commit('SET_FIELDS_LIST', { id, fields: res })
      })
  },

  async getParameters({ state, commit }, { organizationId, id, search }) {
    const defaultUrl = '/'
    let tableIds = state.dashboardTableIds

    if (tableIds)
      tableIds = tableIds.map((item) => {
        return `tableIds=${item}`
      })
    const arrToString = tableIds
      ? `?${tableIds.toString().replaceAll(',', '&')}`
      : ''
    const attachedUrl =
      (search || tableIds.length > 0) && !id
        ? `${arrToString}&search=${search ? search : ''}`
        : `${'?tableIds=' + id}`

    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/segments/filter/parameters${
          !search && !id && !tableIds ? defaultUrl : attachedUrl
        }`,
        {
          headers: {
            'hash-share-url': state.dashboardHash
          }
        }
      )
      .then((response) => {
        const params = response.data.data.targetingParameterGroups

        let parameters = params.reduce((acc, rec) => {
          if (!rec.groupName) acc.unshift(...rec.targetingParameters)
          else
            acc.push(
              { parameterName: rec.groupName, isParent: true },
              ...rec.targetingParameters
            )
          return acc
        }, [])
        if (!search) {
          commit('SET_FILTER_PARAMETERS', {
            id: id || 'dashboard',
            parameters: parameters
          })
        }
        commit('SET_DASHBOARD_FILTERED_PARAMS', parameters)
      })
  },

  async getOperators({ commit, state }, { organizationId }) {
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/segments/parameters/operators`,
        {
          headers: {
            'hash-share-url': state.dashboardHash
          }
        }
      )
      .then((res) => {
        commit(
          'SET_FILTER_OPERATORS',
          res.data.data.columnTypeParameterOperators
        )
      })
  },

  async getGroupByFields({ commit }, { organizationId, id }) {
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/widgets/basic-fields?tableId=${id}`
      )
      .then((res) => {
        const resData = res.data.data.dashboardWidgetFields
        commit('SET_BASIC_FIELDS_LIST', { id, groupByField: resData })
      })
  },

  async cancelQueryRequests() {
    for (const cancelTokenSource of cancelTokens) {
      cancelTokenSource.cancel('Requests canceled')
    }
    cancelTokens = []
  },

  async executeQuery({ state }, { organizationId, data }) {
    let widgetData = {}
    const cancelTokenSource = axios.CancelToken.source()
    cancelTokens.push(cancelTokenSource)
    let predefinedGlobalFilters = state.dashboard.predefinedGlobalFilters.map(
      (filter) => {
        return {
          id: filter.id,
          columnId: filter.columnId,
          operator: filter.operator,
          filterValues: filter.filterValues,
          isHidden: filter.hidden
        }
      }
    )
    let globalFilters = []
    if (state.dashboardConfigs?.globalFilters) {
      globalFilters = [...state.dashboardConfigs.globalFilters]
    }
    if (state.globalFilters && state.globalFilters.filters) {
      globalFilters = [...globalFilters, ...state.globalFilters.filters]
    }
    await axiosIns
      .post(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/widgets/execute-query`,
        {
          ...data,
          globalFilterLogicalOperator: state.globalFilters.logicalOperator,
          globalFilters,
          predefinedGlobalFilters: predefinedGlobalFilters,
          predefinedGlobalFilterLogicalOperator:
            state.dashboard.predefinedGlobalFilterLogicalOperator
        },
        {
          cancelToken: cancelTokenSource.token,
          headers: {
            'hash-share-url': state.dashboardHash
          }
        }
      )
      .then((res) => {
        widgetData = res.data
        widgetData.error = false
      })
      .catch((err) => {
        widgetData = err
        widgetData.error = true
      })
    return widgetData
  },

  async getProperties({ commit, state }, { organizationId }) {
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/properties`,
        {
          headers: {
            'hash-share-url': state.dashboardHash
          }
        }
      )
      .then((response) => {
        commit('SET_PROPERTIES', response.data.data.properties)
      })
  },

  async getWidgets({ commit, state }, { organizationId }) {
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/widget-types`,
        {
          headers: {
            'hash-share-url': state.dashboardHash
          }
        }
      )
      .then((response) => {
        commit('SET_WIDGET_TYPES', response.data.data)
      })
  },

  async getHashShareUrl({ state }, organizationId) {
    return await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboard-sharings/hash-share-url`,
        {
          headers: {
            'hash-share-url': state.dashboardHash
          }
        }
      )
      .then((response) => {
        return response.data.data
      })
  },

  async exportDashboard(_, { data, organizationId }) {
    await axiosIns.post(
      `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboards/export`,
      data
    )
  },

  async getDashboardTemplates({ commit }, { organizationId }) {
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboards/templates`
      )
      .then((response) => {
        commit('SET_DASHBOARD_TEMPLATES', response.data.data)
      })
  },

  async getDashboardTemplateById({ commit }, { organizationId, id }) {
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboards/templates/${id}`
      )
      .then((response) => {
        commit('SET_DASHBOARD', new Dashboard(response.data.data))
      })
  },

  async createDashboardShare(_, { organizationId, data }) {
    let res = await axiosIns.post(
      `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboard-sharings`,
      {
        ...data
      }
    )
    return res
  },

  async updateDashboardShare(_, { organizationId, data }) {
    let res = await axiosIns.put(
      `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboard-sharings`,
      {
        ...data
      }
    )
    return res
  },

  async getDashboardSharingList({ commit, state }, { organizationId, id }) {
    const data = state.dashboardSharingData
    const { page, perPage, searchTerm, orderField, orderType } = data
    const queryParams = formatRequestQuery(
      searchTerm,
      page,
      perPage,
      orderField,
      orderType
    )

    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboard-sharings?dashboardId=${id}&${queryParams}`
      )
      .then((response) => {
        commit('SET_DASHBOARD_SHARE_DATA', response.data.data.dashboardSharings)
        commit(
          'SET_TOTAL_DASHBOARD_SHARE_RECORDS',
          response.data.data.totalCount
        )
        commit('SET_SELECTED_DASHBOARD', {
          id,
          name: response.data.data.dashboardName
        })
        commit('SET_DASHBOARD_NAME', response?.data?.data?.dashboardName)
        commit('SET_CAN_MODIFY_SHARE', response.data.data.canModify)
      })
  },

  async changeShareStatus({ commit }, { organizationId, id }) {
    await axiosIns
      .patch(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboard-sharings/change-status`,
        { id }
      )
      .then((response) => {
        const status = response.data.data.isEnabled
        commit('SET_DASHBOARD_SHARE_STATUS', { id, status })
      })
  },

  async deleteDashboardSharing(_, { organizationId, obj }) {
    await axiosIns.delete(
      `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboard-sharings/${obj.id}`
    )
  },

  async getDashboardShareById({ commit }, { organizationId, id }) {
    let res
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboard-sharings/${id}`
      )
      .then((response) => {
        commit('SET_SHARE_DETAILS', response.data.data)
        res = response.data.data
      })
    return res
  },

  async getAiConfigurations({ commit }, { organizationId }) {
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/organizations/configurations/ai`
      )
      .then((response) => {
        commit('SET_AI_CONFIGURATIONS', response.data.data)
      })
  },

  async getDashboardDropdownList({ commit }, { organizationId }) {
    await axiosIns
      .get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/visualizer/dashboards/sub-list`
      )
      .then((response) => {
        commit('SET_DASHBOARDS_DROPDOWN_LIST', response.data.data.dashboards)
      })
  }
}
